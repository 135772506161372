import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { CustomFile, FileType } from 'src/app/models/customFile.model';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'tsv-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit{

  files: CustomFile[];
  displayedColumns: string[] = [ 'name', 'created', 'download'];
  dataSource: MatTableDataSource<CustomFile>;

  constructor(
    private fileService: FileService
  ){}

  ngOnInit(): void {
      this.fileService.getByFileType(FileType.DOWNLAOD).subscribe(files => {
        this.files = files;
        this.dataSource = new MatTableDataSource(files);
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
