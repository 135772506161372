<h1 mat-dialog-title>Wähle ein Bild aus</h1>
<div mat-dialog-content class="dialog-content">

    <h4 *ngIf="!compressionOngoing && !uploadFailed">
        Uploads sind auf eine max. Größe von 4MB beschränkt.
    </h4>

    <h4 *ngIf="uploadFailed">
        Der Upload ist fehlgeschlagen.
        <br>
        {{failedMessage}}
    </h4>

    <mat-progress-spinner class="image-cropper-upload-progress" *ngIf="percentage && !uploadFailed" [value]="percentage"></mat-progress-spinner>

    <div class="progress-compress" *ngIf="compressionOngoing">
        <h4>Bild wird komprimiert...</h4>
        <mat-spinner></mat-spinner>
    </div>

    <div class="image-cropper-placeholder" *ngIf="!croppedImage && !compressionOngoing && !uploadFailed">
        <button mat-icon-button color="primary" (click)="compressFile()">
            <mat-icon>add_a_photo</mat-icon>
        </button>
    </div>

    <input class="image-input" type="file" accept=".png,.jpg" (change)="fileChangeEvent($event)" #imagecropper />

    <image-cropper class="image-cropper" [imageBase64]="imgResultAfterCompression" [imageChangedEvent]="imageChangedEvent" [aspectRatio]="ratio ? ratio : 4/3"
        *ngIf="!percentage" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="primary" (click)="upload()" [disabled]="disabled || !croppedImage">Hochladen</button>
</div>