import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CustomFile, FileType } from 'src/app/models/customFile.model';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'tsv-admin-downloads',
  templateUrl: './admin-downloads.component.html',
  styleUrls: ['./admin-downloads.component.scss']
})
export class AdminDownloadsComponent implements OnInit {

  selectedFiles: File[] = [];
  files: CustomFile[];
  displayedColumns: string[] = ['name', 'created', 'size', 'download', 'delete'];
  dataSource: MatTableDataSource<CustomFile>;

  constructor(
    private fileService: FileService
  ) { }

  ngOnInit(): void {
    this.fileService.getByFileType(FileType.DOWNLAOD).subscribe(files => {
      this.files = files;
      this.dataSource = new MatTableDataSource(files);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  delete(file: CustomFile) {
    this.fileService.delete(file);
  }

  upload(): void {
    if (this.selectedFiles.length > 0) {
      console.log('Uploading file');
      for (let file of this.selectedFiles) {
        this.fileService.upload(file, FileType.DOWNLAOD);
      }
      this.cancel();
    }
  }

  cancel(): void {
    this.selectedFiles = [];
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
  }

}
