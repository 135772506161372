import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tsv-admin-gallery-folders-dialog',
  templateUrl: './admin-gallery-folders-dialog.component.html',
  styleUrls: ['./admin-gallery-folders-dialog.component.css']
})
export class AdminGalleryFoldersDialogComponent {

  newFolderName: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {prefix: string}
  ){}

}
