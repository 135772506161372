import { Observable } from 'rxjs';
import { HelpDialogComponent } from './../../dialogs/help-dialog/help-dialog.component';
import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/user.service';
import { User } from './../../models/user.model';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  selector: 'tsv-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() user: User | null;
  
  adminRole: Roles = Roles.ADMIN;

  constructor(private userService: UserService, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  signOut(): void {
    this.authService.signOut();
  }

  openHelpDialog(): void {
    const helpDialog = this.dialog.open(HelpDialogComponent);
  }

}
