import { Observable, concatMap } from 'rxjs';
import { AuthService } from './../../services/auth.service';
import { SportService } from './../../services/sport.service';
import { ActivatedRoute, EventType } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Sport } from 'src/app/models/sport.model';
import { User } from 'src/app/models/user.model';
import { Roles } from 'src/app/models/roles.enum';
import { MatDialog } from '@angular/material/dialog';
import { NewsCreateComponent } from '../news/news-create/news-create.component';
import { News } from 'src/app/models/news.model';
import { TeamService } from 'src/app/services/team.service';
import { Team } from 'src/app/models/team.model';
import { TeamCreateDialogComponent } from '../team/team-create-dialog/team-create-dialog.component';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/services/event.service';
import { EventCreateDialogComponent } from '../event/event-create-dialog/event-create-dialog.component';
import { AuthorType } from 'src/app/models/authorType.enum';
import { SettingsService } from 'src/app/services/settings.service';
import { SettingsGlobal } from 'src/app/models/settings-global.model';

@Component({
  templateUrl: './sport.component.html',
  styleUrls: ['./sport.component.scss']
})
export class SportComponent implements OnInit {

  /**
  * Global settings key.
  */
  readonly SETTINGS_KEY: string = 'global-settings';

  /**
   * Stores the global settings, see interface for details.
   */
  settings: SettingsGlobal;

  /**
   * Enum values
   */
  managerRole: Roles = Roles.MANAGER;
  reportRole: Roles = Roles.REPORTER;
  adminRole: Roles = Roles.ADMIN;

  /**
   * Observables for subinformation
   */
  news$: Observable<News[]>;
  teams$: Observable<Team[]>;
  events$: Observable<Event[]>;

  /**
   * Current sport
   */
  sport: Sport;

  /**
   * Current user
   */
  user: User;

  /**
   * Manages the state to show or hide additional information
   */
  more: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sportService: SportService,
    private authService: AuthService,
    public dialog: MatDialog,
    private teamService: TeamService,
    private eventService: EventService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      concatMap(params => this.sportService.getSportById(params['sportId']))
    ).subscribe(sport => {
      this.sport = sport;
      //  TODO: improve this observable call
      this.teams$ = this.teamService.getBySportId(sport.id!);
      this.events$ = this.eventService.getBySportId(sport.id!);
    });
    this.authService.user$.subscribe(user => this.user = user);
    this.settingsService.get(this.SETTINGS_KEY).subscribe(settings => {
      this.settings = settings[0];
      this.more = this.settings.sportInformationDefault;
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NewsCreateComponent, {
      data: this.sport
    });
  }

  openTeamCreateDialog(): void {
    const teamCreateDialog = this.dialog.open(TeamCreateDialogComponent, {
      data: {
        user: this.user,
        sport: this.sport
      }
    })
  }

  openEventCreateDialog(): void {
    const eventCreateDialog = this.dialog.open(EventCreateDialogComponent, {
      data: {
        authorType: AuthorType.SPORT,
        authorId: this.sport.id
      }
    }
    );
  }

  /**
   * Calls the native browser method to call the given telephone number.
   */
  call(): void {
    window.open('tel:' + this.sport.contact?.telephone);
  }

  /**
   * Calls the native browser method to write an email to the given email address.
   */
  email() {
    window.location.href = `mailto:${this.sport.contact?.email}`;
  }

}
