<mat-drawer-container class="root-drawer-container">
    <mat-drawer #drawer class="root-drawer" mode="over">
        <mat-toolbar class="root-drawer-toolbar" color="primary">
            <img class="toolbar-icon" [src]="'assets/icon/tsv.jpg'">
            TSV Travemünde
        </mat-toolbar>
        <div class="root-drawer-content">
            <div class="root-drawer-navigation">
                <a class="root-drawer-navigation-item" [routerLink]="'neuigkeiten'" (click)="drawer.close()">
                    <mat-icon class="root-drawer-navigation-item-icon">article</mat-icon>
                    <div>Neuigkeiten</div>
                </a>
                <mat-divider></mat-divider>
                <a class="root-drawer-navigation-item" [routerLink]="'sportangebote'" (click)="drawer.close()">
                    <mat-icon class="root-drawer-navigation-item-icon">sports_handball</mat-icon>
                    <div>Sportangebote</div>
                </a>
                <mat-divider></mat-divider>
                <a class="root-drawer-navigation-item" [routerLink]="'clubhaus'" (click)="drawer.close()">
                    <mat-icon class="root-drawer-navigation-item-icon">home</mat-icon>
                    <div>Clubhaus</div>
                </a>
                <mat-divider></mat-divider>
                <a class="root-drawer-navigation-item" [routerLink]="'kontakt'" (click)="drawer.close()">
                    <mat-icon class="root-drawer-navigation-item-icon">groups</mat-icon>
                    <div>Geschäftsstelle</div>
                </a>
                <mat-divider></mat-divider>
                <a class="root-drawer-navigation-item" [href]="fanShopUrl" target="_blank" (click)="drawer.close()">
                    <mat-icon  class="root-drawer-navigation-item-icon">storefront</mat-icon>
                    <div>Fanshop</div>
                </a>
                <mat-divider></mat-divider>
                <a class="root-drawer-navigation-item" [routerLink]="'anmelden'" (click)="drawer.close()">
                    <mat-icon class="root-drawer-navigation-item-icon">person</mat-icon>
                    <div>Anmelden</div>
                </a>
                <mat-divider></mat-divider>
                <a class="root-drawer-navigation-item" [routerLink]="'registrieren'" (click)="drawer.close()">
                    <mat-icon class="root-drawer-navigation-item-icon">person_add</mat-icon>
                    <div>Registrieren</div>
                </a>
            </div>
        </div>
    </mat-drawer>

    <tsv-toolbar *ngIf="user$ | async" [user]="(user$ | async)"></tsv-toolbar>

    <mat-toolbar class="toolbar" *ngIf="!(user$ | async)" color="primary">
        <mat-toolbar-row class="toolbar-row0">
            <img class="toolbar-icon" [routerLink]="''" [src]="'assets/icon/tsv.jpg'">
            TSV Travemünde
            <span class="all-spacer"></span>
            <button mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar-row>
        <mat-toolbar-row class="toolbar-row1" *ngIf="!(isHandset$ | async)">
            <a class="toolbar-row1-item" [routerLink]="'neuigkeiten'">
                <mat-icon>article</mat-icon>
                <div>Neuigkeiten</div>
            </a>
            <a class="toolbar-row1-item" [routerLink]="'sportangebote'">
                <mat-icon>sports_handball</mat-icon>
                <div>Sportangebote</div>
            </a>
            <a class="toolbar-row1-item" [routerLink]="'clubhaus'">
                <mat-icon>home</mat-icon>
                <div>Clubhaus</div>
            </a>
            <a class="toolbar-row1-item" [routerLink]="'kontakt'">
                <mat-icon>groups</mat-icon>
                <div>Geschäftsstelle</div>
            </a>
            <a class="toolbar-row1-item" [href]="fanShopUrl" target="_blank">
                <mat-icon>storefront</mat-icon>
                <div>Fanshop</div>
            </a>
            <a class="toolbar-row1-item" [routerLink]="'anmelden'">
                <mat-icon>person</mat-icon>
                <div>Anmelden</div>
            </a>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="content mat-elevation-z2">
        <router-outlet></router-outlet>
    </div>

    <button class="ext-home-fab" mat-fab color="primary" [routerLink]="''"
        *ngIf="!(user$ | async) && routerUrl !== '/'">
        <mat-icon>home</mat-icon>
    </button>

    <mat-toolbar class="banner" color="warn" *ngIf="settings?.banner">
        {{settings.bannerMessage}}
    </mat-toolbar>

    <tsv-footer class="footer"></tsv-footer>
</mat-drawer-container>