<div class="event-create">

    <h2 class="title">{{updatingEvent ? 'Veranstaltung bearbeiten' : 'Veranstaltungen erstellen'}}</h2>

    <!-- Formgroup -->
    <form [formGroup]="form" class="form">

        <!-- First row -->
        <div class="first-row">

            <!-- Title -->
            <mat-form-field appearance="outline">
                <mat-label>Veranstaltungstitel</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>

            <!-- Place -->
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Ort</mat-label>
                <input matInput formControlName="place">
                <mat-icon matPrefix>place</mat-icon>
            </mat-form-field>

            <!-- Participants -->
            <mat-form-field appearance="outline" class="participants">
                <mat-label>Teilnehmer</mat-label>
                <input matInput type="number" min="0" max="99" formControlName="limitedParticipants"
                    oninput="validity.valid||(value=0);">
                <mat-icon matSuffix matTooltip="0 steht für unbegrenzt." matTooltipPosition="before">help</mat-icon>
            </mat-form-field>

            <div class="picker-wrapper">

                <!-- Date -->
                <mat-form-field appearance="outline" class="date-picker">
                    <mat-label>Datum</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="day" (click)="picker.open()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>

                <!-- Start time -->
                <div>
                    <mat-form-field appearance="outline" class="time-picker">
                        <mat-label>Start</mat-label>
                        <input matInput formControlName="start" [ngxMatTimepicker]="timepickerStart" [format]="24"
                            readonly />
                        <mat-icon matSuffix (click)="timepickerStart.open()">
                            watch_later
                        </mat-icon>
                    </mat-form-field>
                    <ngx-mat-timepicker #timepickerStart></ngx-mat-timepicker>
                </div>

                <!-- End time -->
                <div>
                    <mat-form-field appearance="outline" class="time-picker">
                        <mat-label>Ende</mat-label>
                        <input matInput formControlName="end" [ngxMatTimepicker]="timepickerEnd" [format]="24"
                            readonly />
                        <mat-icon matSuffix (click)="timepickerEnd.open()">
                            watch_later
                        </mat-icon>
                    </mat-form-field>
                    <ngx-mat-timepicker #timepickerEnd></ngx-mat-timepicker>
                </div>
            </div>

        </div>

        <!-- Second row -->
        <div class="second-row">

            <!-- Description -->
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Beschreibung</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>

        </div>

        <!-- Third row -->
        <div class="third-row" *ngIf="!updatingEvent">

            <!-- Settings for recurring patterns -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Einstellungen zu Wiederholungen
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-divider></mat-divider>

                <!-- Hourly -->
                <div class="section">
                    <mat-checkbox color="primary" formControlName="recurringHourly">Stündliche
                        Wiederholung</mat-checkbox>

                    <div class="picker-wrapper">
                        <mat-form-field appearance="outline" class="time-picker">
                            <mat-label>Start</mat-label>
                            <input matInput formControlName="recurringHourlyStart"
                                [ngxMatTimepicker]="timepickerRecurringStart" [format]="24" readonly />
                            <mat-icon matSuffix (click)="timepickerRecurringStart.open()">
                                watch_later
                            </mat-icon>
                        </mat-form-field>
                        <ngx-mat-timepicker #timepickerRecurringStart></ngx-mat-timepicker>

                        <mat-form-field appearance="outline" class="time-picker picker-hourly-end">
                            <mat-label>Ende</mat-label>
                            <input matInput formControlName="recurringHourlyEnd"
                                [ngxMatTimepicker]="timepickerRecurringEnd" [format]="24" readonly />
                            <mat-icon matSuffix (click)="timepickerRecurringEnd.open()">
                                watch_later
                            </mat-icon>
                        </mat-form-field>
                        <ngx-mat-timepicker #timepickerRecurringEnd></ngx-mat-timepicker>
                    </div>
                </div>
                <mat-divider></mat-divider>

                <div class="section">
                    <mat-checkbox color="primary" formControlName="recurringDaily">Tägliche Wiederholung</mat-checkbox>

                    <div class="picker-wrapper">
                        <mat-form-field class="picker" appearance="outline">
                            <mat-label>Anzahl Tage</mat-label>
                            <input type="number" min="1" max="7" matInput formControlName="recurringDailyDays"
                                oninput="validity.valid||(value=1);">
                        </mat-form-field>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="section">
                    <mat-checkbox color="primary" formControlName="recurringWeekly">
                        Wöchentliche Wiederholung
                    </mat-checkbox>

                    <div class="picker-wrapper">
                        <mat-form-field class="picker" appearance="outline">
                            <mat-label>Wochen</mat-label>
                            <input type="number" min="1" max="52" matInput formControlName="recurringWeeklyWeeks"
                                oninput="validity.valid||(value=1);">
                        </mat-form-field>
                    </div>
                </div>
                <span>
                    Entschuldige bitte, dass die wöchentliche Wiederholungsrate derzeit aufgrund von Problemen
                    deaktiviert ist. Wir sind aktiv und mit höchster Priorität an der Wiederherrstellung und einsatzsicheren
                    Gewährleistung dieser Funktionalität.
                </span>
                <mat-divider></mat-divider>

                <p>
                    {{readableSentence}}
                </p>
            </mat-expansion-panel>
        </div>

    </form>

    <div class="actions">
        <button mat-button (click)="cancel()">Abbrechen</button>
        <button *ngIf="!updatingEvent" mat-raised-button color="primary" (click)="create()"
            [disabled]="form.invalid">Erstellen</button>
        <button *ngIf="updatingEvent" mat-raised-button color="primary" (click)="update()"
            [disabled]="form.invalid">Speichern</button>
    </div>

</div>