import { Observable } from 'rxjs';
import { BoardService } from './../../../services/board.service';
import { Component, OnInit } from '@angular/core';
import { Board } from 'src/app/models/board.model';
import { MatDialog } from '@angular/material/dialog';
import { BoardCreateComponent } from './board-create/board-create.component';

@Component({
  selector: 'tsv-admin-board',
  templateUrl: './admin-board.component.html',
  styleUrls: ['./admin-board.component.scss']
})
export class AdminBoardComponent implements OnInit {

  board$: Observable<Board[]>;

  constructor(
    private boardService: BoardService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.board$ = this.boardService.getAll();
  }

  openDialog(board?: Board): void {
    if (board) {
      this.dialog.open(BoardCreateComponent, { data: board });
    } else {
      this.dialog.open(BoardCreateComponent);
    }
  }

  delete(board: Board): void {
    this.boardService.delete(board);
  }


}
