<h1 mat-dialog-title>Team erstellen</h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="form">

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Name des Teams." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <!-- User chooser for coaches-->
        <div class="formfield-with-hint">
            <tsv-user-chooser [title]="'Trainer'" [role]="coachRole" [sportId]="sport.id!"
                (selectedUsersEvent)="syncCoaches($event)"></tsv-user-chooser>
            <button mat-icon-button
                matTooltip="Trainer verwalten das Team und dessen Veranstaltungen."
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <!-- User chooser for members-->
        <div class="formfield-with-hint">
            <tsv-user-chooser [title]="'Mitglieder'" [role]="userRole"
                (selectedUsersEvent)="syncMembers($event)"></tsv-user-chooser>
            <button mat-icon-button
                matTooltip="Mitglieder können den Terminkalender einsehen und zu Veranstaltungen zu- oder absagen."
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>
    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="primary" (click)="createTeam()" [disabled]="form.invalid">Erstellen</button>
</div>