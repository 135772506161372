<div class="gallery-container">

    <div class="path" *ngIf="path.length > 0">
        <button mat-icon-button (click)="onHomeClick()">
            <mat-icon>home</mat-icon>
        </button>
        <button mat-button *ngFor="let folder of path" (click)="onPathClick(folder)" class="path-item">
            <mat-icon>chevron_right</mat-icon>
            <span class="path-text">{{folder}}</span>
        </button>
    </div>
    <mat-divider *ngIf="path.length > 0"></mat-divider>

    <div>
        <h3 class="title">Galerie</h3>
    </div>

    <h4 *ngIf="folders.length > 0" class="folders-title">Ordner</h4>

    <mat-list *ngIf="folders.length > 0" class="folders">
        <mat-list-item *ngFor="let folder of folders" (click)="onFolderClick(folder)">
            <mat-icon matListItemIcon>folder</mat-icon>
            <div matListItemTitle class="name">{{folder}}</div>
        </mat-list-item>
    </mat-list>
    <mat-divider *ngIf="folders.length > 0"></mat-divider>

    <div class="gallery-lightbox-container">
        <div class="gallery">
            <div *ngFor="let image of images; let i=index" class="gallery-img">
                <img (click)="onPreviewImage(i)" [src]="image.url" [alt]="image.name" loading="lazy">
            </div>
        </div>
        <div *ngIf="showMask" class="lightbox" @animation2>
            <span *ngIf="showCount" class="count">{{currentIndex + 1}}/{{totalImageCount}}</span>
            <button *ngIf="previewImage" class="close-btn" mat-mini-fab (click)="onClosePreview()" color="primary">
                <mat-icon class="">close</mat-icon>
            </button>
            <button *ngIf="controls" class="btn-lightbox-carousel btn-prev" mat-mini-fab (click)="prev()"
                color="primary">
                <mat-icon class="icon-prev icon-lightbox-carousel">chevron_left</mat-icon>
            </button>
            <button *ngIf="controls" class="btn-lightbox-carousel btn-next" mat-mini-fab (click)="next()"
                color="primary">
                <mat-icon class="icon-next icon-lightbox-carousel">chevron_right</mat-icon>
            </button>
            <div *ngIf="previewImage" [@animation]="{value: 'visible'}" (@animation.done)="onAnimationEnd($event)"
                class="lightbox-img">
                <img [src]="currentLightboxImage.url" [alt]="currentLightboxImage.name" loading="lazy">
            </div>
        </div>
    </div>

</div>
<div class="hint">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Urheberrechtshinweis für Fotografien
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="hint-text"> Alle auf dieser Website abgebildeten Fotos unterliegen dem Urheberrecht des TSV
                Travemünde oder wurden
                mit ausdrücklicher Erlaubnis anderer Rechteinhaber verwendet. Die Verwendung, Vervielfältigung oder
                Verbreitung der Bilder ohne eine vorherige schriftliche Genehmigung durch den TSV Travemünde oder den
                jeweiligen Rechteinhaber ist ausdrücklich untersagt.
                <br>
                Bitte beachten Sie, dass unbefugte Nutzungshandlungen straf- und zivilrechtlich verfolgt werden können.
                <br>
                Falls Sie Interesse an der Verwendung oder Verbreitung der Bilder haben, setzen Sie sich bitte vorab
                schriftlich mit uns in Verbindung, um die erforderlichen Genehmigungen einzuholen.
                <br><br>
                Vielen Dank für Ihr Verständnis und Ihre Kooperation.
            </p>
        </mat-expansion-panel>
    </mat-accordion>
</div>