<mat-card class="card">
    <mat-card-header class="header">
        <img class="avatar" *ngIf="sport$ | async; let sport" [src]="sport.imageUrl" mat-card-avatar [routerLink]="'/sport/' + sport.id">
        <mat-card-title class="headline">{{news.headline}}</mat-card-title>
        <mat-card-subtitle class="subheadline">{{news.subheadline}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="!(sport$ | async)" class="subheadline">{{news.created | date: 'dd.MM.YY, HH:mm'}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="sport$ | async; let sport" class="subheadline">{{sport.name}}: {{news.created | date: 'dd.MM.YY, HH:mm'}}</mat-card-subtitle>
    </mat-card-header>
    <img class="img" [src]="news.imageUrl" mat-card-image [routerLink]="'/neuigkeiten/' + news.id">
    <mat-card-content [routerLink]="'/neuigkeiten/' + news.id">
        <div class="content">
            <p *ngIf="news.content && !news.html">{{news.content}}</p>
            <div *ngIf="news.html" [innerHTML]="news.html"></div>
        </div>
    </mat-card-content>
</mat-card>