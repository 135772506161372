<div class="external-flex-container">
  <div class="external-news">
    <swiper-container
      class="external-news-swiper"
      navigation="true"
      pagination="true"
      pagination-clickable="true"
      loop="true"
      autoplay="true"
    >
      <swiper-slide
        *ngFor="let news of mergedNews"
        class="external-news-item"
        [routerLink]="'neuigkeiten/' + news.id"
      >
        <img
          class="external-news-item-image"
          [src]="news.imageUrl"
          loading="lazy"
        />
        <div class="external-news-item-header">
          <span class="external-news-item-header-headline">{{
            news.headline
          }}</span>
          <div *ngIf="news.subheadline">
            <div class="external-news-item-header-subheadline">
              {{ news.subheadline }}
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>

  <!-- events preview  -->
  <!-- TODO: show year of event if it is not in this year -->
  <div class="external-events" *ngIf="(events$ | async)?.length! > 0">
    <div class="external-events-title">Veranstaltungen</div>
    <div class="external-events-cards">
      <tsv-event-card
        class="event-card"
        *ngFor="let event of events$ | async"
        [event]="event"
      ></tsv-event-card>
    </div>
  </div>

  <!-- sports-preview -->
  <div class="external-sports">
    <div class="external-sports-title">Sportangebote</div>

    <div class="ext-sports-cards-sm hide-lg">
      <tsv-sport-card
        *ngFor="let sport of sports$ | async"
        [sport]="sport"
        [size]="'sm'"
      ></tsv-sport-card>
    </div>

    <div class="ext-sports-cards-dsktp hide-sm">
      <a
        class="ext-sports-card-wrapper"
        *ngFor="let sport of sports$ | async"
        [routerLink]="'/sportangebote/' + sport.id"
      >
        <mat-card class="ext-sports-card">
          <img
            class="ext-sports-card-img"
            mat-card-lg-image
            [src]="sport.imageUrl"
          />
          <div class="ext-sports-card-info">
            <mat-card-title>{{ sport.name }}</mat-card-title>
            <mat-card-subtitle class="ext-sports-card-desc">{{
              sport.description ? sport.description : "&zwnj;"
            }}</mat-card-subtitle>
          </div>
        </mat-card>
      </a>
    </div>

    <div class="external-sports-footer">
      <button mat-raised-button [routerLink]="'sportangebote'" color="primary">
        Alle Anzeigen
      </button>
    </div>
  </div>
</div>
