import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Event } from '../models/event.model';
import { Observable, first, map } from 'rxjs';
import { AuthorType } from '../models/authorType.enum';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private eventsCollection: AngularFirestoreCollection<Event>;

  constructor(
    private firestore: AngularFirestore
  ) {
    this.eventsCollection = firestore.collection<Event>('events');
  }

  create(event: Event): Promise<any> {
    event.id = this.firestore.createId();
    return this.eventsCollection.doc(event.id).set(event);
  }

  get(eventId: string): Observable<Event | undefined> {
    return this.eventsCollection.doc(eventId).valueChanges();
  }

  update(event: Event): Promise<any> {
    return this.eventsCollection.doc(event.id).update(event);
  }

  getAll(): Observable<Event[]> {
    return this.eventsCollection.valueChanges();
  }

  delete(event: Event): Promise<void> {
    return this.eventsCollection.doc(event.id).delete();
  }

  deleteCascading(event: Event): any {
    this.firestore.collection<Event>("events", ref => ref
      .where('originId', '==', event.originId)).valueChanges().subscribe(events => {
        events.forEach(item => this.delete(item));
      });
  }

  toggleBlock(event: Event): Promise<void> {
    if (event.blocked) {
      event.blocked = !event.blocked
    } else {
      event.blocked = true;
    }
    return this.update(event);
  }

  blockCascading(event: Event): any {
    this.firestore.collection<Event>("events", ref => ref
      .where('originId', '==', event.originId)).valueChanges().pipe(first()).subscribe(events => {
        events.forEach(item => this.toggleBlock(item));
      });
  }

  getByAuthorIdAndAuthorType(authorId: string, authorType: AuthorType): Observable<Event[]> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.firestore.collection<Event>('events', ref => ref
      .where('day', '>=', today.getTime())
      .where('authorId', '==', authorId)
      .where('authorType', '==', authorType)
      .orderBy('day', 'asc')
    ).valueChanges();
  }

  getAllByAuthorType(authorType: AuthorType): Observable<Event[]> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.firestore.collection<Event>('events', ref => ref
      .where('day', '>=', today.getTime())
      .where('authorType', '==', authorType)
      .orderBy('day', 'asc')
    ).valueChanges();
  }

  getAllUpcoming(): Observable<Event[]> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.firestore.collection<Event>('events', ref => ref
      .where('day', '>=', today.getTime())
      .orderBy('day', 'asc')
    ).valueChanges();
  }

  getBySportId(sportId: string): Observable<Event[]> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.firestore.collection<Event>('events', ref => ref
      .where('day', '>=', today.getTime())
      .where('authorId', '==', sportId)
      .where('authorType', '==', AuthorType.SPORT)
      .orderBy('day', 'asc')
    ).valueChanges();
  }

  getParticpating(userId: string): Observable<Event[]> {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.firestore.collection<Event>('events', ref => ref
      .where('day', '>=', today.getTime())
      .where('participantIds', 'array-contains', userId)
      .orderBy('day', 'asc')
    ).valueChanges();
  }

  getByTeamAndDay(teamId: string, date: Date): Observable<Event[]> {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date);
    end.setHours(23, 59, 59, 999);

    return this.firestore.collection<Event>('events', ref => ref
      .where('day', '>=', start.getTime())
      .where('day', '<=', end.getTime())
      .where('authorId', '==', teamId)
      .where('authorType', '==', 'team')
      ).valueChanges();
  }

}
