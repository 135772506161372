import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { TeamService } from 'src/app/services/team.service';
import { Sport } from 'src/app/models/sport.model';
import { SportService } from 'src/app/services/sport.service';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  selector: 'tsv-teams',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  team: Team;
  user: User;
  sport: Sport;
  adminRole: Roles = Roles.ADMIN;

  constructor(
    private activatedRoute: ActivatedRoute,
    private teamService: TeamService,
    private sportService: SportService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      concatMap(res => this.teamService.getTeamById(res['teamId']))
    ).subscribe((team: Team) => {
      this.team = team;
      this.sportService.getSportById(team.sportId).subscribe(sport => this.sport = sport);
    });
    this.authService.user$.subscribe(user => this.user = user);
  }

  /**
   * Navigates to the event creation for teams.
   */
  openEventCreation(): void {
    this.router.navigate(['mannschaft', this.team.id, 'veranstaltungen'])
  }

}
