import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

// Angular Material imports
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {ClipboardModule} from '@angular/cdk/clipboard';

// Angular project components
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SportsListComponent } from './components/sport/sports-list/sports-list.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { HelpDialogComponent } from './dialogs/help-dialog/help-dialog.component';
import { AdminComponent } from './components/admin/admin.component';
import { SportComponent } from './components/sport/sport.component';
import { CreateSportComponent } from './components/sport/sport-create/sport-create.component';
import { SportSettingsComponent } from './components/sport/sport-settings/sport-settings.component';
import { EventComponent } from './components/event/event.component';
import { ExternalComponent } from './components/external/external.component';
import { UserChooserComponent } from './components/user-chooser/user-chooser.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { ImagePickerDialogComponent } from './components/image-picker/image-picker-dialog/image-picker-dialog.component';
import { SportPreviewComponent } from './components/sport/sport-preview/sport-preview.component';
import { TeamComponent } from './components/team/team.component';
import { ExtSportsListComponent } from './components/external/ext-sports-list/ext-sports-list.component';
import { ImpressComponent } from './components/footer/impress/impress.component';
import { DatasecurityComponent } from './components/footer/datasecurity/datasecurity.component';
import { ElevationDirective } from './directives/elevation.directive';
import { ExtFaqComponent } from './components/external/ext-faq/ext-faq.component';
import { ExtNewsComponent } from './components/external/ext-news/ext-news.component';
import { ExtNewsPreviewComponent } from './components/external/ext-news/ext-news-preview/ext-news-preview.component';
import { SportCardComponent } from './components/sport/sport-card/sport-card.component';
import { NewsComponent } from './components/news/news.component';
import { NewsCreateComponent } from './components/news/news-create/news-create.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TeamCardComponent } from './components/team/team-card/team-card.component';
import { TeamCreateDialogComponent } from './components/team/team-create-dialog/team-create-dialog.component';
import { TeamMembershipsPreviewComponent } from './components/team/team-memberships-preview/team-memberships-preview.component';
import { TeamJoinDialogComponent } from './components/team/team-join-dialog/team-join-dialog.component';
import { TeamSettingsComponent } from './components/team/team-settings/team-settings.component';
import { TeamCalendarComponent } from './components/team/team-calendar/team-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { EventCreateDialogComponent } from './components/event/event-create-dialog/event-create-dialog.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { EventCardComponent } from './components/event/event-card/event-card.component';
import { EventsPreviewComponent } from './components/event/events-preview/events-preview.component';
import { EventSettingsDialogComponent } from './components/event/event-settings-dialog/event-settings-dialog.component';
import { NewsSettingsDialogComponent } from './components/news/news-settings-dialog/news-settings-dialog.component';
import { TeamCalendarEventDialogComponent } from './components/team/team-calendar/team-calendar-event-dialog/team-calendar-event-dialog.component';
import { FilesComponent } from './components/files/files.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { ExtClubComponent } from './components/external/ext-club/ext-club.component';
import { ExtContactComponent } from './components/external/ext-contact/ext-contact.component';
import { UserManagementComponent } from './components/admin/user-management/user-management.component';
import { FaqComponent } from './components/admin/faq/faq.component';
import { EditUserComponent } from './components/admin/user-management/edit-user/edit-user.component';
import { MatSortModule } from '@angular/material/sort';
import { AdminDownloadsComponent } from './components/admin/admin-downloads/admin-downloads.component';
import { AdminGalleryComponent } from './components/admin/admin-gallery/admin-gallery.component';
import { AdminClubComponent } from './components/admin/admin-club/admin-club.component';
import { AdminContactComponent } from './components/admin/admin-contact/admin-contact.component';
import { AdminSettingsComponent } from './components/admin/admin-settings/admin-settings.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { SportLinkDialogComponent } from './components/sport/sport-link-dialog/sport-link-dialog.component';
import { BoardComponent } from './components/footer/board/board.component';
import { AdminBoardComponent } from './components/admin/admin-board/admin-board.component';
import { BoardCreateComponent } from './components/admin/admin-board/board-create/board-create.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { AdminGalleryFoldersDialogComponent } from './components/admin/admin-gallery/admin-gallery-folders-dialog/admin-gallery-folders-dialog.component';
import { NgxEditorModule } from 'ngx-editor';
import { ImgUploadComponent } from './components/news/img-upload/img-upload.component';
import { EventCreateComponent } from './components/event/event-create/event-create.component';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ToolbarComponent,
    SignUpComponent,
    SignInComponent,
    ProfileComponent,
    SettingsComponent,
    SportsListComponent,
    LandingPageComponent,
    HelpDialogComponent,
    AdminComponent,
    SportComponent,
    CreateSportComponent,
    SportSettingsComponent,
    EventComponent,
    ExternalComponent,
    UserChooserComponent,
    ImagePickerComponent,
    ImagePickerDialogComponent,
    SportPreviewComponent,
    TeamComponent,
    ExtSportsListComponent,
    ImpressComponent,
    DatasecurityComponent,
    ElevationDirective,
    ExtFaqComponent,
    ExtNewsComponent,
    ExtNewsPreviewComponent,
    SportCardComponent,
    NewsComponent,
    NewsCreateComponent,
    TimelineComponent,
    TeamCardComponent,
    TeamCreateDialogComponent,
    TeamMembershipsPreviewComponent,
    TeamJoinDialogComponent,
    TeamSettingsComponent,
    TeamCalendarComponent,
    EventCreateDialogComponent,
    EventCardComponent,
    EventsPreviewComponent,
    EventSettingsDialogComponent,
    NewsSettingsDialogComponent,
    TeamCalendarEventDialogComponent,
    FilesComponent,
    ArchiveComponent,
    ExtClubComponent,
    ExtContactComponent,
    UserManagementComponent,
    FaqComponent,
    EditUserComponent,
    AdminDownloadsComponent,
    AdminGalleryComponent,
    AdminClubComponent,
    AdminContactComponent,
    AdminSettingsComponent,
    SportLinkDialogComponent,
    BoardComponent,
    AdminBoardComponent,
    BoardCreateComponent,
    GalleryComponent,
    AdminGalleryFoldersDialogComponent,
    ImgUploadComponent,
    EventCreateComponent,
    DeleteDialogComponent,
    FileUploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTableModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatGridListModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    MatListModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatTabsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgxMatTimepickerModule.setLocale('de-DE'),
    MatPaginatorModule,
    MatSortModule,
    NgxFilesizeModule,
    DragDropModule,
    NgxEditorModule,
    MatProgressBarModule,
    ClipboardModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
