import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private storage: AngularFireStorage) { }

  /**
   * Uploads a file to Firebase storage and returns an observable for tracking the upload progress.
   * 
   * @param file The file to be uploaded.
   * @param path The storage path where the file should be uploaded.
   * @returns Observable<number | undefined> Observable for upload progress (percentage).
   */
  uploadFile(file: File, path: string): Observable<number | undefined> {
    const fileRef = this.storage.ref(path);
    const task = this.storage.upload(path, file);

    // Track progress
    return task.percentageChanges();
  }

  /**
   * Gets the download URL for a file after it has been uploaded.
   * 
   * @param path The storage path where the file was uploaded.
   * @returns Observable<string> Observable for the file's download URL.
   */
  getDownloadURL(path: string): Observable<string> {
    return this.storage.ref(path).getDownloadURL();
  }
}
