<div class="sport-settings-flexbox">
    <mat-card class="sport-settings-card">
        <mat-card-header>
            <mat-card-title>Einstellungen</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>

        <mat-card-content *ngIf="sport">
            <form [formGroup]="sportForm" class="sport-settings-card-content">

                <!-- Image -->
                <tsv-image-picker [img]="sport.imageUrl!" (downloadURLEvent)="syncDownloadURL($event)"
                    class="sport-settings-card-image-picker"></tsv-image-picker>

                <!-- Name -->
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <!-- Description -->
                <mat-form-field appearance="outline">
                    <mat-label>Beschreibung</mat-label>
                    <input matInput formControlName="description">
                </mat-form-field>

                <!-- Abteilungsleiter -->
                <tsv-user-chooser class="sport-settings-card-user-chooser" [role]="role"
                    [selectedUsers]="sport.trainerIds" [title]="'Abteilungsleiter'"
                    (selectedUsersEvent)="syncTrainers($event)"></tsv-user-chooser>

                <!-- Trainer -->
                <tsv-user-chooser class="sport-settings-card-user-chooser" [role]="coachRole"
                    [selectedUsers]="sport.coachIds!" [title]="'Trainer'"
                    (selectedUsersEvent)="syncCoaches($event)"></tsv-user-chooser>

                <mat-form-field appearance="outline" class="formfield">
                    <mat-label>Kontakt</mat-label>
                    <input matInput formControlName="contactName">
                </mat-form-field>

                <mat-form-field appearance="outline" class="formfield">
                    <mat-label>Telephone</mat-label>
                    <input matInput formControlName="contactTelephone">
                </mat-form-field>

                <mat-form-field appearance="outline" class="formfield">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="contactEmail">
                </mat-form-field>

                <!-- Information -->
                <mat-form-field appearance="outline">
                    <mat-label>Informationen</mat-label>
                    <textarea matInput formControlName="information"></textarea>
                </mat-form-field>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Links
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="links-manage">
                        <button mat-raised-button (click)="addLink()">Link hinzufügen</button>
                        <div cdkDropList (cdkDropListDropped)="drop($event)" class="links-list">
                            <div class="link" *ngFor="let link of links" cdkDrag>
                                <p class="link-name">{{link.name}}</p>
                                <div class="link-btns">
                                    <a mat-icon-button target="_blank" [href]="link.url" matTooltip="Link öffnen"
                                        matTooltipPosition="left">
                                        <mat-icon>link</mat-icon>
                                    </a>
                                    <button mat-icon-button (click)="rmLink(link.name)" matTooltip="Löschen"
                                        matTooltipPosition="left">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>

            </form>
        </mat-card-content>
        <mat-card-actions class="sport-settings-card-footer">
            <button mat-button (click)="cancel()">Abbrechen</button>
            <button mat-raised-button color="warn" (click)="delete()">Löschen</button>
            <button mat-raised-button color="primary" (click)="updateSport()">Speichern</button>
        </mat-card-actions>
    </mat-card>
</div>