import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { Team } from 'src/app/models/team.model';
import { Roles } from 'src/app/models/roles.enum';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'tsv-team-calendar-event-dialog',
  templateUrl: './team-calendar-event-dialog.component.html',
  styleUrls: ['./team-calendar-event-dialog.component.scss']
})
export class TeamCalendarEventDialogComponent implements OnInit {

  readonly ADMIN_ROLE: Roles = Roles.ADMIN;

  user: User;
  event: Event;
  team: Team;

  /**
   * Boolean to show or hide a list of members.
   */
  memberToggle: boolean = false;

  /**
   * Optional array of user names who booked the same event.
   */
  participants?: string[] = [];

  /**
   * Array to store all events of the same today to enable according operations for admins.
   */
  dailyEvents: Event[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { event: Event, user: User, team: Team },
    private eventService: EventService,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<TeamCalendarEventDialogComponent>,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.syncDialogData();
  }

  book(): void {
    this.event.participantIds?.push(this.user.id!);
    this.updateEvent('Veranstaltung verbindlich zugesagt.');
  }

  /**
   * Books appointment as an admin.
   */
  bookAsAdmin(): void {
    const bookingDummyId = 'FLAv8CDjg8MgF4ByZ9lC7jqrWM63';
    this.event.participantIds?.push(bookingDummyId);
    this.updateEvent();
  }

  redoAdminBooking(): void {
    const bookingDummyId = 'FLAv8CDjg8MgF4ByZ9lC7jqrWM63';
    this.event.participantIds = this.event.participantIds?.filter(item => item !== bookingDummyId);
    this.updateEvent();
  }


  cancelBooking(): void {
    this.event.participantIds = this.event.participantIds?.filter(item => item !== this.user.id!);
    this.updateEvent('Veranstaltung abgesagt.');
  }

  delete(event: Event): void {
    this.eventService.delete(event).then(() => {
      this.matSnackBar.open(
        'Veranstaltung wurde erfolgreich gelöscht.',
        'Ok!',
        { duration: 2000 }
      );
      this.dialogRef.close();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  /**
   * Deletes all events with the same day as the choosen one.
   */
  deleteDaily(): void {
    this.dailyEvents.forEach(event => this.delete(event));
  }

  /**
   * Deletes all events of the same series.
   * @param event 
   */
  deleteCascading(event: Event): void {
    this.eventService.deleteCascading(event);
    this.matSnackBar.open('Veranstaltungen wurden gelöscht.', 'Ok', { duration: 2000 });
    this.dialogRef.close();
  }

  /**
   * Blocks the according event.
   * @param event 
   */
  block(event: Event): void {
    this.eventService.toggleBlock(event);
  }

  /**
   * Blocks all events with the same day as the choosen one.
   */
  blockToday(): void {
    this.dailyEvents.forEach(event => this.block(event));
  }

  /**
   * Blocks all events from the same series.
   */
  blockCascading(): void {
    this.eventService.blockCascading(this.event);
  }

  /**
   * Navigates to the event update component.
   */
  openSettings(): void {
    this.dialogRef.close();
    this.router.navigate(['mannschaft', this.team.id, 'veranstaltungen', this.event.id]);
  }

  /**
   * Updates the according event.
   */
  private updateEvent(msg?: string): void {
    this.eventService.update(this.event).then(() => {
      this.matSnackBar.open(
        msg ? msg : 'Buchung wurde erfolgreich angepasst.',
        'Ok!',
        { duration: 2000 }
      );
      this.dialogRef.close();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  private syncDialogData(): void {
    this.event = this.dialogData.event;
    this.user = this.dialogData.user;
    this.team = this.dialogData.team;
    this.getAllEventFromSameDay();
    this.userService.getUsersByIds(this.event.participantIds!).subscribe(users => {
      this.participants = [];
      users.forEach(user => this.participants?.push(user.firstName + ' ' + user.lastName));
    });

  }

  private getAllEventFromSameDay(): void {
    const date = new Date(this.event.day);
    this.eventService.getByTeamAndDay(this.team.id!, date).subscribe(events => this.dailyEvents = events);
  }

}
