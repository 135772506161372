<div class="board">
    <h3>Vorstand</h3>

    <button mat-flat-button (click)="openDialog()" class="add-btn">
        <mat-icon>add</mat-icon>
        Hinzufügen
    </button>

    <mat-card *ngFor="let member of board$ | async" class="card" (click)="openDialog(member)">
        <img class="card-img" mat-card-lg-image [src]="member.imageUrl">
        <mat-card-header>
            <mat-card-title>{{member.name}}</mat-card-title>
            <mat-card-subtitle>{{member.title}}</mat-card-subtitle>
        </mat-card-header>
    </mat-card>

</div>