import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private imageCollection: AngularFirestoreCollection<any>;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
    this.imageCollection = db.collection<any>('images');
  }

  createId(): string {
    return this.db.createId();
  }

  uploadImage(file: any, id: string): AngularFireUploadTask {
    const filePath = 'images/' + id;
    const fileRef = this.storage.ref(filePath);
    const task = fileRef.putString(file, 'data_url');
    return task;
  }

  getDownloadURL(id: string): Observable<any> {
    return this.storage.ref('images/' + id).getDownloadURL();
  }
}
