<mat-expansion-panel [expanded]="expanded" class="file-upload">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{titleText}}
        </mat-panel-title>
        <mat-panel-description>
            {{descriptionText}}
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="fab-wrapper">
        <button mat-fab class="upload-fab" (click)="fileUpload.click()" color="primary">
            <mat-icon>upload_file</mat-icon>
        </button>
    </div>

    <input class="input" type="file" multiple accept="application/pdf" #fileUpload (change)="onFileSelected($event)">

    <mat-list class="list">
        <div class="list-item" *ngFor="let file of selectedFiles; let i = index">
            <div class="list-item-top">
                <mat-list-item>
                    <span>{{file.name}}</span>
                </mat-list-item>
                <button mat-icon-button (click)="removeFile(i)" *ngIf="uploadProgress[i] != 100">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
            <mat-progress-bar mode="determinate" [value]="uploadProgress[i]"></mat-progress-bar>
        </div>
    </mat-list>

    <mat-action-row class="actions">
        <button mat-button (click)="cancel()">Abbrechen</button>
        <button mat-raised-button color="primary" (click)="upload()" [disabled]="selectedFiles.length == 0 || uploadInProgress || uploadProgress[0] == 100">{{buttonText}}</button>
    </mat-action-row>
</mat-expansion-panel>