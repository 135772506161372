import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  ref = 'gallery';

  constructor(
    private storage: AngularFireStorage
  ) { }

  getTest(): any {
    return firstValueFrom(this.storage.ref(this.ref).listAll()).then((res) => {
      res.prefixes.forEach((folderRef) => console.log(folderRef.name));
      res.items.forEach((itemRef) => {
        console.log(itemRef.getDownloadURL());
        console.log(itemRef.getMetadata());
      });
    });
  }

  get(path: string[]): Promise<any> {
    this.ref = 'gallery';
    path.forEach(folder => {
      this.ref = this.ref + '/' + folder;
    })
    return firstValueFrom(this.storage.ref(this.ref).listAll());
  }

  delete(path: string, news: boolean = false): Observable<void> {
    let ref;
    if (news) {
      ref = this.storage.refFromURL(path);
    } else {
      ref = this.storage.ref(path);
    }
    return ref.delete();
  }

  add(path: string): AngularFireUploadTask {
    return this.storage.ref(path + '/newFolder').put({});
  }

  deleteAll(path: string): Promise<void> {
    return this.storage.storage.ref(path).listAll().then(data => {
      data.items.forEach(item => {
        this.storage.storage.ref(item.fullPath).delete()
      });
    })
  }

  uploadFiles(files: File[], path: string[]): Promise<string[]> {
    const uploadPromises: Promise<string>[] = [];
    this.ref = 'gallery';
    path.forEach(folder => {
      this.ref = this.ref + '/' + folder;
    });

    files.forEach((file: File) => {
      const filePath = `${this.ref}/${file.name}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = storageRef.put(file);

      const uploadPromise = new Promise<string>((resolve, reject) => {
        uploadTask.then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL);
          });
        }).catch(error => {
          reject(error);
        });
      });

      uploadPromises.push(uploadPromise);
    });

    return Promise.all(uploadPromises);
  }

}
