<div class="faq">
    <h3>FAQs</h3>
    <mat-expansion-panel class="panel">
        <mat-expansion-panel-header>
            Neue FAQ erstellen
        </mat-expansion-panel-header>
        <div class="new-faq">
            <mat-form-field class="formfield" appearance="outline">
                <mat-label>Neue Frage</mat-label>
                <input matInput [(ngModel)]="newFaq.question">
            </mat-form-field>
            <mat-form-field class="formfield" appearance="outline">
                <mat-label>Neue Antwort</mat-label>
                <input matInput [(ngModel)]="newFaq.answer">
            </mat-form-field>
            <button class="create-btn" mat-raised-button color="primary"
                (click)="createFaq()">Erstellen</button>
        </div>
    </mat-expansion-panel>
    
    <mat-divider></mat-divider>
    
    <mat-expansion-panel *ngFor="let faq of faqs" class="faq" class="panel">
        <mat-expansion-panel-header class="header">
            <mat-panel-title>
                {{faq.question}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="outline" class="formfield">
            <mat-label>Frage</mat-label>
            <textarea matInput [(ngModel)]="faq.question"></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="formfield">
            <mat-label>Antwort</mat-label>
            <textarea matInput [(ngModel)]="faq.answer"></textarea>
        </mat-form-field>
        <div>
            <button mat-raised-button color="warn" (click)="deleteFaq(faq)">
                Löschen
            </button>
            <button mat-raised-button color="primary" (click)="updateFaq(faq)">
                Speichern
            </button>
        </div>
    </mat-expansion-panel>
</div>