import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user.model';
import { Roles } from 'src/app/models/roles.enum';
import { Editor, Toolbar } from 'ngx-editor';
import { ImageUpload } from 'src/app/models/imageUpload.model';

@Component({
  selector: 'tsv-news-settings-dialog',
  templateUrl: './news-settings-dialog.component.html',
  styleUrls: ['./news-settings-dialog.component.scss']
})
export class NewsSettingsDialogComponent implements OnInit, OnDestroy {

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3'] }],
    ['link', 'image']
  ];
  newsId: string = '';
  imageUploads: string[] = [];
  reporterRole: Roles = Roles.REPORTER;
  user: User;
  news: News;
  form: FormGroup = this.formBuilder.group({
    imageUrl: [''],
    headline: [''],
    subheadline: [''],
    featured: [false],
    featuredUntil: [],
    html: ['']
  })

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private newsService: NewsService,
    @Inject(MAT_DIALOG_DATA) public dialogData: News,
    private dialogRef: MatDialogRef<NewsSettingsDialogComponent>,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.editor = new Editor();
    this.authService.user$.subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  initForm(): void {
    this.newsId = this.dialogData.id!;
    this.news = this.dialogData;
    const news = this.dialogData;
    this.form.controls['headline'].setValue(news.headline);
    this.form.controls['subheadline'].setValue(news.subheadline);
    this.form.controls['html'].setValue(news.html);
    this.form.controls['featured'].setValue(news.featured);
    news.featuredUntil ? this.form.controls['featuredUntil'].setValue(new Date(news.featuredUntil!)) : null;
    this.form.controls['imageUrl'].setValue(news.imageUrl);
    this.imageUploads = news.imageUploads!;
  }

  update(): void {
    const news: News = this.dialogData;
    news.headline = this.form.get('headline')?.value!;
    news.subheadline = this.form.get('subheadline')?.value!;
    news.html = this.form.get('html')?.value!;
    news.featured = this.form.get('featured')?.value!;
    news.modified = new Date().getTime();
    news.imageUrl = this.form.get('imageUrl')?.value!;
    news.imageUploads = this.imageUploads!;

    this.newsService.update(news).then(() => {
      this.matSnackBar.open(
        'Neuigkeit wurde erfolgreich bearbeitet.',
        'Ok!',
        { duration: 2000 }
      );
      this.dialogRef.close();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  /**
  * Gets called on change of the emitted downloadURL of the image-picker and syncs the url to the create object.
  * @param url string
  */
  syncDownloadURL(url: string) {
    this.form.controls['imageUrl'].setValue(url);
  }

  imagesUploadedEvent(imageUploads: ImageUpload[]): void {
    imageUploads.forEach(img => this.imageUploads.push(img.url ? img.url : ''));
  }
}
