<h1 mat-dialog-title>Vorstand</h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="form">

        <div class="form-image-picker">
            <tsv-image-picker *ngIf="!board" (downloadURLEvent)="syncDownloadURL($event)"
                [ratio]="3/4"></tsv-image-picker>
            <tsv-image-picker *ngIf="board" (downloadURLEvent)="syncDownloadURL($event)" [ratio]="3/4"
                [img]="board.imageUrl"></tsv-image-picker>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Titel</mat-label>
                <input matInput formControlName="title" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Titel des Vorstandsmitglieds." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Name des Vorstandsmitglieds." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Reihenfolge</mat-label>
                <input matInput type="number" min="0" max="9" formControlName="order" required
                    oninput="validity.valid||(value=0);">
            </mat-form-field>
            <button mat-icon-button matTooltip="Reihenfolge der Sortierung der Vorstandsmitglieder. Desto niedriger desto höher." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button matDialogClose>Abbrechen</button>
    <button *ngIf="!board" mat-raised-button color="primary" [disabled]="form.invalid" (click)="create()">Erstellen</button>
    <button *ngIf="board" mat-raised-button color="warn" (click)="delete()">Löschen</button>
    <button *ngIf="board" mat-raised-button color="primary" [disabled]="form.invalid" (click)="update()">Speichern</button>
</div>