import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Link } from 'src/app/models/sport.model';

@Component({
  selector: 'tsv-sport-link-dialog',
  templateUrl: './sport-link-dialog.component.html',
  styleUrls: ['./sport-link-dialog.component.scss']
})
export class SportLinkDialogComponent {

  form = this.formBuilder.group({
    name: [''],
    url: ['']
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SportLinkDialogComponent>
  ) { }

  update(): void {
    const link: Link = {
      name: this.form.get('name')?.value!,
      url: this.form.get('url')?.value!
    }
    this.dialogRef.close(link);
  }

}
