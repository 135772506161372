import { Component, OnInit, Input } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/services/event.service';
import { AuthorType } from 'src/app/models/authorType.enum';
import { MatDialog } from '@angular/material/dialog';
import { TeamCalendarEventDialogComponent } from './team-calendar-event-dialog/team-calendar-event-dialog.component';

@Component({
  selector: 'tsv-team-calendar',
  templateUrl: './team-calendar.component.html',
  styleUrls: ['./team-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class TeamCalendarComponent implements OnInit {

  @Input() currentUser: User;
  @Input() team: Team;

  events: Event[];

  /**
   * Configurations for the calendar
   */
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  //TODO: override these if an event is out of the boundaries
  dayStartHour: number = 6;
  dayEndHour: number = 22;
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  locale: string = 'de';
  hourSegments: number = 1;
  activeDayIsOpen: boolean = true;

  constructor(
    private eventService: EventService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.eventService.getByAuthorIdAndAuthorType(this.team.id!, AuthorType.TEAM).subscribe(events => {
      this.events = events;
      this.applyColor();
    });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  openDialog(event: any): void {
    const dialogRef = this.matDialog.open(TeamCalendarEventDialogComponent, {
      minWidth: '300px',
      data: { event: event.event, user: this.currentUser, team: this.team }
    });
  }

  /**
   * Apply color to the events to return a visual feedback to the user.
   */
  applyColor(): void {
    // Define the colors for the different states
    const booked = {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    };
    const free = {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    };
    const full = {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    };
    const blocked = {
      primary: '#5e6668',
      secondary: '#c1c8c7'

    };

    this.events.forEach(event => {
      // event is blocked
      if (event.blocked) {
        event.color = blocked;
      }
      // event is booked by the current user
      else if (event.participantIds?.includes(this.currentUser?.id!)) {
        event.color = booked;
      }
      // event is limited and full
      else if (event.limitedParticipants! > 0 && event.participantIds?.length! >= event.limitedParticipants!) {
        event.color = full;
      }
      // reset color if no condition is true
      else {
        event.color = free;
      }
    });
  }

}
