import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomFile, FileType } from 'src/app/models/customFile.model';
import { FileService } from 'src/app/services/file.service';


@Component({
  selector: 'tsv-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArchiveComponent implements OnInit {

  files: CustomFile[];
  fileType: FileType = FileType.ARCHIVE;
  imageUrl = 'https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1406&q=80';

  constructor(
    private fileService: FileService
  ) { }

  ngOnInit(): void {
    this.fileService.getByFileType(this.fileType).subscribe(files => this.files = files);
  }



}
