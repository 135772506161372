import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FAQ } from '../models/faq.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  private faqCollection: AngularFirestoreCollection<FAQ>;

  constructor(
    private firestore: AngularFirestore
  ) {
    this.faqCollection = firestore.collection<FAQ>('faq');
  }

  getAll(): Observable<FAQ[]> {
    return this.faqCollection.valueChanges();
  }

  update(faq: FAQ): Promise<void> {
    return this.faqCollection.doc(faq.id!).set(faq);
  }

  create(faq: FAQ): Promise<void> {
    faq.id = this.firestore.createId();
    return this.faqCollection.doc(faq.id).set(faq);
  }

  delete(faq: FAQ): Promise<void> {
    return this.faqCollection.doc(faq.id).delete();
  }

}
