<h1 mat-dialog-title>Link</h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="form">

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Name des Links" matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>
        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Url</mat-label>
                <input matInput formControlName="url" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Url des Links" matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button [matDialogClose]>Abbrechen</button>
    <button mat-raised-button [disabled]="form.invalid" (click)="update()">Speichern</button>
</div>
