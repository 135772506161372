import { Component } from '@angular/core';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

  navigationItems = [
    {
      name: 'Benutzerverwaltung',
      link: 'benutzerverwaltung',
      icon: 'groups'
    },
    {
      name: 'FAQs',
      link: 'faq',
      icon: 'quiz'
    },
    {
      name: 'Downloads',
      link: 'downloads',
      icon: 'downloads'
    },
    {
      name: 'Galerie',
      link: 'galerie',
      icon: 'photos'
    },
    {
      name: 'Clubhaus',
      link: 'clubhaus',
      icon: 'home'
    },
    {
      name: 'Geschäftsstelle',
      link: 'kontakt',
      icon: 'store'
    },
    {
      name: 'Vorstand',
      link: 'vorstand',
      icon: 'assignment_ind'
    },
    {
      name: 'Einstellungen',
      link: 'einstellungen',
      icon: 'settings'
    }
  ];

}
