<mat-card class="event">
    <mat-card-header class="header">
        <div class="text-wrapper">
            <mat-card-subtitle>{{event.day | date: 'dd.MM.YYYY'}}, {{event.start | date: 'HH:mm'}} - {{event.end | date: 'HH:mm'}}</mat-card-subtitle>
            <mat-card-title class="text">{{event.title}}</mat-card-title>
            <mat-card-subtitle class="text" *ngIf="event.place">Ort: {{event.place}}</mat-card-subtitle>
            <mat-card-subtitle *ngIf="sport" class="text">Veranstalter: {{sport.name}}</mat-card-subtitle>
        </div>
        <div class="all-spacer"></div>
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="user">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item *ngIf="canEdit()" (click)="edit()">
                <mat-icon>edit_note</mat-icon>
                Bearbeiten
            </button>
            <button mat-menu-item *ngIf="!event.participantIds?.includes(user?.id!)" (click)="follow()">
                <mat-icon>event_available</mat-icon>
                Folgen
            </button>
            <button mat-menu-item *ngIf="event.participantIds?.includes(user?.id!)" (click)="unfollow()">
                <mat-icon>event_busy</mat-icon>
                Nicht mehr Folgen
            </button>
            <button mat-menu-item (click)="delete(event)" *ngIf="canEdit()">
                <mat-icon>delete</mat-icon>
                Löschen
            </button>
        </mat-menu>
    </mat-card-header>
</mat-card>