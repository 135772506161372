<h1 mat-dialog-title class="dialog-title">
    {{event.title}}
    <div class="all-spacer"></div>
    <button mat-icon-button *ngIf="team.coachIds.includes(user.id!) || user.roles.includes(ADMIN_ROLE)"
        [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openSettings()" matTooltip="Öffnet die Einstellungen der Veranstaltung."
            matTooltipPosition="left">
            <mat-icon>edit</mat-icon>
            Bearbeiten
        </button>
        <button mat-menu-item (click)="bookAsAdmin()" matTooltip="Reserviert die Veranstaltung als Admin."
            matTooltipPosition="left">
            <mat-icon>book</mat-icon>
            Reservieren
        </button>
        <button mat-menu-item (click)="redoAdminBooking()" matTooltip="Storniert alle Reservierungen."
            matTooltipPosition="left">
            <mat-icon>redo</mat-icon>
            Adminbuchung stornieren
        </button>
        <button mat-menu-item (click)="block(event)" matTooltip="(Un-)blockiert diese Veranstaltung."
            matTooltipPosition="left">
            <mat-icon>block</mat-icon>
            <span>{{event.blocked ? 'Freigeben' : 'Blockieren'}}</span>
        </button>
        <button mat-menu-item (click)="blockToday()" *ngIf="dailyEvents.length > 1" matTooltipPosition="left"
            matTooltip="(Un-)blockiert alle Veranstaltung des ausgewählten Tages.">
            <mat-icon>block</mat-icon>
            <span>Tag (un-)blockieren</span>
        </button>
        <button mat-menu-item (click)="blockCascading()" *ngIf="event.originId"
            matTooltip="(Un-)Blockiert diese und alle Veranstaltungen, welche in der selben Serie erstellt wurden."
            matTooltipPosition="left">
            <mat-icon>block</mat-icon>
            Serie (un-)blockieren
        </button>
        <button mat-menu-item (click)="delete(event)" matTooltip="Löscht diese Veranstaltung."
            matTooltipPosition="left">
            <mat-icon>delete</mat-icon>
            Löschen
        </button>
        <button mat-menu-item (click)="deleteDaily()" *ngIf="dailyEvents.length > 1" matTooltipPosition="left"
            matTooltip="Löscht alle Veranstaltungen des ausgewählten Tages.">
            <mat-icon>delete</mat-icon>
            Tag Löschen
        </button>
        <button mat-menu-item (click)="deleteCascading(event)" *ngIf="event.originId"
            matTooltip="Löscht diese und alle Veranstaltungen, welche in der selben Serie erstellt wurden."
            matTooltipPosition="left">
            <mat-icon>delete</mat-icon>
            Serie löschen
        </button>
    </mat-menu>
</h1>
<div mat-dialog-content class="dialog-content">

    <p *ngIf="event.description">{{event.description}}</p>

    <div class="dialog-item">
        <mat-icon class="icon">schedule</mat-icon>
        <span>{{event.start | date: 'HH:mm'}} - {{event.end | date: 'HH:mm'}}</span>
    </div>

    <div class="dialog-item">
        <mat-icon class="icon">event</mat-icon>
        <span>{{event.start | date: 'dd.MM.YYYY'}}</span>
    </div>

    <div class="dialog-item" *ngIf="event.place">
        <mat-icon class="icon">place</mat-icon>
        <span>{{event.place}}</span>
    </div>

    <div class="dialog-item" *ngIf="event.limitedParticipants! > 0">
        <button mat-icon-button (click)="memberToggle = !memberToggle" color="primary">
            <mat-icon>group</mat-icon>
        </button>
        <span>{{event.participantIds?.length}} / {{event.limitedParticipants}}</span>
    </div>

    <div class="dialog-item participants" *ngIf="memberToggle">
        <span *ngFor="let participant of participants">{{participant}}</span>
    </div>

</div>
<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close>Schließen</button>
    <button mat-raised-button color="primary" *ngIf="!event.participantIds?.includes(user.id!)"
        [disabled]="(event.participantIds?.length! > 0 && event.participantIds?.length! >= event.limitedParticipants!) || event.blocked"
        (click)="book()">Teilnehmen</button>
    <button mat-raised-button color="warn" *ngIf="event.participantIds?.includes(user.id!)" (click)="cancelBooking()"
        [disabled]="event.blocked">Stornieren</button>
</div>