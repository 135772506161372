<h1 mat-dialog-title>Ordner hinzufügen</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <span matPrefix>{{data.prefix}}</span>
    <input matInput [(ngModel)]="newFolderName">
  </mat-form-field>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button matDialogClose>Abbrechen</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="newFolderName">Speichern</button>
</div>