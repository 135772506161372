<div class="downloads">
    <h3>Downloads</h3>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Neue Datei(en) hochladen.
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <button mat-raised-button (click)="fileInput.click()" *ngIf="selectedFiles.length < 1">
                <mat-icon>upload</mat-icon>
                Datei(en) auswählen
            </button>
            <input class="upload" type="file" multiple accept="application/pdf" #fileInput
                (change)="onFileSelected($event)" />
            <ul>
                <li *ngFor="let file of selectedFiles">{{ file.name }} {{file.size | filesize}}</li>
            </ul>
        </div>
        <mat-action-row *ngIf="selectedFiles.length > 0">
            <button mat-button (click)="cancel()">Abbrechen</button>
            <button mat-raised-button color="primary" (click)="upload()">Hochloaden</button>
        </mat-action-row>
    </mat-expansion-panel>

    <mat-card class="card-table">
        <mat-card-content>
            <mat-form-field appearance="outline" class="search">
                <mat-label>Suche</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
            </mat-form-field>

            <div class="table">
                <table mat-table [dataSource]="dataSource" class="">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let file"> {{file.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef class="hide-xs"> Datum </th>
                        <td mat-cell *matCellDef="let file" class="hide-xs"> {{file.created | date: 'dd.MM.yyyy'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="size">
                        <th mat-header-cell *matHeaderCellDef class="hide-xs"> Größe </th>
                        <td mat-cell *matCellDef="let file" class="hide-xs"> {{file.size | filesize}} </td>
                    </ng-container>

                    <ng-container matColumnDef="download">
                        <th mat-header-cell *matHeaderCellDef> Download </th>
                        <td mat-cell *matCellDef="let file">
                            <a mat-icon-button target="_blank" [href]="file.downloadUrl">
                                <mat-icon>open_in_new</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef> Löschen </th>
                        <td mat-cell *matCellDef="let file">
                            <button mat-icon-button (click)="delete(file)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Keine Suchergebnisse für die Eingabe "{{input.value}}"</td>
                    </tr>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

</div>