import { CustomFile, FileType } from './../models/customFile.model';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage'; import { MatSnackBar } from '@angular/material/snack-bar';
;
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private matSnackBar: MatSnackBar
  ) { }

  upload(file: any, fileType: FileType): any {
    const id = this.db.createId();
    const path = `files/${id}-${file.name}`;
    const ref = this.storage.ref(path);
    const task = this.storage.upload(path, file);

    task.then(snapshot => {
      snapshot.ref.getDownloadURL().then(url => {
        const newFile: CustomFile = {
          id: id,
          created: new Date().getTime(),
          name: file.name,
          downloadUrl: url,
          path: path,
          type: fileType,
          size: file.size
        };
        this.db.collection('files').doc(id).set(newFile).then(() => {
          this.matSnackBar.open('Datei wurde erfolgreich hochgeladen.', undefined, { duration: 2500 });
          return 'test';
        }).catch(err => {
          console.log(err);
          this.matSnackBar.open('Ups, etwas ist schiefgelaufen.', undefined, { duration: 2500 });
        });
      });
    });
  }

  getAll(): Observable<CustomFile[]> {
    return this.db.collection<CustomFile>('files').valueChanges();
  }

  getByFileType(fileType: FileType): Observable<CustomFile[]> {
    return this.db.collection<CustomFile>('files', ref => ref
      .where('type', '==', fileType)
      .orderBy('name', 'asc')
    ).valueChanges();
  }

  delete(file: CustomFile): any {
    lastValueFrom(this.storage.ref(file.path).delete()).then(() => {
      this.matSnackBar.open('Datei wurde erfolgreich gelöscht.', undefined, { duration: 2500 });
      return this.db.collection<CustomFile>('files').doc(file.id).delete();
    }).catch(err => console.log);
  }

}
