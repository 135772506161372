import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Sport } from 'src/app/models/sport.model';
import { SportService } from 'src/app/services/sport.service';

@Component({
  selector: 'tsv-ext-sports-list',
  templateUrl: './ext-sports-list.component.html',
  styleUrls: ['./ext-sports-list.component.scss']
})
export class ExtSportsListComponent implements OnInit {

  sports$: Observable<Sport[]>;

  constructor(
    private sportService: SportService
  ) { }

  ngOnInit(): void {
    this.sports$ = this.sportService.getSports();
  }

}
