<div class="ext-news-preview">
    <mat-card class="ext-news-preview-card" *ngIf="news">
        <mat-card-header>
            <mat-card-title>{{news.headline}}</mat-card-title>
            <mat-card-subtitle>{{news.subheadline}}</mat-card-subtitle>
            <mat-card-subtitle>{{news.created | date: 'dd.MM.YY, HH:mm'}}</mat-card-subtitle>
            <span class="all-spacer"></span>
            <button mat-icon-button [matMenuTriggerFor]="menu"
                *ngIf="user?.roles?.includes(reporterRole) || user?.roles?.includes(adminRole)">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="edit()">
                    <mat-icon>edit_note</mat-icon>
                    Bearbeiten
                </button>
                <button mat-menu-item (click)="delete(news.id!)">
                    <mat-icon>delete</mat-icon>
                    Löschen
                </button>
            </mat-menu>
        </mat-card-header>
        <img class="ext-news-card-img" [src]="news.imageUrl" mat-card-image>
        <mat-card-content class="content">
            <p class="old-content" *ngIf="news.content && !news.html" [innerHTML]="news.content"></p>
            <div class="new-content" *ngIf="news.html" [innerHTML]="news.html"></div>
        </mat-card-content>

        <mat-chip-listbox class="attachments">
            <a mat-chip *ngFor="let file of news.files" target="_blank" [href]="file.downloadUrl">
                <mat-icon matChipAvatar>download</mat-icon>
                {{file.name}}
            </a>
        </mat-chip-listbox>

    </mat-card>
</div>