<div class="files">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                Downloads
            </mat-card-title>
            <mat-card-subtitle>
                Hier findest du alle wichtigen Dateien, welche du unter anderem für deine Anmeldung beim Verein
                benötigst.
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="outline" class="search">
                <mat-label>Suche</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Bspw. Anleitung" #input>
            </mat-form-field>

            <div class="table">
                <table mat-table [dataSource]="dataSource" class="">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let file"> {{file.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef class="hide-xs"> Datum </th>
                        <td mat-cell *matCellDef="let file" class="hide-xs"> {{file.created | date: 'dd.MM.yyyy'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="download">
                        <th mat-header-cell *matHeaderCellDef> Download </th>
                        <td mat-cell *matCellDef="let file">
                            <a mat-icon-button target="_blank" [href]="file.downloadUrl">
                                <mat-icon>open_in_new</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Keine Suchergebnisse für die Eingabe "{{input.value}}"</td>
                    </tr>
                </table>
            </div>

        </mat-card-content>
    </mat-card>

</div>