import { UserService } from 'src/app/services/user.service';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Sport } from '../models/sport.model';
import { User } from '../models/user.model';
import { Team } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class SportService {

  private sportCollection: AngularFirestoreCollection<Sport>;

  constructor(
    private firestore: AngularFirestore
  ) {
    this.sportCollection = firestore.collection<Sport>('sports');
  }

  /**
   * Creates a sport document in the sports collection.
   * @param sport
   * @returns 
   */
  createSport(sport: Sport): Promise<any> {
    sport.id = this.firestore.createId();
    return this.sportCollection.doc(sport.id).set(sport);
  }

  updateSport(sport: Sport): Promise<any> {
    return this.sportCollection.doc(sport.id).update(sport);
  }

  delete(sportId: string): Promise<void> {
    return this.sportCollection.doc(sportId).delete();
  }

  /**
   * Gets sports collection.
   * @param name for enabling a search filter on the reference.
   * @param limit for limiting the number of received docs.
   * @returns Observable<Sport[]>
   */
  getSports(limit: number = 25): Observable<Sport[]> {
    return this.firestore.collection<Sport>('sports', ref => ref
      .limit(limit)
      .orderBy('name', 'asc')
    ).valueChanges();
  }

  getSportById(id: string): Observable<Sport | any> {
    return this.sportCollection.doc(id).valueChanges();
  }

  getSportsByIds(ids: string[]): Observable<Sport[]> {
    return this.firestore.collection<Sport>('sports', ref => ref
      .where('id', 'in', ids)
    ).valueChanges();
  }

  getAllSportsUserIsAdminTo(userId: string): Observable<Sport[]> {
    return this.firestore.collection<Sport>('sports', ref => ref
      .where(userId, 'in', 'trainerIds')
    ).valueChanges()
  }

}
