import { Observable, map, shareReplay } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Component, OnInit, inject } from '@angular/core';
import { User } from './models/user.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from './services/settings.service';
import { SettingsGlobal } from './models/settings-global.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
  * Global settings key.
  */
  readonly SETTINGS_KEY: string = 'global-settings';

  /**
   * Stores the global settings, see interface for details.
   */
  settings: SettingsGlobal;

  user$: Observable<User>;
  routerUrl: string = '';
  fanShopUrl: string = 'https://tsvtravemuende.fan12.de/';

  private breakpointObserver = inject(BreakpointObserver);

  constructor(
    private authService: AuthService,
    private router: Router,
    private settingsService: SettingsService
  ) {
  }

  ngOnInit(): void {
    this.settingsService.get(this.SETTINGS_KEY).subscribe(settings => {
      this.settings = settings[0];
    })
    this.user$ = this.authService.user$;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routerUrl = this.router.url;
      }
    });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

}
