import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Event } from 'src/app/models/event.model';
import { Sport } from 'src/app/models/sport.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { SportService } from 'src/app/services/sport.service';
import { EventSettingsDialogComponent } from '../event-settings-dialog/event-settings-dialog.component';
import { Roles } from 'src/app/models/roles.enum';
import { AuthorType } from 'src/app/models/authorType.enum';

@Component({
  selector: 'tsv-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {

  @Input() event: Event;

  adminRole: Roles = Roles.ADMIN;
  reporterRole: Roles = Roles.REPORTER;
  sport: Sport;
  user: User;

  constructor(
    private sportService: SportService,
    private authService: AuthService,
    private eventService: EventService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fetchAuthorIfExistent();
    this.authService.user$.subscribe(user => this.user = user);
  }

  private fetchAuthorIfExistent(): void {
    if (this.event.authorId !== '') {
      this.sportService.getSportById(this.event.authorId!).subscribe(sport => this.sport = sport);
    }
  }

  edit(): void {
    this.dialog.open(EventSettingsDialogComponent, { data: this.event });
  }

  delete(event: Event): void {
    this.eventService.delete(event);
  }

  follow(): void {
    this.event.participantIds?.push(this.user?.id!);
    this.eventService.update(this.event);
  }

  unfollow(): void {
    this.event.participantIds = this.event.participantIds?.filter(item => item !== this.user?.id!);
    this.eventService.update(this.event);
  }

  canEdit(): boolean {
    // user is admin
    if (this.user?.roles.includes(this.adminRole)) {
      return true;
    }
    // user is trainer of the sport which is the author
    else if (this.sport?.trainerIds.includes(this.user?.id!)) {
      return true;
    }
    // user is a reporter and the event is global 
    else if (this.event.authorType == AuthorType.EVENT  && this.user?.roles.includes(this.reporterRole)) {
      return true;
    } else {
      return false;
    }
  }
}
