<div class="wrapper">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Benutzer bearbeiten</mat-card-title>
        </mat-card-header>
        <mat-card-content [formGroup]="form" class="form">
    
            <mat-form-field appearance="outline">
                <mat-label>Vorname</mat-label>
                <input matInput placeholder="Max" formControlName="firstName" required>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
                <mat-label>Nachname</mat-label>
                <input matInput placeholder="Mustermann" formControlName="lastName" required>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="max.mustermann@tsv.de" formControlName="email" required>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
                <mat-label>Rollen</mat-label>
                <mat-select multiple formControlName="roles">
                    <mat-option *ngFor="let role of roles" [value]="role">
                        {{role}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
    
        </mat-card-content>
        <mat-card-actions class="actions">
            <button mat-flat-button (click)="cancel()">Abbrechen</button>
            <button mat-raised-button color="primary" (click)="update()">Speichern</button>
        </mat-card-actions>
    </mat-card>    
</div>