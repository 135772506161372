import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { DeleteDialogComponent } from 'src/app/dialogs/delete-dialog/delete-dialog.component';
import { Roles } from 'src/app/models/roles.enum';
import { Team } from 'src/app/models/team.model';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'tsv-team-settings',
  templateUrl: './team-settings.component.html',
  styleUrls: ['./team-settings.component.scss']
})
export class TeamSettingsComponent implements OnInit {

  team: Team;
  userRole: Roles = Roles.USER;
  coachRole: Roles = Roles.COACH;
  form: FormGroup = this.formBuilder.group({
    name: [''],
    coachIds: [['']],
    memberIds: [['']]
  });

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private teamService: TeamService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      concatMap(params => this.teamService.getTeamById(params['teamId']))
    ).subscribe(team => {
      this.team = team;
      this.initForm();
    })
  }

  initForm(): void {
    this.syncCoaches(this.team.coachIds);
    this.syncMembers(this.team.memberIds);
    this.form.controls['name'].setValue(this.team.name);
  }

  update(): void {
    const team = this.team;
    team.name = this.form.get('name')?.value!;
    team.coachIds = this.form.get('coachIds')?.value!;
    team.memberIds = this.form.get('memberIds')?.value!;
    team.modified = new Date().getTime();

    this.teamService.update(team).then(() => {
      this.matSnackBar.open(
        'Team wurde erfolgreich erstellt.',
        'Ok!',
        { duration: 2000 }
      );
      this.cancel();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  cancel(): void {
    this.router.navigate(['mannschaft/' + this.team.id]);
  }

  delete(): void {
    this.dialog.open(DeleteDialogComponent, { data: { type: 'Team' } }).afterClosed().subscribe(bool => {
      if (bool) {
        this.teamService.delete(this.team).then(() => {
          this.matSnackBar.open(
            'Team wurde erfolgreich gelöscht.',
            'Ok!',
            { duration: 2000 }
          );
          this.router.navigate(['/']);
        })
          .catch(err => {
            this.matSnackBar.open(
              'Etwas ist schiefgelaufen.',
              'Hm...',
              { duration: 5000 }
            );
            console.log(err);
          });
      }
    });
  }

  /**
  * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
  * @param userIds string[]
  */
  syncCoaches(userIds: string[]) {
    this.form.controls['coachIds'].setValue(userIds);
  }
  /**

   * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
   * @param userIds string[]
   */
  syncMembers(userIds: string[]) {
    this.form.controls['memberIds'].setValue(userIds);
  }

}
