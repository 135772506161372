import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorType } from 'src/app/models/authorType.enum';
import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'tsv-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss']
})
export class EventCreateComponent implements OnInit {

  /**
   * Given authortype for later filtering
   */
  readonly EVENT_TYPE: AuthorType = AuthorType.TEAM;

  /**
   * Currently authenticated user.
   */
  user: User;

  /**
   * Id of the team from the url.
   */
  teamId: string;

  /**
   * Optioanl event and eventId, which only gets synced if this component is opened for editing.
   */
  eventId?: string | null;
  event?: Event;

  /**
   * Boolean to state if the component is currently used for an update or create operation.
   */
  updatingEvent: boolean = false;

  /**
   * A verbal rendition of the actual conversion of the event(s)
   */
  readableSentence: string;

  /**
   * A total count of events that will be created with the creation of the series.
   */
  totalCount: number;

  /**
   * Form to group all relevant attributes within the event creation.
   */
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    day: [new Date(), Validators.required],
    description: [''],
    place: [''],
    start: ['12:00', Validators.required],
    end: ['13:00', Validators.required],
    limitedParticipants: [0],
    recurringHourly: [false],
    recurringHourlyStart: ['08:00'],
    recurringHourlyEnd: ['20:00'],
    recurringDaily: [false],
    recurringDailyDays: [1],
    recurringWeekly: [{value: false, disabled: true}],
    recurringWeeklyWeeks: [{value: 1, disabled: true}]
  },
    { validators: this.endTimeGreaterThanStartTimeValidator.bind(this) }
  );

  constructor(
    private router: Router,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    // Get current authenticated user
    this.authService.user$.subscribe(user => this.user = user);
    // Get team id from url
    this.teamId = this.route.snapshot.paramMap.get('teamId')!;

    this.getEvent();
  }

  /**
   * Create event
   */
  create(): void {
    // Type of any
    const event: any = {
      created: new Date().getTime(),
      modified: new Date().getTime(),
      title: this.form.get('name')?.value,
      description: this.form.get('description')?.value,
      place: this.form.get('place')?.value,
      day: this.form.get('day')?.value!.getTime(),
      start: new Date(),
      end: new Date(),
      startHour: this.form.get('start')?.value,
      endHour: this.form.get('end')?.value,
      creatorId: this.user.id!,
      authorId: this.teamId,
      authorType: this.EVENT_TYPE,
      participantIds: [],
      limitedParticipants: this.form.get('limitedParticipants')?.value,
      totalCount: this.totalCount,
      recurring: {
        recurringHourly: this.form.get('recurringHourly')?.value,
        recurringHourlyStart: this.form.get('recurringHourlyStart')?.value,
        recurringHourlyEnd: this.form.get('recurringHourlyEnd')?.value,
        recurringDaily: this.form.get('recurringDaily')?.value,
        recurringDailyDays: this.form.get('recurringDailyDays')?.value,
        recurringWeekly: this.form.get('recurringWeekly')?.value,
        recurringWeeklyWeeks: this.form.get('recurringWeeklyWeeks')?.value
      }
    };

    if (event.recurring.recurringHourly) {
      event.start = this.formatTime(new Date(event.day), event.recurring.recurringHourlyStart).getTime();
      event.end = this.formatTime(new Date(event.day), this.addOneHourToTime(event.recurring.recurringHourlyStart)).getTime();
    } else {
      event.start = this.formatTime(new Date(event.day), event.startHour).getTime();
      event.end = this.formatTime(new Date(event.day), event.endHour).getTime();
    }

    this.eventService.create(event).then(() => {
      this.matSnackBar.open(
        'Veranstaltung wurde erfolgreich erstellt.',
        'Ok!',
        { duration: 2500 }
      );
      this.cancel();
    }).catch(err => {
      console.log(err);
      this.matSnackBar.open(
        'Entschuldigung, etwas ist schiefgelaufen.',
        undefined,
        { duration: 2500 }
      )
    });
  }

  update(): void {
    const event: any = this.event;
    event.title = this.form.get('name')?.value!;
    event.description = this.form.get('description')?.value!;
    event.place = this.form.get('place')?.value!;
    event.day = this.form.get('day')?.value!.getTime();
    event.startHour = this.form.get('start')?.value!;
    event.endHour = this.form.get('end')?.value!;
    event.modified = new Date().getTime();
    event.limitedParticipants = this.form.get('limitedParticipants')?.value!;

    event.start = this.formatTime(new Date(event.day), event.startHour).getTime();
    event.end = this.formatTime(new Date(event.day), event.endHour).getTime();

    this.eventService.update(event).then(() => {
      this.matSnackBar.open(
        'Veranstaltung wurde erfolgreich bearbeitet.',
        'Ok!',
        { duration: 2500 }
      );
      this.cancel();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Entschuldigung, etwas ist schiefgelaufen.',
          undefined,
          { duration: 2500 }
        );
        console.log(err);
      });
  }

  cancel(): void {
    this.router.navigate(['mannschaft', this.teamId]);
  }

  private formASentence(): void {
    // Retrieve form controls
    const dayControl = this.form.get('day');
    const startControl = this.form.get('start');
    const endControl = this.form.get('end');

    // Ensure form controls are available
    if (!dayControl || !startControl || !endControl) {
      // Handle error cases where controls are not available
      return;
    }

    // Retrieve date and format it
    const date = dayControl.value;
    const dateReadable = this.datePipe.transform(date, 'dd.MM.yyyy');

    // Calculate start and end time in milliseconds
    const startTime = this.formatTime(new Date(date), startControl.value).getTime();
    const endTime = this.formatTime(new Date(date), endControl.value).getTime();
    // Calculate duration in minutes
    const duration = (endTime - startTime) / 60000;

    // Initialize sentence
    let sentence = `Die Veranstaltung wird am ${dateReadable}`;

    // Initialize total count
    this.totalCount = 1;

    // Check for daily recurrence
    if (this.form.get('recurringDaily')?.value && this.form.get('recurringDailyDays')?.value > 1) {
      const recurringDailyDays = this.form.get('recurringDailyDays')!.value;
      sentence += ` und an ${recurringDailyDays - 1} darauffolgenden Tag(en)`;
      this.totalCount *= recurringDailyDays;
    }

    // Check for hourly recurrence
    if (this.form.get('recurringHourly')?.value) {
      const recurringHourlyStart = this.form.get('recurringHourlyStart')!.value;
      const recurringHourlyEnd = this.form.get('recurringHourlyEnd')!.value;
      const hourlyStartFormatted = this.formatTime(new Date(date), recurringHourlyStart).getTime();
      const hourlyEndFormatted = this.formatTime(new Date(date), recurringHourlyEnd).getTime();
      const dailyDuration = (hourlyEndFormatted - hourlyStartFormatted) / 60000;

      sentence += ` von ${recurringHourlyStart} bis ${recurringHourlyEnd} Uhr`;
      this.totalCount *= dailyDuration / duration;
    }

    // Check for weekly recurrence
    if (this.form.get('recurringWeekly')?.value && this.form.get('recurringWeeklyWeeks')?.value > 1) {
      const recurringWeeklyWeeks = this.form.get('recurringWeeklyWeeks')!.value;
      sentence += ` über ${recurringWeeklyWeeks} Wochen`;
      this.totalCount *= recurringWeeklyWeeks;
    }

    // Append duration to sentence
    sentence += ` mit einer Dauer von ${duration} Minuten insgesamt ${this.totalCount} mal erstellt.`;

    // Update the sentence
    this.readableSentence = sentence;
  }

  private formatTime(day: Date, start: string): Date {
    // Clone the 'day' Date object to avoid modifying the original object
    const dayWithStartTime = new Date(day);

    // Parse the 'start' string into hours and minutes
    const [startHoursStr, startMinutesStr] = start.split(':');
    const startHours = parseInt(startHoursStr, 10);
    const startMinutes = parseInt(startMinutesStr, 10);

    // Set the hours and minutes of the 'dayWithStartTime'
    dayWithStartTime.setHours(startHours, startMinutes, 0, 0);

    return dayWithStartTime;
  }

  private addOneHourToTime(timeStr: string): string {
    // Parse the input time string into hours and minutes
    const [hoursStr, minutesStr] = timeStr.split(':');
    const hours = parseInt(hoursStr);
    const minutes = parseInt(minutesStr);

    // Create a Date object and set the time
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    // Add one hour
    date.setHours(date.getHours() + 1);

    // Format the result as "HH:mm"
    const formattedTime = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

    return formattedTime;
  }

  /**
   * Checks if the component is used for updating an existing event or creating a new one,
   * as different actions are defined for each scenarios.
   */
  private getEvent(): void {
    // Get event id from url if called from an existing event
    this.eventId = this.route.snapshot.paramMap.get('eventId');
    if (!!this.eventId) {
      this.updatingEvent = true;
      this.eventService.get(this.eventId).subscribe(event => {
        this.event = event;
        this.initForm(event!);
      });
    } else {

      // Subscribe to the form to call function on every change
      this.form.valueChanges.subscribe(() => this.formASentence());
    }
  }

  /**
   * Sets values to the form of the event.
   * Only gets called when this component is used for an update.
   * @param event {Event}
   */
  private initForm(event: Event): void {
    this.form.controls['name'].setValue(event.title);
    this.form.controls['day'].setValue(new Date(event.day));
    this.form.controls['description'].setValue(event.description);
    this.form.controls['place'].setValue(event.place);
    this.form.controls['start'].setValue(event.startHour);
    this.form.controls['end'].setValue(event.endHour);
    this.form.controls['limitedParticipants'].setValue(event.limitedParticipants);
  }

  /**
   * Custom validator function.
   * @param control {AbstractControl}
   * @returns error {ValidationErrors | null}
   */
  private endTimeGreaterThanStartTimeValidator(control: AbstractControl): ValidationErrors | null {
    const start = control.get('start')?.value;
    const end = control.get('end')?.value;

    if (!start || !end) {
      return null; // Don't perform validation if either start or end is not present
    }

    const startTime = this.parseTimeString(start);
    const endTime = this.parseTimeString(end);

    if (startTime && endTime && startTime >= endTime) {
      this.matSnackBar.open('Das Ende der Veranstaltung muss nach dem Anfang liegen.', 'Schließen', {
      });
      return { endTimeGreaterThanStartTime: true };
    }

    return null;
  }

  /**
   * Helper function to parse time string into Date object.
   * @param timeString {string}
   * @returns date {Date | null}
   */
  private parseTimeString(timeString: string): Date | null {
    const [hours, minutes] = timeString.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

}
