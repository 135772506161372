import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { NewsService } from 'src/app/services/news.service';
import { News, NewsFile } from 'src/app/models/news.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sport } from 'src/app/models/sport.model';
import { Roles } from 'src/app/models/roles.enum';
import { Editor, Toolbar } from 'ngx-editor';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ImageUpload } from 'src/app/models/imageUpload.model';

@Component({
  selector: 'tsv-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.scss']
})
export class NewsCreateComponent implements OnInit, OnDestroy {

  newsId: string;
  imageUploads: string[] = [];
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3'] }],
    ['link', 'image']
  ];

  today = new Date();
  user: User;
  imageUrl: string;
  reporterRole: Roles = Roles.REPORTER;
  form = this.formBuiler.group({
    headline: ['', Validators.required],
    subheadline: [''],
    featured: [false, Validators.required],
    featuredUntil: [this.today],
    html: ['', Validators.required]
  });
  files: NewsFile[] = [];

  constructor(
    private authService: AuthService,
    private newsService: NewsService,
    private formBuiler: FormBuilder,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<NewsCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: Sport,
    private firestore: AngularFirestore
  ) {
    this.newsId = this.firestore.createId();
  }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => this.user = user);
    this.editor = new Editor();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  createNews(): void {
    const news: News = {
      headline: this.form.get('headline')?.value!,
      subheadline: this.form.get('subheadline')?.value!,
      html: this.form.get('html')?.value!,
      created: new Date().getTime(),
      modified: new Date().getTime(),
      creatorId: this.user.id!,
      featured: this.form.get('featured')?.value!,
      featuredUntil: this.form.get('featuredUntil')?.value!.getTime() == this.today.getTime() ? new Date(0).getTime() : this.form.get('featuredUntil')?.value!.getTime(),
      imageUrl: this.imageUrl ? this.imageUrl : '',
      authorId: this.dialogData ? this.dialogData.id : '',
      id: this.newsId,
      imageUploads: this.imageUploads,
      files: this.files
    };
    this.newsService.create(news).then(() => {
      this.matSnackBar.open(
        'Neuigkeit wurde erfolgreich erstellt.',
        'Ok!',
        { duration: 2000 }
      );
      this.dialogRef.close();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  /**
   * Gets called on change of the emitted downloadURL of the image-picker and syncs the url to the create object.
   * @param url string
   */
  syncDownloadURL(url: string) {
    this.imageUrl = url;
  }

  imagesUploadedEvent(imageUploads: ImageUpload[]): void {
    imageUploads.forEach(img => this.imageUploads.push(img.url ? img.url : ''));
  }

  onUploadCompleted(newsFiles: NewsFile[]): void {
    this.files = newsFiles;
  }

}
