import { animate, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Image } from 'src/app/models/images.model';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'tsv-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => visible', [
        style({ transform: 'scale(0.5' }),
        animate('150ms', style({ transform: 'scale(1)' }))
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' }))
      ])
    ]),
    trigger('animation2', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0.8 }))
      ])
    ])
  ]
})
export class GalleryComponent implements OnInit {

  folders: string[] = [];
  images: Image[] = [];

  previewImage = false;
  showCount = true;
  showMask = false;
  currentLightboxImage: Image = this.images[0];
  currentIndex: number = 0;
  controls = true;
  totalImageCount: number = 0;

  path: string[] = [];
  root: string = 'gallery/';

  constructor(
    private galleryService: GalleryService
  ) { }

  ngOnInit(): void {
    this.syncStorage();
  }

  syncStorage(): void {
    this.folders = [];
    this.images = [];
    this.galleryService.get(this.path).then((res) => {
      res.prefixes.forEach((folderRef: any) => {
        this.folders.push(folderRef.name);
        this.folders.sort();
      });
      res.items.forEach((itemRef: any) => {
        itemRef.getMetadata().then((data: any) => {
          if (data.name != 'newFolder') {
            itemRef.getDownloadURL().then((url: any) => {
              const image: Image = {
                fullPath: data.fullPath,
                size: data.size,
                name: data.name,
                updated: data.updated,
                created: data.created ? data.created : '',
                url: url
              }
              this.images.push(image);
              this.images.sort((a, b) => a.name.localeCompare(b.name));
            });
          }
        });
      });
    });
  }

  onFolderClick(folder: string): void {
    this.path.push(folder);
    this.syncStorage();
  }

  onHomeClick(): void {
    this.path = [];
    this.syncStorage();
  }

  onPathClick(folder: string) {
    this.removePathsFromLast(folder);
    this.syncStorage();
  }

  removePathsFromLast(inputString: string): void {
    const index = this.path.indexOf(inputString);

    if (index !== -1) {
      // If the inputString is found in the array
      this.path.splice(index + 1);
    } else {
      // Handle the case when the inputString is not found in the array
      console.error(`${inputString} not found in paths array`);
    }
  }

  onPreviewImage(index: number): void {
    this.totalImageCount = this.images.length;
    this.showMask = true;
    this.previewImage = true;
    this.currentIndex = index;
    this.currentLightboxImage = this.images[index];
  }

  onAnimationEnd(event: AnimationEvent): void {
    if (event.toState === 'void') {
      this.showMask = false;
    }
  }

  onClosePreview(): void {
    this.previewImage = false;
  }

  prev(): void {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.images.length - 1;
    }
    this.currentLightboxImage = this.images[this.currentIndex];
  }

  next(): void {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex > this.images.length - 1) {
      this.currentIndex = 0;
    }
    this.currentLightboxImage = this.images[this.currentIndex];
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // Call your method here
      this.onClosePreview();
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    // Define the list of class names to exclude
    const excludedClasses = ['btn-lightbox-carousel', 'count', 'close-btn', 'gallery', 'lightbox-img']; // Add any classes you want to exclude

    // Check if the clicked element or any of its ancestors have the excluded classes
    if (!this.isElementWithinClasses(event.target, excludedClasses)) {
      // Call your method to close the dialog
      this.onClosePreview();
    }
  }

  isElementWithinClasses(element: any, classes: string[]): boolean {
    // Check if the clicked element or any of its ancestors have the excluded classes
    while (element) {
      if (element.classList && classes.some(className => element.classList.contains(className))) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  }

}