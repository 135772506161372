import { SettingsContact } from './../../../models/settingsContact.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'tsv-admin-contact',
  templateUrl: './admin-contact.component.html',
  styleUrls: ['./admin-contact.component.scss']
})
export class AdminContactComponent implements OnInit {

  imageUrl: string;
  contact: SettingsContact;
  form = this.formBuilder.group({
    place: [''],
    phone: [''],
    mail: [''],
    description: [''],
    hours: ['']
  });

  constructor(
    private formBuilder: FormBuilder,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /**
  * Gets called on change of the emitted downloadURL of the image-picker and syncs the url to the create object.
  * @param url string
  */
  syncDownloadURL(url: string) {
    this.imageUrl = url;
  }

  initForm(): void {
    this.settingsService.get('contact').subscribe((contacts: SettingsContact[]) => {
      const contact = contacts[0];
      this.contact = contact;

      this.form.controls.place.setValue(contact.place);
      this.form.controls.phone.setValue(contact.phone);
      this.form.controls.mail.setValue(contact.mail);
      this.form.controls.description.setValue(contact.description);
      this.form.controls.hours.setValue(contact.hours);
      this.imageUrl = contact.imageUrl;
    });
  }

  update(): void {
    const contact: SettingsContact = this.contact;
    contact.modified = new Date().getTime();
    contact.place = this.form.controls.place.value!;
    contact.phone = this.form.controls.phone.value!;
    contact.mail = this.form.controls.mail.value!;
    contact.description = this.form.controls.description.value!;
    contact.hours = this.form.controls.hours.value!;
    contact.imageUrl = this.imageUrl;

    this.settingsService.update(contact);
  }

}
