import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImagePickerDialogComponent } from './image-picker-dialog/image-picker-dialog.component';

@Component({
  selector: 'tsv-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {

  /**
   * Optional input of an image url to have it displayed on already created object.
   */
  @Input() img: string;

  /**
   * Optional input of the image croppers ratio. 
   * The number needs to be of the according format, eg. '4/3'.
   */
  @Input() ratio: number;

  /**
   * Outputs the download url after upload completion.
   */
  @Output() downloadURLEvent: EventEmitter<string> = new EventEmitter<string>;

  /**
   * Fallback image when no input is given.
   */
  imageSource = 'https://firebasestorage.googleapis.com/v0/b/dev-tsv-travemuende.appspot.com/o/default%2Fdefault-sports-image.png?alt=media&token=f1baf33f-3c3b-43f2-b491-a1109b8d5770';

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.img) {
      this.imageSource = this.img;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ImagePickerDialogComponent, {
      data: {
        ratio: this.ratio
      }
    });
    dialogRef.afterClosed().subscribe(dialog => {
      if (dialog.data) {
        this.imageSource = dialog.data;
        this.downloadURLEvent.emit(dialog.data);
      }
    });
  }

}
