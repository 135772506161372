<div class="sport-flexbox" *ngIf="sport && user">

    <button mat-fab class="fab" (click)="openDialog()"
        *ngIf="user.roles.includes(adminRole) || sport.coachIds?.includes(user.id!) || sport.trainerIds.includes(user.id!)">
        <mat-icon>article</mat-icon>
    </button>

    <div class="sport">

        <!-- Card to display for medium and large width -->
        <mat-card class="sport-card-lg hide-sm">

            <mat-card-header class="sport-card-header">
                <div class="sport-card-img-wrapper">
                    <img class="sport-card-img" [src]="sport.imageUrl" mat-card-avatar>
                </div>
                <div class="sport-card-text">
                    <mat-card-title class="sport-card-title">{{sport.name}}</mat-card-title>
                    <mat-card-subtitle
                        [ngClass]="more ? 'sport-card-subtitle-more' : 'sport-card-subtitle-less'">{{sport.description}}</mat-card-subtitle>
                </div>

                <div class="sport-card-options">
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="sport-card-settings"
                        *ngIf="sport.links?.length! > 0" matTooltip="Links" matTooltipPosition="before">
                        <mat-icon>link</mat-icon>
                    </button>
                    <button mat-icon-button [routerLink]="'einstellungen'"
                        *ngIf="user.roles.includes(adminRole) || sport.coachIds?.includes(user.id!) || sport.trainerIds.includes(user.id!)"
                        matTooltip="Einstellungen" matTooltipPosition="before">
                        <mat-icon>settings</mat-icon>
                    </button>

                    <button mat-icon-button (click)="more = !more"
                        [matTooltip]="more ? 'Weniger anzeigen' : 'Mehr anzeigen'" matTooltipPosition="before">
                        <mat-icon>{{more ? 'expand_less' : 'expand_more'}}</mat-icon>
                    </button>
                </div>
            </mat-card-header>

            <mat-card-content *ngIf="more" class="sport-contact">
                <div class="contact" *ngIf="sport.contact">
                    <mat-list *ngIf="sport.contact.name">
                        <mat-list-item>
                            <mat-icon matListItemIcon class="contact-icon">person</mat-icon>
                            <div matListItemTitle>{{sport.contact.name}}</div>
                            <div matListItemLine>Kontakt</div>
                        </mat-list-item>
                    </mat-list>

                    <button mat-stroked-button (click)="call()">
                        <mat-icon>phone</mat-icon>
                        <span>Telefon</span>
                    </button>

                    <button mat-stroked-button (click)="email()">
                        <mat-icon>email</mat-icon>
                        <span>E-Mail</span>
                    </button>
                </div>

                <div class="sport-information" *ngIf="sport.information">
                    <h4>Informationen</h4>
                    <p class="information" [innerHTML]="sport.information"></p>
                </div>
            </mat-card-content>
        </mat-card>

        <!-- Card to display for small width -->
        <mat-card class="sport-card-sm hide-lg">
            <mat-card-header>
                <img mat-card-avatar class="avatar" [src]="sport.imageUrl">
                <mat-card-title>
                    {{sport.name}}
                </mat-card-title>
                <mat-card-subtitle [ngClass]="more ? 'subtitle-more' : 'subtitle-less'">
                    {{sport.description}}
                </mat-card-subtitle>
                <mat-card-subtitle *ngIf="more && sport.contact?.name" class="subtitle-contact-name">
                    <mat-icon>person</mat-icon>
                    Kontakt: {{sport.contact?.name}}
                </mat-card-subtitle>
                <div class="options">
                    <button mat-icon-button (click)="more = !more"
                        [matTooltip]="more ? 'Weniger anzeigen' : 'Mehr anzeigen'" matTooltipPosition="before">
                        <mat-icon>{{more ? 'expand_less' : 'expand_more'}}</mat-icon>
                    </button>
                    <button *ngIf="more" mat-icon-button (click)="call()">
                        <mat-icon>phone</mat-icon>
                    </button>
                    <button *ngIf="more" mat-icon-button (click)="email()">
                        <mat-icon>email</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="sport-card-settings"
                        *ngIf="more && sport?.links && sport.links?.length! > 0" matTooltip="Links"
                        matTooltipPosition="before">
                        <mat-icon>link</mat-icon>
                    </button>
                    <button mat-icon-button [routerLink]="'einstellungen'" matTooltip="Einstellungen" matTooltipPosition="before"
                    *ngIf=" more && (user.roles.includes(adminRole) || sport.coachIds?.includes(user.id!) || sport.trainerIds.includes(user.id!))">
                        <mat-icon>settings</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-card-content *ngIf="more && sport.information" class="sport-information">
                <h4>Informationen</h4>
                <p class="information" [innerHTML]="sport.information"></p>
            </mat-card-content>
        </mat-card>

        <!-- MatMenu for the links -->
        <mat-menu #menu [xPosition]="'before'" class="link-menu">
            <a *ngFor="let link of sport.links" mat-menu-item target="_blank" [href]="link.url" [matTooltip]="link.url">
                <!-- <mat-icon class="link-menu-item-icon">open_in_new</mat-icon> -->
                <span class="link-menu-item-name">{{link.name}}</span>
            </a>
        </mat-menu>

    </div>

    <div class="sport-content">
        <mat-tab-group [selectedIndex]="1">
            <mat-tab label="Teams">
                <div class="sport-teams">
                    <button class="teams-create-btn" mat-raised-button (click)="openTeamCreateDialog()"
                        *ngIf="user.roles.includes(adminRole) || sport.coachIds?.includes(user.id!) || sport.trainerIds.includes(user.id!)">
                        <mat-icon>group_add</mat-icon>
                        Team erstellen
                    </button>
                    <tsv-team-card *ngFor="let team of teams$ | async" [team]="team" tsvElevation
                        [currentUser]="user"></tsv-team-card>
                </div>
            </mat-tab>
            <mat-tab label="News">
                <div class="sport-news">
                    <tsv-timeline [authorId]="sport.id!"></tsv-timeline>
                </div>
            </mat-tab>
            <mat-tab label="Events">
                <div class="sport-events">
                    <button class="events-create-btn" mat-raised-button (click)="openEventCreateDialog()"
                        *ngIf="user.roles.includes(adminRole) || sport.coachIds?.includes(user.id!) || sport.trainerIds.includes(user.id!)">
                        <mat-icon>event</mat-icon>
                        Event erstellen
                    </button>
                    <tsv-event-card class="card" *ngFor="let event of events$ | async" [event]="event"></tsv-event-card>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>