import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'tsv-events-preview',
  templateUrl: './events-preview.component.html',
  styleUrls: ['./events-preview.component.scss']
})
export class EventsPreviewComponent implements OnInit {

  @Input() user: User;

  events$: Observable<Event[]>;

  constructor(
    private eventService: EventService
  ) { }

  //TODO
  ngOnInit(): void {
    this.events$ = this.eventService.getParticpating(this.user.id!);
  }

}
