import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, concatMap, map } from 'rxjs';
import { Sport } from 'src/app/models/sport.model';
import { SportService } from 'src/app/services/sport.service';

@Component({
  selector: 'tsv-sport-preview',
  templateUrl: './sport-preview.component.html',
  styleUrls: ['./sport-preview.component.scss']
})
export class SportPreviewComponent implements OnInit {

  /**
   * Observable of the current sport.
   */
  sport$: Observable<Sport>;

  /**
   * Current sport
   */
  sport: Sport;

  /**
   * Manages the state to show or hide additional information
   */
  more: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sportService: SportService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      concatMap(
        params => this.sportService.getSportById(params['sportId']))).subscribe(sport => this.sport = sport);
  }

  /**
 * Calls the native browser method to call the given telephone number.
 */
  call(): void {
    window.open('tel:' + this.sport.contact?.telephone);
  }

  /**
   * Calls the native browser method to write an email to the given email address.
   */
  email() {
    window.location.href = `mailto:${this.sport.contact?.email}`;
  }

}
