<div class="profile-flexbox-wrapper">
    <div class="profile-flexbox">
        <div class="profile-preview">
            <mat-card class="profile-card">
                <mat-card-header>
                    <img class="icon mat-elevation-z4" mat-card-avatar [src]="user.imageUrl">
                    <mat-card-title>{{user.firstName}} {{user.lastName}}</mat-card-title>
                    <mat-card-subtitle>{{user.position}}</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>
        <div class="profile-content">
        </div>
    </div>
</div>