import { SportService } from '../../../services/sport.service';
import { AuthService } from '../../../services/auth.service';
import { Observable, map } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { Sport } from 'src/app/models/sport.model';
import { Router } from '@angular/router';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  templateUrl: './sports-list.component.html',
  styleUrls: ['./sports-list.component.scss']
})
export class SportsListComponent implements OnInit {

  managerRole: Roles = Roles.MANAGER;
  adminRole: Roles = Roles.ADMIN;
  user: User;
  sports$: Observable<Sport[]>;
  searchValue = '';

  constructor(
    private authService: AuthService,
    private sportService: SportService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => this.user = user);
    this.sports$ = this.sportService.getSports();
  }

  // TODO: limit request for search to not exceed limits
  search(): void {
    const filter = this.searchValue;
    if (filter.length != 0) {
      this.sports$ = this.sports$.pipe(map(sports => sports.filter(sport => sport.name >= filter && sport.name <= filter + '\uf8ff')));
    } else {
      this.sports$ = this.sportService.getSports();
    }
  }

  navigateToSport(sportId: string): void {
    this.router.navigate(['sport/' + sportId]);
  }

}
