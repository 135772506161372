<div class="contact">
    <h3>Geschäftsstelle</h3>
    <form class="form" [formGroup]="form">

        <div class="form-image-picker" *ngIf="contact">
            <tsv-image-picker (downloadURLEvent)="syncDownloadURL($event)" [img]="imageUrl"></tsv-image-picker>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-icon matPrefix>place</mat-icon>
                <mat-label>Adresse</mat-label>
                <input matInput formControlName="place">
            </mat-form-field>
            <button mat-icon-button matTooltip="Adresse des Vereinsheim." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-icon matPrefix>phone</mat-icon>
                <mat-label>Telefon</mat-label>
                <input matInput formControlName="phone">
            </mat-form-field>
            <button mat-icon-button matTooltip="Telefonnummer für die Kontaktaufnahme." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-icon matPrefix>mail</mat-icon>
                <mat-label>Email</mat-label>
                <input matInput formControlName="mail">
            </mat-form-field>
            <button mat-icon-button matTooltip="Email für die Kontaktaufnahme." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Beschreibung</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
            <button mat-icon-button matTooltip="Beschreibung und Zusatzinformationen." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Öffnungszeiten</mat-label>
                <textarea matInput formControlName="hours"></textarea>
            </mat-form-field>
            <button mat-icon-button matTooltip="Öffnungszeiten der Geschäftsstelle." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

    </form>

    <div class="actions">
        <button mat-raised-button color="primary" (click)="update()">Speichern</button>
    </div>

</div>