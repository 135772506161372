<div class="create-sport-flexbox">
    <mat-card class="create-sport-card">
        <mat-card-header>
            <mat-card-title>Sportart erstellen</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="form" class="create-sport-form">

                <!-- Image picker-->
                <div class="form-image-picker">
                    <tsv-image-picker (downloadURLEvent)="syncDownloadURL($event)"></tsv-image-picker>
                </div>

                <!-- Name -->
                <div class="formfield-with-hint">
                    <mat-form-field appearance="outline" class="formfield">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>
                    <button mat-icon-button matTooltip="Name der Sportart." matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <!-- Description -->
                <div class="formfield-with-hint">
                    <mat-form-field appearance="outline" class="formfield">
                        <mat-label>Beschreibung</mat-label>
                        <textarea matInput formControlName="description"></textarea>
                    </mat-form-field>
                    <button mat-icon-button matTooltip="Eine optionale kurze Beschreibung der Sportart."
                        matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <!-- User chooser for 'Abteilungsleiter'-->
                <div class="formfield-with-hint">
                    <tsv-user-chooser [title]="'Abteilungsleiter'" [role]="role"
                        (selectedUsersEvent)="syncTrainers($event)" class="formfield"></tsv-user-chooser>
                    <button mat-icon-button
                        matTooltip="Abteilungsleiter können die Sportart bearbeiten und i dessen Namen Neuigkeiten und Veranstaltungen erstellen und bearbeiten."
                        matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <!-- User chooser for 'Trainers' -->
                <div class="formfield-with-hint">
                    <tsv-user-chooser [title]="'Trainer'" [role]="coachRole" (selectedUsersEvent)="syncCoaches($event)"
                        class="formfield"></tsv-user-chooser>
                    <button mat-icon-button
                        matTooltip="Trainer können im Namen der Sportart Neuigkeiten und Veranstaltungen erstellen und bearbeiten. Trainer können ein Team erstellen und bearbeiten. Trainer können in ihrem Team Veranstaltungen und Mitglieder verwalten."
                        matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <div class="formfield-with-hint">
                    <mat-form-field appearance="outline" class="formfield">
                        <mat-label>Kontakt</mat-label>
                        <input matInput formControlName="contactName">
                    </mat-form-field>
                    <button mat-icon-button matTooltip="Name der Kontaktperson." matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <div class="formfield-with-hint">
                    <mat-form-field appearance="outline" class="formfield">
                        <mat-label>Telephone</mat-label>
                        <input matInput formControlName="contactTelephone">
                    </mat-form-field>
                    <button mat-icon-button matTooltip="Telefonnummer der Kontaktperson." matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <div class="formfield-with-hint">
                    <mat-form-field appearance="outline" class="formfield">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="contactEmail">
                    </mat-form-field>
                    <button mat-icon-button matTooltip="Email der Kontaktperson." matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <div class="formfield-with-hint">
                    <mat-form-field appearance="outline" class="formfield">
                        <mat-label>Informationen</mat-label>
                        <textarea matInput formControlName="information"></textarea>
                    </mat-form-field>
                    <button mat-icon-button matTooltip="Weitere eventuelle nützliche Informationen."
                        matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <div class="formfield-with-hint">
                    <mat-expansion-panel class="formfield">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Links
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="links-manage">
                            <button mat-raised-button (click)="addLink()">Link hinzufügen</button>
                            <div cdkDropList (cdkDropListDropped)="drop($event)" class="links-list">
                                <div class="link" *ngFor="let link of links" cdkDrag>
                                    <p class="link-name">{{link.name}}</p>
                                    <div class="link-btns">
                                        <a mat-icon-button target="_blank" [href]="link.url" matTooltip="Link öffnen"
                                        matTooltipPosition="left">
                                            <mat-icon>link</mat-icon>
                                        </a>
                                        <button mat-icon-button (click)="rmLink(link.name)" matTooltip="Löschen"
                                            matTooltipPosition="left">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <button mat-icon-button matTooltip="Für die Sportart relevante Links. Du kannst die Reihenfolge der Links durch Drag & Drop anpassen." matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

            </form>

        </mat-card-content>
        <mat-card-actions class="actions">
            <button mat-button (click)="cancel()">Abbrechen</button>
            <button mat-raised-button color="primary" (click)="createSport()">Erstellen</button>
        </mat-card-actions>
    </mat-card>
</div>