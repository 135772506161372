export enum Roles {
    // only passively see content and join or leave
    USER = 'User',
    // can create sports
    MANAGER = 'Abteilungsleiter',
    // can be added as a coach to a team and then manage it accordingly
    COACH = 'Trainer',
    // can manage administration
    ADMIN = 'Admin',
    // can create global news and events
    REPORTER = 'Reporter'
}