import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Sport } from 'src/app/models/sport.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'tsv-sport-card',
  templateUrl: './sport-card.component.html',
  styleUrls: ['./sport-card.component.scss']
})
export class SportCardComponent implements OnInit {

  @Input() sport: Sport;
  @Input() size: string = 'md';

  user$: Observable<User>;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user$ = this.authService.user$;
  }
}
