import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageUpload } from 'src/app/models/imageUpload.model';
import { News } from 'src/app/models/news.model';
import { GalleryService } from 'src/app/services/gallery.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'tsv-img-upload',
  templateUrl: './img-upload.component.html',
  styleUrls: ['./img-upload.component.scss']
})
export class ImgUploadComponent implements OnInit {

  @Input() newsId: string;

  @Input() news: News;

  @Input() inputImageUrls: string[];

  @Output() imagesUploadedEvent: EventEmitter<ImageUpload[]> = new EventEmitter<ImageUpload[]>;

  images: ImageUpload[] = [];
  progress: number[] = [];
  tooltip: string = 'Wenn du weitere Bilder in deinen Inhalt einfügen möchtest lade diese Hoch und kopiere die zugehörige Url und binde dies mithilfe des Editors in den Text ein.';

  constructor(
    private storage: AngularFireStorage,
    private imageCompress: NgxImageCompressService,
    private snackBar: MatSnackBar,
    private galleryService: GalleryService,
    private clipboard: Clipboard,
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.inputImageUrls.forEach(img => {
      const item: ImageUpload = {
        url: img,
        progress: 100,
        img: img
      };
      this.images.push(item);
    });
  }

  async compressAndUpload(): Promise<void> {
    const today = new Date().getTime();

    try {
      const result = await this.imageCompress.uploadMultipleFiles();

      await Promise.all(
        result.map(async (res, index) => {
          const compressedImage = await this.imageCompress.compressFile(res.image, res.orientation);
          this.images[index] = {};

          this.images[index].img = res.image;

          const filePath = `news/${this.newsId}/${today}-${res.fileName}`;
          const ref = this.storage.ref(filePath);
          const task = ref.putString(compressedImage, 'data_url');

          // Track progress
          task.percentageChanges().subscribe((percentage) => {
            this.images[index].progress = Math.round(percentage!);
          });

          // Track successful upload and get download URL
          const snapshot = await task.snapshotChanges().toPromise();

          if (snapshot) {
            // Get download URL
            const downloadURL = await snapshot.ref.getDownloadURL();
            this.images[index].url = downloadURL;

            if (index == result.length - 1) {
              this.imagesUploadedEvent.emit(this.images);
            }
            // Do something with the download URL, e.g., store it in an array or perform further actions
          } else {
            console.error('Snapshot is undefined. Upload may have failed.');
          }
        })
      );
    } catch (error) {
      console.error('Error compressing or uploading image:', error);
    }
  }

  showTooltip(): void {
    this.snackBar.open(this.tooltip, undefined, { duration: 2500 });
  }

  delete(imageUpload: ImageUpload): void {
    const index = this.images.indexOf(imageUpload);
    if (index !== -1) {
      this.images.splice(index, 1);
      this.galleryService.delete(imageUpload.url!, true);
    } else {
      console.error('Object not found in the array.');
    }

    /**
     * Delete the entry from the news if updating and not creating
     */
    if (this.news) {
      const newsIndex = this.news.imageUploads?.indexOf(imageUpload.url!);
      this.news.imageUploads?.splice(newsIndex!, 1);
      this.newsService.update(this.news)
        .then(() => this.snackBar.open('Bild erfolgreich gelöscht.', undefined, { duration: 2500 }))
        .catch(err => console.log(err));
    }
  }

  copyUrl(imageUpload: ImageUpload): void {
    const index = this.images.indexOf(imageUpload);
    const url = this.images[index].url!;
    this.clipboard.copy(url);
    this.snackBar.open('Die Bild Url wurde kopiert.', 'Ok', { duration: 2500 });
  }

}
