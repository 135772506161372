import { Component } from '@angular/core';

@Component({
  selector: 'tsv-datasecurity',
  templateUrl: './datasecurity.component.html',
  styleUrls: ['./datasecurity.component.scss']
})
export class DatasecurityComponent {

}
