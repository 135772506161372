<a [routerLink]="(user$ | async) ? '/sport/' + sport.id : '/sportangebote/' + sport.id" class="sports-card-wrapper-sm"
    *ngIf="size === 'sm'">
    <mat-card class="sports-card">
        <mat-card-header class="sports-card-header">
            <img [src]="sport.imageUrl" class="sports-card-image" mat-card-avatar>
            <div class="sports-card-text">
                <mat-card-title class="sports-card-title">{{sport.name}}</mat-card-title>
                <mat-card-subtitle class="sports-card-subtitle">{{sport.description}}</mat-card-subtitle>
            </div>
        </mat-card-header>
    </mat-card>
</a>

<a [routerLink]="(user$ | async) ? '/sport/' + sport.id : '/sportangebote/' + sport.id" class="sports-card-wrapper-md" *ngIf="size === 'md'">
    <mat-card class="sports-card">
        <mat-card-header class="sports-card-header">
            <img [src]="sport.imageUrl" class="sports-card-image" mat-card-avatar>
            <div class="sports-card-text">
                <mat-card-title class="sports-card-title">{{sport.name}}</mat-card-title>
                <mat-card-subtitle class="sports-card-subtitle">{{sport.description}}</mat-card-subtitle>
            </div>
        </mat-card-header>
    </mat-card>
</a>