import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { NewsSettingsDialogComponent } from 'src/app/components/news/news-settings-dialog/news-settings-dialog.component';
import { DeleteDialogComponent } from 'src/app/dialogs/delete-dialog/delete-dialog.component';
import { News } from 'src/app/models/news.model';
import { Roles } from 'src/app/models/roles.enum';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'tsv-ext-news-preview',
  templateUrl: './ext-news-preview.component.html',
  styleUrls: ['./ext-news-preview.component.scss']
})
export class ExtNewsPreviewComponent implements OnInit {

  //TODO: rename this component and clearify usage
  // TODO: add the author like with the card for a clear design

  news: News;
  user: User | null;
  reporterRole: Roles = Roles.REPORTER;
  adminRole: Roles = Roles.ADMIN;

  constructor(
    private newsService: NewsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      concatMap(params => this.newsService.getById(params['newsId']))).subscribe(news => this.news = news);
    this.authService.user$.subscribe(user => this.user = user);
  }

  edit(): void {
    this.dialog.open(NewsSettingsDialogComponent, { data: this.news, maxWidth: '600px', width: '100%' });
  }

  delete(id: string): void {
    this.dialog.open(DeleteDialogComponent, { data: { type: 'Neuigkeit' } }).afterClosed().subscribe(bool => {
      if (bool) {
        this.newsService.delete(id).then(() => {
          this.matSnackBar.open(
            'Neuigkeit wurde erfolgreich gelöscht.',
            'Ok!',
            { duration: 2000 }
          );
          this.router.navigate(['/']);
        })
          .catch(err => {
            this.matSnackBar.open(
              'Etwas ist schiefgelaufen.',
              'Hm...',
              { duration: 5000 }
            );
            console.log(err);
          });
      }
    });
  }

}
