<h1 mat-dialog-title>Neuigkeit bearbeiten</h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="form">

        <div class="form-img-picker">
            <h3>Vorschaubild</h3>
            <tsv-image-picker [img]="news.imageUrl!" (downloadURLEvent)="syncDownloadURL($event)">
            </tsv-image-picker>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Schlagzeile</mat-label>
                <input matInput formControlName="headline" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Die Schlagzeile der Neuigkeit." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Unterüberschrift</mat-label>
                <input matInput formControlName="subheadline">
            </mat-form-field>
            <button mat-icon-button matTooltip="Die Unterüberschrift der Neuigkeit." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <tsv-img-upload [newsId]="newsId" [news]="news" (imagesUploadedEvent)="imagesUploadedEvent($event)" [inputImageUrls]="imageUploads"></tsv-img-upload>

        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor" formControlName="html" outputFormat="html"
                [placeholder]="'Hier schreiben...'"></ngx-editor>
        </div>

        <div class="formfield-toggle-hint" *ngIf="news.imageUrl && !news.authorId">
            <p>Featured</p>
            <mat-checkbox formControlName="featured">
            </mat-checkbox>
            <button mat-icon-button
                matTooltip="Gibt an ob diese Neuigkeit auf der Homepage im Swiper angezeigt wird. Nur Möglich mit einem Bild."
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint" *ngIf="news.imageUrl && !news.authorId">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Featured Bis:</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="featuredUntil">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
            <button mat-icon-button matTooltip="Legt fest bis wann die Neuigkeit im Swiper angezeigt wird."
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="primary" (click)="update()" [disabled]="form.invalid">Speichern</button>
</div>