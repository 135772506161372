import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { concat, last, map } from 'rxjs';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'tsv-image-picker-dialog',
  templateUrl: './image-picker-dialog.component.html',
  styleUrls: ['./image-picker-dialog.component.scss']
})
export class ImagePickerDialogComponent implements OnInit {

  ratio: number;

  /**
   * Defines the type of the image upload to restrict the according upload size.
   */
  type: string = 'news' || 'sport';

  /**
   * Tracks the percentage of the upload.
   */
  percentage: any;

  /**
   * Boolean to disable the upload button.
   */
  disabled: boolean = false;

  /**
   * Variables for the image cropper.
   */
  imageChangedEvent: any = '';
  croppedImage: any = '';

  /**
   * Variables for the image compression
   */
  imgResultBeforeCompression: any = '';
  imgResultAfterCompression: any = '';
  compressionOngoing = false;

  /**
   * Variables to handle the failing upload
   */
  uploadFailed = false;
  failedMessage = '';

  constructor(
    private imageService: ImageService,
    private dialogRef: MatDialogRef<ImagePickerDialogComponent>,
    private imageCompress: NgxImageCompressService,
    private matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.ratio) {
      this.ratio = this.data.ratio;
    }
  }

  /**
   * Upload the image to the firebase storage, get the download url and pass it to the parent component.
   */
  upload(): void {
    // disable upload button
    this.disabled = true;

    // get id for later url fetching
    const id = this.imageService.createId();

    const upload = this.imageService.uploadImage(this.croppedImage, id);
    upload.catch(err => {
      this.uploadFailed = true;
      this.croppedImage = '';

      if (err.message.includes('storage/unauthorized')) {
        this.failedMessage = 'Die Datei ist größer als 4MB. Reduziere die Größe oder wähle eine andere Datei.'
        this.matSnackBar.open('Die Datei ist größer als 4MB. Reduziere die Größe oder wähle eine andere Datei.', 'Ok');
      } else {
        this.failedMessage = 'Etwas ist mit dem Upload fehlgeschlagen, bitte überprüfe deine Internetverbindung und versuche es erneut.'
        this.matSnackBar.open('Etwas ist mit dem Upload fehlgeschlagen, bitte überprüfe deine Internetverbindung und versuche es erneut.', 'Ok');
      }
    })

    const uploadTask$ = upload.percentageChanges();
    const downloadURL$ = this.imageService.getDownloadURL(id);
    const concatedObs$ = concat(uploadTask$, downloadURL$);

    /**
     * First map the percentage and then use the last emitted value for the downloadURL.
     */
    concatedObs$
      .pipe(
        map(percentage => this.percentage = percentage))
      .pipe(
        last(),
        map(url => {

          // Set the percentage to 100, as the percentage observable is no longer subscribed to
          this.percentage = 100;

          // Close the dialog and add the download url to the dialogs data.
          this.dialogRef.close({ data: url });

        })).subscribe();
  }

  /**
   * Functions for the image cropper.
   */
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  /**
   * Select local file, upload it to the application
   */
  compressFile() {
    this.compressionOngoing = true;
    this.uploadFailed = false;

    const MAX_MEGABYTE = 4;

    this.imageCompress
      .uploadAndGetImageWithMaxSize(MAX_MEGABYTE)
      .then(
        (result: string) => {
          this.compressionOngoing = false;
          this.imgResultAfterCompression = result;
        },
        (result: string) => {
          console.error(
            "The compression algorithm didn't succed! The best size we can do is",
            this.imageCompress.byteCount(result),
            'bytes'
          );
          this.compressionOngoing = false;
          this.imgResultAfterCompression = result;
        }
      );
  }

}
