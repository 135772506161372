import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewsFile } from 'src/app/models/news.model';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'tsv-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  /**
   * Optional string to override the button text.
   * Defaults to 'Hochladen' if not provided.
   */
  @Input() buttonText: string = 'Hochladen';

  /**
   * Optional string to override the title.
   * Defaults to 'Datei(en) hochladen' if not provided.
   */
  @Input() titleText: string = 'Datei(en) hochladen';

  /**
   * Optional string to override the description.
   * Defaults to an empty string if not provided.
   */
  @Input() descriptionText: string = '';

  /**
   * Optional boolean to set whether the expansion panel is opened by default.
   * Defaults to true if not provided.
   */
  @Input() expanded: boolean = false;

  /**
   * Optional string to specify the subdirectory in the Firestore storage bucket where files will be uploaded.
   * Ensure that the access rules are configured accordingly.
   * Defaults to 'files' if not provided.
   */
  @Input() uploadPath: string = 'files';

  @Output() uploadCompleted: EventEmitter<NewsFile[]> = new EventEmitter<NewsFile[]>();

  /**
   * Array to hold the selected files for upload.
   */
  selectedFiles: File[] = [];

  uploadProgress: number[] = [];
  uploadInProgress: boolean = false;

  constructor(private fileUploadService: FileUploadService) { }

  /**
   * Event handler triggered when files are selected.
   * Updates the selectedFiles array with the chosen files.
   * 
   * @param event - The file input change event containing the selected files.
   */
  onFileSelected(event: any) {
    this.selectedFiles = [];
    this.uploadProgress = [];
    this.uploadInProgress = false;
    this.selectedFiles = Array.from(event.target.files);
  }

  /**
   * Cancels the upload process by clearing the selectedFiles array and collapsing the expansion panel.
   */
  cancel() {
    this.selectedFiles = [];
    this.uploadProgress = [];
    this.uploadInProgress = false;
    this.expanded = false;
  }

  /**
   * Removes a file from the selectedFiles array by its index.
   * 
   * @param index - The index of the file to be removed.
   */
  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.uploadProgress.splice(index, 1);
  }

  /**
   * Initiates the upload process for the selected files.
   * To be implemented: Add the logic to upload files to Firestore.
   */
  upload(): void {
    if (this.selectedFiles.length === 0) {
      console.log('No file selected');
      return;
    };

    this.uploadProgress = new Array(this.selectedFiles.length).fill(0);
    this.uploadInProgress = true;
    const files: NewsFile[] = [];

    this.selectedFiles.forEach((file, index) => {
      const path = `${this.uploadPath}/${new Date().getTime()}/${file.name}`;
      const uploadTask = this.fileUploadService.uploadFile(file, path);

      uploadTask.subscribe(
        progress => {
          this.uploadProgress[index] = progress ? progress : 0;
        },
        error => {
          console.error('Error uploading file:', error);
        },
        () => {
          this.fileUploadService.getDownloadURL(path).subscribe(
            url => {
              console.log('File available at:', url);

              const uploadFile: NewsFile = {
                name: file.name,
                downloadUrl: url
              }

              files.push(uploadFile);
              if (files.length === this.selectedFiles.length) {
                this.uploadInProgress = false;
                this.uploadCompleted.emit(files);
              }
            }
          );
        }
      );
    });
  }

}
