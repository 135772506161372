import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Team } from '../models/team.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private teamsCollection: AngularFirestoreCollection<Team>;

  constructor(
    private firestore: AngularFirestore
  ) {
    this.teamsCollection = firestore.collection<Team>('teams');
  }

  create(team: Team): Promise<void> {
    team.id = this.firestore.createId();
    return this.teamsCollection.doc(team.id).set(team);
  }

  update(team: Team): Promise<void> {
    return this.teamsCollection.doc(team.id!).set(team);
  }

  delete(team: Team): Promise<void> {
    return this.teamsCollection.doc(team.id).delete();
  }

  getTeamById(id: string): Observable<Team | any> {
    return this.teamsCollection.doc(id).valueChanges();
  }

  getAll(): Observable<Team[]> {
    return this.firestore.collection<Team>('teams', ref => ref
      .orderBy('name', 'asc')
    ).valueChanges();
  }

  getBySportId(sportId: string): Observable<Team[]> {
    return this.firestore.collection<Team>('teams', ref => ref
      .where('sportId', '==', sportId)).valueChanges();
  }

  getAllByUserId(userId: string): Observable<Team[]> {
    return this.firestore.collection<Team>('teams', ref => ref
      .where('memberIds', 'array-contains', userId)
    ).valueChanges();
  }
}
