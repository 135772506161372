import { SportSettingsComponent } from './components/sport/sport-settings/sport-settings.component';
import { SportComponent } from './components/sport/sport.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { NotAuthGuard } from './guards/not-auth.guard';
import { AdminComponent } from './components/admin/admin.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { SportsListComponent } from './components/sport/sports-list/sports-list.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateSportComponent } from './components/sport/sport-create/sport-create.component';
import { ExternalComponent } from './components/external/external.component';
import { SportPreviewComponent } from './components/sport/sport-preview/sport-preview.component';
import { TeamComponent } from './components/team/team.component';
import { ExtSportsListComponent } from './components/external/ext-sports-list/ext-sports-list.component';
import { ImpressComponent } from './components/footer/impress/impress.component';
import { DatasecurityComponent } from './components/footer/datasecurity/datasecurity.component';
import { ExtFaqComponent } from './components/external/ext-faq/ext-faq.component';
import { ExtNewsComponent } from './components/external/ext-news/ext-news.component';
import { ExtNewsPreviewComponent } from './components/external/ext-news/ext-news-preview/ext-news-preview.component';
import { TeamSettingsComponent } from './components/team/team-settings/team-settings.component';
import { EventComponent } from './components/event/event.component';
import { FilesComponent } from './components/files/files.component';
import { ExtClubComponent } from './components/external/ext-club/ext-club.component';
import { ExtContactComponent } from './components/external/ext-contact/ext-contact.component';
import { UserManagementComponent } from './components/admin/user-management/user-management.component';
import { FaqComponent } from './components/admin/faq/faq.component';
import { EditUserComponent } from './components/admin/user-management/edit-user/edit-user.component';
import { AdminDownloadsComponent } from './components/admin/admin-downloads/admin-downloads.component';
import { AdminGalleryComponent } from './components/admin/admin-gallery/admin-gallery.component';
import { AdminClubComponent } from './components/admin/admin-club/admin-club.component';
import { AdminContactComponent } from './components/admin/admin-contact/admin-contact.component';
import { AdminSettingsComponent } from './components/admin/admin-settings/admin-settings.component';
import { BoardComponent } from './components/footer/board/board.component';
import { AdminBoardComponent } from './components/admin/admin-board/admin-board.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { EventCreateComponent } from './components/event/event-create/event-create.component';

const routes: Routes = [
  { path: '', component: ExternalComponent, canActivate: [NotAuthGuard] },
  { path: 'anmelden', component: SignInComponent, canActivate: [NotAuthGuard] },
  { path: 'registrieren', component: SignUpComponent, canActivate: [NotAuthGuard] },
  { path: 'neuigkeiten', component: ExtNewsComponent, canActivate: [NotAuthGuard] },
  { path: 'neuigkeiten/:newsId', component: ExtNewsPreviewComponent },
  { path: 'downloads', component: FilesComponent },
  { path: 'kontakt', component: ExtContactComponent },
  { path: 'clubhaus', component: ExtClubComponent },
  { path: 'galerie', component: GalleryComponent },
  { path: 'faq', component: ExtFaqComponent },
  { path: 'vorstand', component: BoardComponent },
  { path: 'impressum', component: ImpressComponent },
  { path: 'datenschutz', component: DatasecurityComponent },
  { path: 'sportangebote', component: ExtSportsListComponent, canActivate: [NotAuthGuard] },
  { path: 'sportangebote/:sportId', component: SportPreviewComponent },
  { path: 'startseite', component: LandingPageComponent, canActivate: [AuthGuard] },
  { path: 'benutzer/:userId', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'einstellungen', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'sportarten', component: SportsListComponent, canActivate: [AuthGuard] },
  { path: 'sport/:sportId', component: SportComponent, canActivate: [AuthGuard] },
  { path: 'sport/:sportId/einstellungen', component: SportSettingsComponent },
  { path: 'mannschaft/:teamId', component: TeamComponent, canActivate: [AuthGuard] },
  { path: 'mannschaft/:teamId/einstellungen', component: TeamSettingsComponent, canActivate: [AuthGuard] },
  { path: 'mannschaft/:teamId/veranstaltungen', component: EventCreateComponent, canActivate: [AuthGuard] },
  { path: 'mannschaft/:teamId/veranstaltungen/:eventId', component: EventCreateComponent, canActivate: [AuthGuard] },
  { path: 'sport-erstellen', component: CreateSportComponent, canActivate: [AuthGuard] },
  { path: 'veranstaltungen', component: EventComponent, canActivate: [AuthGuard] },
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard, AdminGuard], children: [
      { path: '', redirectTo: 'benutzerverwaltung', pathMatch: 'full' },
      { path: 'benutzerverwaltung', component: UserManagementComponent },
      { path: 'benutzerverwaltung/:userId', component: EditUserComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'downloads', component: AdminDownloadsComponent },
      { path: 'galerie', component: AdminGalleryComponent },
      { path: 'clubhaus', component: AdminClubComponent },
      { path: 'kontakt', component: AdminContactComponent },
      { path: 'einstellungen', component: AdminSettingsComponent },
      { path: 'vorstand', component: AdminBoardComponent }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
