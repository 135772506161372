import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'tsv-team-memberships-preview',
  templateUrl: './team-memberships-preview.component.html',
  styleUrls: ['./team-memberships-preview.component.scss']
})
export class TeamMembershipsPreviewComponent implements OnInit {

  @Input() user: User;
  teams$: Observable<Team[]>;


  constructor(
    private teamService: TeamService
  ) { }

  ngOnInit(): void {
    this.teams$ = this.teamService.getAllByUserId(this.user.id!);
  }
}
