<div class="gallery-container">
  <div class="path" *ngIf="path.length > 0">
    <button mat-icon-button (click)="onHomeClick()">
      <mat-icon>home</mat-icon>
    </button>
    <button
      mat-button
      *ngFor="let folder of path"
      (click)="onPathClick(folder)"
      class="path-item"
    >
      <mat-icon>chevron_right</mat-icon>
      <span class="path-text">{{ folder }}</span>
    </button>
    <button
      mat-raised-button
      color="warn"
      class="delete-folder hide-sm"
      (click)="deleteFolder()"
    >
      <mat-icon>delete</mat-icon>
      Ordner & Inhalte löschen
    </button>
    <button
      color="warn"
      mat-icon-button
      (click)="addFolder()"
      class="delete-folder hide-lg"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <mat-divider *ngIf="path.length > 0"></mat-divider>

  <mat-accordion *ngIf="path.length == 0">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Bilder laden zu lange?
      </mat-expansion-panel-header>
      Dauert das Laden der Bilder in der Galerie zu lange liegt dies an der
      Größe der individuellen Dateien. Versuche die Größe der Bilder optimal
      unter 4MB zu halten. Sind deine Bilder zu groß kannst du Sie versuchen mit
      einem externen Komprimierungstool anzupassen.
      <a mat-button target="_blank" href="https://imagecompressor.com/de/">
        <mat-icon>open_in_new</mat-icon>
        Komprimierungstool
      </a>
    </mat-expansion-panel>
  </mat-accordion>

  <div>
    <h3 class="title">Galerie</h3>
  </div>

  <div class="folders-title">
    <h4>Ordner</h4>
    <button mat-raised-button (click)="addFolder()" class="hide-sm">
      <mat-icon>folder</mat-icon>
      Ordner hinzufügen
    </button>
    <button mat-icon-button (click)="addFolder()" class="hide-lg">
      <mat-icon>folder</mat-icon>
    </button>
  </div>

  <mat-list *ngIf="folders.length > 0" class="folders">
    <mat-list-item
      *ngFor="let folder of folders"
      (click)="onFolderClick(folder)"
      class="folder-item"
    >
      <mat-icon matListItemIcon>folder</mat-icon>
      <div matListItemTitle class="name">{{ folder }}</div>
    </mat-list-item>
  </mat-list>
  <mat-divider *ngIf="folders.length > 0"></mat-divider>

  <div class="gallery-lightbox-container">
    <div class="gallery">
      <div *ngFor="let image of images; let i = index" class="gallery-img">
        <img
          (click)="onPreviewImage(i)"
          [src]="image.url"
          [alt]="image.name"
          loading="lazy"
        />
      </div>
    </div>
    <div *ngIf="showMask" class="lightbox" @animation2>
      <span *ngIf="showCount" class="count"
        >{{ currentIndex + 1 }}/{{ totalImageCount }}</span
      >
      <button
        *ngIf="previewImage"
        class="close-btn"
        mat-mini-fab
        (click)="onClosePreview()"
        color="primary"
      >
        <mat-icon class="">close</mat-icon>
      </button>
      <button
        *ngIf="previewImage"
        class="delete-btn"
        mat-mini-fab
        (click)="delete()"
        color="primary"
      >
        <mat-icon class="">delete</mat-icon>
      </button>
      <button
        *ngIf="controls"
        class="btn-lightbox-carousel btn-prev"
        mat-mini-fab
        (click)="prev()"
        color="primary"
      >
        <mat-icon class="icon-prev icon-lightbox-carousel"
          >chevron_left</mat-icon
        >
      </button>
      <button
        *ngIf="controls"
        class="btn-lightbox-carousel btn-next"
        mat-mini-fab
        (click)="next()"
        color="primary"
      >
        <mat-icon class="icon-next icon-lightbox-carousel"
          >chevron_right</mat-icon
        >
      </button>
      <div
        *ngIf="previewImage"
        [@animation]="{ value: 'visible' }"
        (@animation.done)="onAnimationEnd($event)"
        class="lightbox-img"
      >
        <img
          [src]="currentLightboxImage.url"
          [alt]="currentLightboxImage.name"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</div>
<div class="hint">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Urheberrechtshinweis für Fotografien
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="hint-text">
        Alle auf dieser Website abgebildeten Fotos unterliegen dem Urheberrecht
        des TSV Travemünde oder wurden mit ausdrücklicher Erlaubnis anderer
        Rechteinhaber verwendet. Die Verwendung, Vervielfältigung oder
        Verbreitung der Bilder ohne eine vorherige schriftliche Genehmigung
        durch den TSV Travemünde oder den jeweiligen Rechteinhaber ist
        ausdrücklich untersagt.
        <br />
        Bitte beachten Sie, dass unbefugte Nutzungshandlungen straf- und
        zivilrechtlich verfolgt werden können.
        <br />
        Falls Sie Interesse an der Verwendung oder Verbreitung der Bilder haben,
        setzen Sie sich bitte vorab schriftlich mit uns in Verbindung, um die
        erforderlichen Genehmigungen einzuholen.
        <br /><br />
        Vielen Dank für Ihr Verständnis und Ihre Kooperation.
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<button mat-fab class="fab" (click)="input.click()">
  <mat-icon color="primary">add</mat-icon>
</button>

<input
  class="input-img"
  #input
  type="file"
  (change)="onFileSelected($event)"
  multiple
/>

<div *ngIf="loading" class="overlay">
  <mat-spinner></mat-spinner>
</div>
