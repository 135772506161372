<div class="sports-list-flexbox">

    <button mat-fab class="fab" color="accent" *ngIf="user?.roles?.includes(managerRole) || user?.roles?.includes(adminRole)"
        [routerLink]="'/sport-erstellen'">
        <mat-icon>add</mat-icon>
    </button>

    <div>
        <mat-form-field appearance="outline">
            <button matSuffix mat-icon-button>
                <mat-icon>search</mat-icon>
            </button>
            <input matInput placeholder="Suche" [(ngModel)]="searchValue" (keyup)="search()">
        </mat-form-field>
    </div>

    <div class="sports-list">
        <tsv-sport-card *ngFor="let sport of sports$ | async" [sport]="sport" [size]="'sm'"></tsv-sport-card>
    </div>
</div>