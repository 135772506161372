import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Board } from '../models/board.model';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  private collection: AngularFirestoreCollection<Board>;

  constructor(
    private firestore: AngularFirestore,
    private matSnackBar: MatSnackBar
  ) {
    this.collection = this.firestore.collection<Board>('board');
  }

  getAll(): Observable<Board[]> {
    return this.firestore.collection<Board>('board', ref => ref
      .orderBy('order', 'asc')
    ).valueChanges();
  }

  update(board: Board): Promise<any> {
    return this.collection.doc(board.id!).set(board)
      .then(() =>
        this.matSnackBar.open(
          'Vorstand wurde erfolgreich angepasst.',
          undefined,
          { duration: 2500 }))
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          undefined,
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  create(board: Board): Promise<void> {
    board.id = this.firestore.createId();
    return this.update(board);
  }

  delete(board: Board): Promise<any> {
    return this.collection.doc(board.id).delete()
      .then(() =>
        this.matSnackBar.open(
          'Vorstand wurde erfolgreich gelöscht.',
          undefined,
          { duration: 2500 }))
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          undefined,
          { duration: 5000 }
        );
        console.log(err);
      });
  }

}
