import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'tsv-team-join-dialog',
  templateUrl: './team-join-dialog.component.html',
  styleUrls: ['./team-join-dialog.component.scss']
})
export class TeamJoinDialogComponent implements OnInit {

  coaches$: Observable<User[]>;

  constructor(
    public dialogRef: MatDialogRef<TeamJoinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: Team,
    private userServive: UserService
  ) { }

  ngOnInit(): void {
    this.coaches$ = this.userServive.getUsersByIds(this.dialogData.coachIds);
  }

}
