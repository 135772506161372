<div class="events">

    <button mat-fab class="fab" (click)="openDialog()" *ngIf="user?.roles!.includes(reporterRole) || user?.roles?.includes(adminRole)">
        <mat-icon>event</mat-icon>
    </button>

    <tsv-event-card class="card" *ngFor="let event of events$ | async" [event]="event"></tsv-event-card>

    <mat-card *ngIf="(events$ | async)?.length! == 0" appearance="outlined">
        <mat-card-header>
            <mat-card-subtitle>Aktuell sind leider keine zukünftigen Veranstaltungen geplannt.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>

</div>