<div class="settings">
    <mat-card class="card">
        <mat-card-header>
            <mat-card-title>Einstellungen</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="form">
            </form>
        </mat-card-content>
    </mat-card>
</div>