import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  //TODO: clearly defined the usecase and the boudaries before continuing with this

  user: User;
  form: FormGroup = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    dateOfBirth: [],
    addressPostalCode: [''],
    addressStreet: [''],
    addressHouseNumber: ['']
  });

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => {
      this.user = user;
      this.initForm();
    });
  }

  initForm(): void {
    this.form.controls['firstName'].setValue(this.user.firstName);
    this.form.controls['lastName'].setValue(this.user.lastName);
    this.form.controls['email'].setValue(this.user.email);
    this.form.controls['dateOfBirth'].setValue(new Date(this.user.dateOfBirth!));
    this.form.controls['addressPostalCode'].setValue(this.user.address?.postalCode);
    this.form.controls['addressHouseStreet'].setValue(this.user.address?.street);
    this.form.controls['addressHouseNumber'].setValue(this.user.address?.houseNumber);
  }

  update(): void { }

}
