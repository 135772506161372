import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { merge, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { Roles } from '../models/roles.enum';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private usersCollection: AngularFirestoreCollection<User>;
  private reference = 'users';

  constructor(
    private firestore: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.usersCollection = firestore.collection<User>(this.reference);
  }

  createUser(user: User): Promise<any> {
    return this.usersCollection.doc(user.id).set(user, { merge: true });
  }

  getUserById(id: string): Observable<User | any> {
    return this.usersCollection.doc(id).valueChanges();
  }

  getAllAdmins(): Observable<User | any> {
    return this.firestore.collection<User>(this.reference,
      ref => ref.where('role', '==', 'admin'))
      .valueChanges();
  }

  getAllUsers(): Observable<User[]> {
    return this.firestore.collection<User>(this.reference, ref => ref
      .where('deleted', '==', false)
      .orderBy('lastName', 'asc')
    ).valueChanges();
  }

  getAllUsersByRole(role: Roles): Observable<User[]> {
    return this.firestore.collection<User>(this.reference, ref => ref
      .where('deleted', '==', false)
      .where('roles', 'array-contains', role)
    ).valueChanges();
  }

  updateUser(user: User): Promise<any> {
    return this.usersCollection.doc(user.id).update(user)
      .then(() => this.snackBar.open(user.firstName + ' wurde erfolgreich angepasst.', undefined, { duration: 3000 }));
  }

  getUsersByIds(ids: string[]): Observable<User[]> {
    return this.firestore.collection<User>(this.reference, ref => ref
      .where('id', 'in', ids)
    ).valueChanges();
  }

  deleteUser(user: User): Promise<void> {
    user.deleted = true;
    return this.updateUser(user);
  }

}
