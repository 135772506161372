<div class="table-header">
    <div>
        <button mat-icon-button mwlCalendarPreviousView [(viewDate)]="viewDate" [view]="view">
            <mat-icon>navigate_before</mat-icon>
        </button>
        <button mat-icon-button mwlCalendarToday [(viewDate)]="viewDate">
            <mat-icon>today</mat-icon>
        </button>
        <button mat-icon-button mwlCalendarNextView [(viewDate)]="viewDate" [view]="view">
            <mat-icon>navigate_next</mat-icon>
        </button>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>help</mat-icon>
    </button>
</div>
<mat-menu #menu="matMenu">
    <button mat-menu-item style="background-color: #FDF1BA;">Frei</button>
    <button mat-menu-item style="background-color: #D1E8FF;">Gebucht</button>
    <button mat-menu-item style="background-color: #FAE3E3;">Ausgebucht</button>
    <button mat-menu-item style="background-color: #c1c8c7;">Blockiert</button>
  </mat-menu>
<mwl-calendar-week-view [hourSegments]="hourSegments" [weekStartsOn]="weekStartsOn" [locale]="locale"
    (eventClicked)="openDialog($event)" [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [viewDate]="viewDate"
    [events]="events">
</mwl-calendar-week-view>