import { AuthService } from './../../services/auth.service';
import { NewsService } from 'src/app/services/news.service';
import { Component, Input, OnInit } from '@angular/core';
import { Sport } from 'src/app/models/sport.model';
import { User } from 'src/app/models/user.model';
import { News } from 'src/app/models/news.model';

@Component({
  selector: 'tsv-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  /**
   * AuthorId to either fetch all news on the main timeline 
   * or the news from an according sport.
   */
  @Input() authorId: string;

  /**
   * Current user.
   */
  user: User;

  /**
   * Array of news.
   */
  newsArray: News[];
  
  /**
   * Number which holds the current iteration of fetching more news,
   * is used to fetch the right amount of news items.
   */
  loadMore: number = 1;

  /**
   * Total current limit of the items which can be show.
   */
  currentLimit: number;

  /**
   * Default page limit.
   */
  pageLimit: number = 10;

  constructor(
    private newsService: NewsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => this.user = user);
    this.initNews();
  }

  initNews(): void {
    if (this.authorId) {
      this.newsService.getByAuthorId(this.authorId, this.pageLimit).subscribe(news => this.newsArray = news);
    } else {
      this.newsService.getAll(this.pageLimit).subscribe(news => this.newsArray = news);
    }
  }

  loadNews(): void {
    this.loadMore++;
    
    // increases the total limit by one more iteration of the default page limit
    this.currentLimit = this.loadMore * this.pageLimit;

    if (this.authorId) {
      this.newsService.getByAuthorId(this.authorId, this.currentLimit).subscribe(news => this.newsArray = news);
    } else {
      this.newsService.getAll(this.currentLimit).subscribe(news => this.newsArray = news);
    }
  }

}
