<div class="faq-container">

    <mat-expansion-panel *ngFor="let faq of faq$ | async" class="faq">
        <mat-expansion-panel-header class="header">
            <mat-panel-title>
                {{faq.question}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{faq.answer}}</p>
    </mat-expansion-panel>

</div>