import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorType } from 'src/app/models/authorType.enum';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'tsv-event-settings-dialog',
  templateUrl: './event-settings-dialog.component.html',
  styleUrls: ['./event-settings-dialog.component.scss']
})
export class EventSettingsDialogComponent implements OnInit {

  event: Event;
  teamAuthorType = AuthorType.TEAM;
  form: FormGroup = this.formBuilder.group({
    name: [''],
    day: [new Date()],
    description: [''],
    place: [''],
    startHour: ['08:00'],
    endHour: ['09:00'],
    limitedParticipants: [0],
  });

  constructor(
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private dialogRef: MatDialogRef<EventSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: Event,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  update(): void {
    const event: any = this.event;
    event.title = this.form.get('name')?.value!;
    event.description = this.form.get('description')?.value!;
    event.place = this.form.get('place')?.value!;
    event.day = this.form.get('day')?.value!.getTime();
    event.startHour = this.form.get('startHour')?.value!;
    event.endHour = this.form.get('endHour')?.value!;
    event.modified = new Date().getTime();
    event.limitedParticipants = this.form.get('limitedParticipants')?.value!;

    event.start = this.formatTime(new Date(event.day), event.startHour).getTime();
    event.end = this.formatTime(new Date(event.day), event.endHour).getTime();

    this.eventService.update(event).then(() => {
      this.matSnackBar.open(
        'Veranstaltung wurde erfolgreich bearbeitet.',
        'Ok!',
        { duration: 2000 }
      );
      this.dialogRef.close();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  initForm(): void {
    this.event = this.dialogData;
    this.form.controls['name'].setValue(this.event.title);
    this.form.controls['day'].setValue(new Date(this.event.day));
    this.form.controls['description'].setValue(this.event.description);
    this.form.controls['place'].setValue(this.event.place);
    this.form.controls['startHour'].setValue(this.event.startHour);
    this.form.controls['endHour'].setValue(this.event.endHour);
    this.form.controls['limitedParticipants'].setValue(this.event.limitedParticipants);
  }

  formatTime(day: Date, start: string): Date {
    // Clone the 'day' Date object to avoid modifying the original object
    const dayWithStartTime = new Date(day);

    // Parse the 'start' string into hours and minutes
    const [startHoursStr, startMinutesStr] = start.split(':');
    const startHours = parseInt(startHoursStr, 10);
    const startMinutes = parseInt(startMinutesStr, 10);

    // Set the hours and minutes of the 'dayWithStartTime'
    dayWithStartTime.setHours(startHours, startMinutes, 0, 0);

    return dayWithStartTime;
  }

}
