import { Component } from '@angular/core';

@Component({
  selector: 'tsv-impress',
  templateUrl: './impress.component.html',
  styleUrls: ['./impress.component.scss']
})
export class ImpressComponent {

}
