import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { News } from '../models/news.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private newsCollection: AngularFirestoreCollection<News>;
  private news = 'news';

  constructor(
    private firestore: AngularFirestore
  ) {
    this.newsCollection = firestore.collection<News>(this.news);
  }

  create(news: News): Promise<void> {
    console.log(news);
    return this.newsCollection.doc(news.id).set(news, {merge: true});
  }

  // TODO: additionally delete all images and attachments from a news
  delete(id: string): Promise<void> {
    return this.newsCollection.doc(id).delete();
  }

  update(news: News): Promise<void> {
    return this.newsCollection.doc(news.id!).set(news);
  }

  getAll(limit: number = 10): Observable<News[]> {
    return this.firestore.collection<News>(this.news, ref => ref
      .orderBy('created', 'desc')
      .limit(limit)
    ).valueChanges();
  }

  getById(id: string): Observable<News | any> {
    return this.firestore.collection<News>(this.news).doc(id).valueChanges();
  }

  getFeaturedNews(): Observable<News[]> {
    const today = new Date().getTime();
    return this.firestore.collection<News>(this.news, ref => ref
      .where('featuredUntil', '==', 0)
      .where('featured', '==', true)
      .orderBy('created', 'desc')
      .limit(5)
    ).valueChanges();
  }

  getFeaturedUntilNews(): Observable<News[]> {
    return this.firestore.collection<News>(this.news, ref => ref
      .where('featuredUntil', '>=', new Date().getTime())
      .orderBy('featuredUntil','desc')
      .limit(5)
    ).valueChanges();
  }

  getByAuthorId(authorId: string, limit: number = 10): Observable<News[]> {
    return this.firestore.collection<News>(this.news, ref => ref
      .where('authorId', '==', authorId)
      .orderBy('created', 'desc')
      .limit(limit)
    ).valueChanges();
  }
}
