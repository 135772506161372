import { User } from 'src/app/models/user.model';
import { AuthService } from './../../services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  selector: 'tsv-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  hidePassword = true;

  missingValue = 'Du musst einen Wert angeben.';
  dateOfBirthError = 'Du musst einen gültigen Wert im Format Tag.Monat.Jahr angeben.';
  stringError = 'Kein gültiger Wert, bitte verwende nur Buchstaben.';
  numberError = 'Verwende 5 Zahlen.'
  error = 'Kein gültiger Wert.';
  emailError = 'Keine gültige Email';
  passwordError = 'Verwende mindestens 8 Zeichen.';

  signUpForm = this.formBuiler.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    password: ['', [Validators.minLength(8)]],
    legalAcceptance: [false, [Validators.requiredTrue]]
  });

  userRole: Roles = Roles.USER;

  constructor(
    private formBuiler: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  signUp(): void {
    const password = this.signUpForm.get('password')!.value || '';
    const user: User = {
      activated: true,
      roles: [this.userRole],
      firstName: this.signUpForm.get('firstName')!.value || 'error',
      lastName: this.signUpForm.get('lastName')!.value || 'error',
      sportIds: [],
      email: this.signUpForm.get('email')!.value || 'error',
      created: new Date().getTime(),
      modified: new Date().getTime(),
      deleted: false,
      legalAcceptance: true
    };
    this.authService.signUp(user, password);
  }

}
