<h1 mat-dialog-title>{{dialogData.name}}</h1>
<div mat-dialog-content>
    <p>Du bist noch kein Mitglied von {{dialogData.name}}.</p>
    <p *ngIf="(coaches$ | async)?.length! > 0">Melde dich bei den Trainern damit diese dich hinzufügen können:</p>
    <ul>
        <li *ngFor="let coach of coaches$ | async">{{coach.firstName}} {{coach.lastName}}: {{coach.email}}</li>
    </ul>
</div>
<div mat-dialog-actions>
    <button mat-button matDialogClose>Ok</button>
</div>