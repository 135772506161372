import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { TeamJoinDialogComponent } from '../team-join-dialog/team-join-dialog.component';
import { Router } from '@angular/router';
import { Roles } from 'src/app/models/roles.enum';
import { Sport } from 'src/app/models/sport.model';
import { SportService } from 'src/app/services/sport.service';

@Component({
  selector: 'tsv-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent implements OnInit {

  @Input() team: Team;
  @Input() currentUser: User;

  adminRole: Roles = Roles.ADMIN;
  sport: Sport;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private sportService: SportService
  ) { }

  ngOnInit(): void {
    this.sportService.getSportById(this.team.sportId).subscribe(sport => this.sport = sport);
  }

  /**
   * 
   */
  openTeam(): void {
    if (this.team.memberIds.includes(this.currentUser.id!) || this.team.coachIds.includes(this.currentUser.id!) || this.currentUser.roles.includes(this.adminRole) || this.sport.trainerIds.includes(this.currentUser.id!)) {
      this.router.navigate(['/mannschaft/' + this.team.id]);
    } else {
      const dialogRef = this.dialog.open(TeamJoinDialogComponent, {
        data: this.team
      });
    }
  }

}
