<div class="sign-in-flexbox">

    <mat-card appearance="outlined" class="sign-in-card">
        <mat-card-header>
            <mat-card-title>
                Anmelden
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form class="sign-in-card-form" [formGroup]="signInForm">

                <!-- Email -->
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" formControlName="email" required>
                    <mat-error *ngIf="signInForm.get('email')?.hasError('required')">Gebe einen Wert
                        an.</mat-error>
                    <mat-error *ngIf="signInForm.get('email')?.hasError('email')">Gebe eine gültige Email
                        an.</mat-error>
                </mat-form-field>

                <!-- Password -->
                <mat-form-field appearance="outline">
                    <mat-label>Passwort</mat-label>
                    <mat-icon matPrefix>key</mat-icon>
                    <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" required (keydown.enter)="signIn()">
                    <mat-error *ngIf="signInForm.get('password')?.hasError('required')">Gebe einen Wert
                        an.</mat-error>
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>

                <button mat-raised-button color="primary" [disabled]="!signInForm.valid" (click)="signIn()">
                    Anmelden
                </button>
            </form>
            <mat-divider></mat-divider>
        </mat-card-content>
        <mat-card-footer class="sign-in-card-footer">
            <a routerLink="/registrieren">Noch keinen Zugang? Jetzt anfragen!</a>
            <a (click)="forgotPassword()">Passwort vergessen?</a>
        </mat-card-footer>
    </mat-card>



</div>