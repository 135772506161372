<div class="club">
  <!-- Pagetitle -->
  <h3>Clubhaus</h3>

  <!-- Form -->
  <form class="form" [formGroup]="form">
    <!-- Title -->
    <div class="formfield-with-hint">
      <mat-form-field appearance="outline" class="formfield">
        <mat-label>Überschrift</mat-label>
        <input matInput formControlName="title" />
      </mat-form-field>
      <button
        mat-icon-button
        matTooltip="Überschrift der aufgelisteten Informationen."
        matTooltipPosition="left"
      >
        <mat-icon>help</mat-icon>
      </button>
    </div>

    <!-- Description -->
    <div class="formfield-with-hint">
      <mat-form-field appearance="outline" class="formfield">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <button
        mat-icon-button
        matTooltip="Beschreibung."
        matTooltipPosition="left"
      >
        <mat-icon>help</mat-icon>
      </button>
    </div>

    <!-- Content -->
    <div class="formfield-with-hint">
      <mat-form-field appearance="outline" class="formfield">
        <mat-label>Inhalt</mat-label>
        <textarea
          matInput
          formControlName="content"
          style="white-space: pre-line"
        ></textarea>
      </mat-form-field>
      <button
        mat-icon-button
        matTooltip="Ausführliche Beschreibung und Zusatzinformationen."
        matTooltipPosition="left"
      >
        <mat-icon>help</mat-icon>
      </button>
    </div>

    <!-- Banner -->
    <div class="formfield-with-hint">
      <mat-form-field appearance="outline" class="formfield">
        <mat-label>Banner</mat-label>
        <input matInput formControlName="banner" />
      </mat-form-field>
      <button
        mat-icon-button
        matTooltip="Information des Banners, welcher im unteren Bildschirmrand beim Aufruf dieser Seite angezeigt wird. Bleibt dieser Text leer wird keiner angezeigt."
        matTooltipPosition="left"
      >
        <mat-icon>help</mat-icon>
      </button>
    </div>
  </form>

  <!-- Actions -->
  <div class="actions">
    <button mat-raised-button color="primary" (click)="update()">
      Speichern
    </button>
  </div>

  <mat-expansion-panel class="img-hint">
    <mat-expansion-panel-header>
      <mat-panel-title> Bilderverwaltung </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>
    <p>
      Alle Bilder welche in der Galerie direkt in dem Ordner "Clubhaus"
      hinterlegt werden, werden automatisch mit in den entsprechenden Swiper
      geladen.
      <br />
      Ansonsten kann ein Hinweis für die Nutzer hinterlegt werden, dass diese
      sich die weiteren Beispiele für Feiern in der Galerie ansehen.
    </p>
  </mat-expansion-panel>
</div>
