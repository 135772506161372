<div class="club" *ngIf="settingsClub">
  <swiper-container
    *ngIf="images"
    class="swiper"
    navigation="true"
    pagination="true"
    pagination-clickable="true"
    loop="true"
    autoplay="true"
  >
    <swiper-slide *ngFor="let image of images" class="slide">
      <img [src]="image.url" class="slide-img" />
      <div class="title-wrapper">
        <h1 class="title">{{ settingsClub.title }}</h1>
        <h4 class="subtitle">{{ settingsClub.description }}</h4>
      </div>
    </swiper-slide>
  </swiper-container>

  <mat-card class="card">
    <mat-card-content>
      <p class="card-content">
        {{ settingsClub.content }}
      </p>
    </mat-card-content>
  </mat-card>
</div>
