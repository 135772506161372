<div class="files">

    <mat-card class="file">
        <mat-card-header>
            <mat-card-title>Archiv</mat-card-title>
            <mat-card-subtitle>Hier findest du alle historischen Dateien und Fotos, welche sich über die Jahrzente bei unserem Verein angesammelt haben.</mat-card-subtitle>
        </mat-card-header>
    </mat-card>

    <a mat-raised-button *ngFor="let file of files" class="file" target="_blank" [href]="file.downloadUrl">
        <mat-icon>open_in_new</mat-icon>
        {{file.name}}
    </a>

</div>