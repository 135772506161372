import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'tsv-ext-news',
  templateUrl: './ext-news.component.html',
  styleUrls: ['./ext-news.component.scss']
})
export class ExtNewsComponent implements OnInit {

  //TODO: as this is used internally as well, rename this component and enable an overview of the sport creator if given

  news$: Observable<News[]>;

  constructor(
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.news$ = this.newsService.getAll();
  }

}
