<div class="flexbox" *ngIf="sport" >

    <mat-card class="card">

        <mat-card-header class="card-header">
            <div class="card-img-wrapper">
                <img class="card-img" [src]="sport.imageUrl" mat-card-avatar>
            </div>
            <div class="card-text">
                <mat-card-title class="card-title">{{sport.name}}</mat-card-title>
                <mat-card-subtitle
                    [ngClass]="more ? 'card-subtitle-more' : 'card-subtitle-less'">{{sport.description}}</mat-card-subtitle>
            </div>

            <div class="sport-card-options">
                <button mat-icon-button (click)="more = !more"
                    [matTooltip]="more ? 'Weniger anzeigen' : 'Mehr anzeigen'" matTooltipPosition="before">
                    <mat-icon>{{more ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <mat-divider class="divider" *ngIf="more"></mat-divider>

        <mat-card-content *ngIf="more" class="sport-contact">
            <div class="contact" *ngIf="sport.contact">
                <mat-list *ngIf="sport.contact.name">
                    <mat-list-item>
                        <mat-icon matListItemIcon class="contact-icon">person</mat-icon>
                        <div matListItemTitle>{{sport.contact.name}}</div>
                        <div matListItemLine>Kontakt</div>
                    </mat-list-item>
                </mat-list>

                <button mat-stroked-button (click)="call()">
                    <mat-icon>phone</mat-icon>
                    <span>Telefon</span>
                </button>

                <button mat-stroked-button (click)="email()">
                    <mat-icon>email</mat-icon>
                    <span>E-Mail</span>
                </button>
            </div>

            <div class="sport-information" *ngIf="sport.information">
                <h4>Informationen</h4>
                <p class="information" [innerHTML]="sport.information"></p>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="sport-news">
        <tsv-timeline [authorId]="sport.id!"></tsv-timeline>
    </div>

</div>