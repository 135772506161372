<mat-card *ngIf="events$ | async; let events" class="events-preview">
    <span class="title">Veranstaltungen</span>

    <mat-divider></mat-divider>

    <div class="events-list">
        <div *ngFor="let event of events; let last = last" class="event-preview-wrapper">
            <span class="event-title">{{event.title}}</span>
            <span class="event-info">
                <mat-icon>event</mat-icon>
                {{event.day | date: 'dd.MM.YYYY'}}
            </span>
            <span class="event-info">
                <mat-icon>schedule</mat-icon>
                {{event.start | date: 'HH:mm'}} - {{event.end | date: 'HH:mm'}} Uhr
            </span>
            <span class="event-info" *ngIf="event.place">
                <mat-icon>place</mat-icon>
                <span class="text">{{event.place}}</span>
            </span>
            <mat-divider *ngIf="!last"></mat-divider>
        </div>

        <mat-card-subtitle *ngIf="events.length == 0">Du hast noch keine bevorstehenden Events.</mat-card-subtitle>
    </div>
</mat-card>