import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'tsv-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  hidePassword = true;
  signInForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  signIn(): void {
    const email = this.signInForm.get('email')!.value!;
    const password = this.signInForm.get('password')!.value!;
    this.authService.signIn(email, password);
  }

  forgotPassword(): void {
    if (this.signInForm.get('email')?.value) {
      const email = this.signInForm.get('email')?.value!;
      this.authService.passwordReset(email).then(() => {
        this.snackBar.open(
          'Du wirst in kürze eine Email für das Zurücksetzen deines Passworts erhalten',
          'Ok',
          { duration: 5000 }
        )
      }
      );
    } else {
      this.snackBar.open(
        'Du musst deine Email angeben um dein Passwort zurückzusetzen.',
        'Ok',
        { duration: 5000 }
      )
    }
  }

}
