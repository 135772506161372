import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Board } from 'src/app/models/board.model';
import { BoardService } from 'src/app/services/board.service';

@Component({
  selector: 'tsv-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  board$: Observable<Board[]>;

  constructor(
    private boardService: BoardService
  ) { }

  ngOnInit(): void {
    this.board$ = this.boardService.getAll();
  }

}
