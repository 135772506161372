import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { EventCreateDialogComponent } from './event-create-dialog/event-create-dialog.component';
import { Observable, map } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { Event } from 'src/app/models/event.model';
import { Roles } from 'src/app/models/roles.enum';
import { AuthorType } from 'src/app/models/authorType.enum';

@Component({
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  user: User;
  events$: Observable<Event[]>;
  reporterRole: Roles = Roles.REPORTER;
  adminRole: Roles = Roles.ADMIN;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => this.user = user);
    this.events$ = this.eventService.getAllUpcoming().pipe(map(events => events.filter(event => event.authorType != AuthorType.TEAM)));
  }

  openDialog(): void {
    this.dialog.open(EventCreateDialogComponent, {
      data: {
        authorType: AuthorType.EVENT
      }
    });
  }
}
