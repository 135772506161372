<div class="flexbox">
    <mat-card class="card">
        <mat-card-header>
            <mat-card-title>Geschäftstelle</mat-card-title>
            <mat-card-subtitle class="subtitle">
                <mat-icon>place</mat-icon>
                {{contact.place}}
            </mat-card-subtitle>
            <mat-card-subtitle class="subtitle">
                <mat-icon>phone</mat-icon>
                {{contact.phone}}
            </mat-card-subtitle>
            <mat-card-subtitle class="subtitle" *ngIf="contact.mail">
                <mat-icon>mail</mat-icon>
                {{contact.mail}}
            </mat-card-subtitle>
        </mat-card-header>
        <img [src]="contact.imageUrl" mat-card-image>
        <mat-card-content>
            <p [innerHTML]="contact.description" style="white-space: pre-wrap;"></p>
            <p class="bold">Öffnungszeiten:</p>
            <p [innerHTML]="contact.hours" style="white-space: pre-wrap;"></p>
        </mat-card-content>
    </mat-card>
</div>