import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, concatMap, tap } from 'rxjs';
import { Roles } from 'src/app/models/roles.enum';
import { User } from 'src/app/models/user.model';
import { SportService } from 'src/app/services/sport.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'tsv-user-chooser',
  templateUrl: './user-chooser.component.html',
  styleUrls: ['./user-chooser.component.scss']
})
export class UserChooserComponent implements OnInit {

  /**
   * Optional title of the component.
   */
  @Input() title: string = 'Benutzer';

  /**
   * Optional input of a role which filters the response of all users.
   * Defaults to users.
   */
  @Input() role: Roles = Roles.USER;

  /**
   * Optional input, but the variable basically hold the slecected userIds.
   */
  @Input() selectedUsers: string[] = [];

  /**
   * Optional input, which filters the users based on the sport id.
   * This is concepted to pre-filter the selection of trainers.
   */
  @Input() sportId: string;

  /**
   * Outputs the selected user ids.
   */
  @Output() selectedUsersEvent: EventEmitter<string[]> = new EventEmitter<string[]>;

  /**
   * Array of users.
   */
  users: User[];

  /**
   * Array of filtered users, to not manipulate the original source.
   */
  filteredUsers: User[];

  /**
   * Seach value upon filtering the first- and lastname of the users.
   */
  searchValue: string = '';

  constructor(
    private userService: UserService,
    private sportService: SportService
  ) { }

  ngOnInit(): void {
    this.fetchData();
  }

  /**
   * Search function for users.
   */
  search() {

    // 1. Filter users according to the search value
    let filteredUsers: User[] = this.users.filter(user => {
      const searchTerm = this.searchValue.toLowerCase();
      return (
        user.firstName.toLowerCase().includes(searchTerm) ||
        user.lastName.toLowerCase().includes(searchTerm)
      );
    });

    // 2. Create a user array of the user ids array
    let selectedUsers: User[] = [];
    this.selectedUsers.forEach(userId => {
      const user = this.users.find(user => user.id == userId);
      if (user) {
        selectedUsers.push(user);
      }
    });

    // 3. Merge the filtered and the preselected users.
    // Transform the array to a set to cross out duplicates and back to an array.
    // Sort the array.
    this.filteredUsers = Array.from(new Set([...filteredUsers, ...selectedUsers]));
    this.sortUsers();
  }

  /**
   * Fetches the data.
   */
  private fetchData(): void {
    if (this.sportId) {
      this.sportService.getSportById(this.sportId).pipe(concatMap(sport => this.userService.getUsersByIds(sport.coachIds!))).subscribe(users => {
        this.users = users;
        this.sortUsers();
      });
    } else {
      this.userService.getAllUsersByRole(this.role).subscribe(users => {
        this.users = users;
        this.sortUsers();
      });
    }
  }

  /**
   * Sorts the array of users.
   */
  private sortUsers(): void {
    this.users.sort((a, b) => a.lastName < b.lastName ? -1 : 1);
    this.filteredUsers.sort((a, b) => a.lastName < b.lastName ? -1 : 1);
  }

}
