import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { concatMap } from 'rxjs';
import { Roles } from 'src/app/models/roles.enum';
import { Link, Sport } from 'src/app/models/sport.model';
import { SportService } from 'src/app/services/sport.service';
import { SportLinkDialogComponent } from '../sport-link-dialog/sport-link-dialog.component';
import { DeleteDialogComponent } from 'src/app/dialogs/delete-dialog/delete-dialog.component';

@Component({
  templateUrl: './sport-settings.component.html',
  styleUrls: ['./sport-settings.component.scss']
})
export class SportSettingsComponent implements OnInit {

  links: Link[] = [];
  sport: Sport;
  role: Roles = Roles.MANAGER;
  coachRole: Roles = Roles.COACH;
  sportForm = this.formBuilder.group({
    name: [''],
    description: [''],
    trainerIds: [['']],
    coachIds: [['']],
    imageURL: [''],
    contactName: [''],
    contactTelephone: [''],
    contactEmail: [''],
    information: ['']
  });

  constructor(
    private formBuilder: FormBuilder,
    private sportService: SportService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      concatMap(params => this.sportService.getSportById(params['sportId'])
      )
    ).subscribe(sport => {
      this.sport = sport;
      this.initSportForm();
    });
  }

  initSportForm(): void {
    this.syncTrainers(this.sport.trainerIds);
    this.syncDownloadURL(this.sport.imageUrl!);
    this.sportForm.controls['name'].setValue(this.sport.name);
    this.sportForm.controls['description'].setValue(this.sport.description!);
    this.sportForm.controls['contactName'].setValue(this.sport.contact!.name!);
    this.sportForm.controls['contactEmail'].setValue(this.sport.contact!.email!);
    this.sportForm.controls['contactTelephone'].setValue(this.sport.contact!.telephone!);
    this.sportForm.controls['information'].setValue(this.sport.information!);
    this.links = this.sport.links ? this.sport.links! : [];
  }

  /**
  * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
  * @param userIds string[]
  */
  syncTrainers(userIds: string[]): void {
    this.sportForm.controls['trainerIds'].setValue(userIds);
  }

  /**
  * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
  * @param userIds string[]
  */
  syncCoaches(userIds: string[]): void {
    this.sportForm.controls['coachIds'].setValue(userIds);
  }

  /**
  * Gets called on change of the emitted downloadURL of the image-picker and syncs the url to the create object.
  * @param url string
  */
  syncDownloadURL(url: string) {
    this.sportForm.controls['imageURL'].setValue(url);
  }

  updateSport(): void {
    const sport = this.sport;
    sport.name = this.sportForm.get('name')?.value!;
    sport.description = this.sportForm.get('description')?.value!;
    sport.trainerIds = this.sportForm.get('trainerIds')?.value!;
    sport.coachIds = this.sportForm.get('coachIds')?.value!;
    sport.modified = new Date().getTime();
    sport.imageUrl = this.sportForm.get('imageURL')?.value!;
    sport.information = this.sportForm.get('information')?.value!;
    sport.contact!.telephone = this.sportForm.get('contactTelephone')?.value!;
    sport.contact!.email = this.sportForm.get('contactEmail')?.value!;
    sport.contact!.name = this.sportForm.get('contactName')?.value!;
    sport.links = this.links;

    this.sportService.updateSport(sport).then(() => {
      this.matSnackBar.open('Sportart wurde erfolgreich aktualisiert.', 'Ok!', { duration: 2000 });
      this.router.navigate(['/sport/' + this.sport.id]);
    });
  }

  delete(): void {
    this.matDialog.open(DeleteDialogComponent, { data: { type: 'Sport' } }).afterClosed().subscribe(bool => {
      if (bool) {
        this.sportService.delete(this.sport.id!).then(() => {
          this.matSnackBar.open(
            'Sport wurde erfolgreich gelöscht.',
            'Ok!',
            { duration: 2000 }
          );
          this.router.navigate(['/']);
        })
          .catch(err => {
            this.matSnackBar.open(
              'Etwas ist schiefgelaufen.',
              'Hm...',
              { duration: 5000 }
            );
            console.log(err);
          });
      }
    });
  }

  cancel(): void {
    this.router.navigate(['/sport/' + this.sport.id]);
  }

  addLink(): void {
    const sportLinkDialogRef = this.matDialog.open(SportLinkDialogComponent, {});

    sportLinkDialogRef.afterClosed().subscribe((link: Link) => {
      console.log(link);
      if (link.name) {
        this.links.push(link);
      }
    });
  }

  rmLink(linkName: string): void {
    const indexToRemove = this.links.findIndex(link => link.name === linkName);

    if (indexToRemove !== -1) {
      this.links.splice(indexToRemove, 1);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.links, event.previousIndex, event.currentIndex);
  }

}
