import { FaqService } from './../../../services/faq.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FAQ } from 'src/app/models/faq.model';

@Component({
  selector: 'tsv-ext-faq',
  templateUrl: './ext-faq.component.html',
  styleUrls: ['./ext-faq.component.scss']
})
export class ExtFaqComponent implements OnInit {

  faq$: Observable<FAQ[]>;

  constructor(
    private faqService: FaqService
  ) { }

  ngOnInit(): void {
    this.faq$ = this.faqService.getAll();
  }

}
