import { trigger, transition, style, animate, AnimationEvent } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Image } from 'src/app/models/images.model';
import { User } from 'src/app/models/user.model';
import { GalleryService } from 'src/app/services/gallery.service';
import { AdminGalleryFoldersDialogComponent } from './admin-gallery-folders-dialog/admin-gallery-folders-dialog.component';

@Component({
  selector: 'tsv-admin-gallery',
  templateUrl: './admin-gallery.component.html',
  styleUrls: ['./admin-gallery.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => visible', [
        style({ transform: 'scale(0.5' }),
        animate('150ms', style({ transform: 'scale(1)' }))
      ]),
      transition('visible => void', [
        style({ transform: 'scale(1)' }),
        animate('150ms', style({ transform: 'scale(0.5)' }))
      ])
    ]),
    trigger('animation2', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0.8 }))
      ])
    ])
  ]
})
export class AdminGalleryComponent implements OnInit {

  folders: string[] = [];
  images: Image[] = [];

  previewImage = false;
  showCount = true;
  showMask = false;
  currentLightboxImage: Image = this.images[0];
  currentIndex: number = 0;
  controls = true;
  totalImageCount: number = 0;

  path: string[] = [];
  root: string = 'gallery/';

  user: User;

  prefix = '/';

  selectedFiles: File[] = [];
  loading: boolean = false;

  constructor(
    private galleryService: GalleryService,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.syncStorage();
  }

  syncStorage(): void {
    this.syncPrefix();
    this.folders = [];
    this.images = [];
    this.galleryService.get(this.path).then((res) => {
      res.prefixes.forEach((folderRef: any) => {
        this.folders.push(folderRef.name);
        this.folders.sort();
      });
      res.items.forEach((itemRef: any) => {
        itemRef.getMetadata().then((data: any) => {
          if (data.name != 'newFolder') {
            itemRef.getDownloadURL().then((url: any) => {
              const image: Image = {
                fullPath: data.fullPath,
                size: data.size,
                name: data.name,
                updated: data.updated,
                created: data.created ? data.created : '',
                url: url
              }
              this.images.push(image);
              this.images.sort((a, b) => a.name.localeCompare(b.name));
            });
          }
        });
      });
    });
  }

  onFolderClick(folder: string): void {
    this.path.push(folder);
    this.syncStorage();
  }

  onHomeClick(): void {
    this.path = [];
    this.syncStorage();
  }

  onPathClick(folder: string) {
    this.removePathsFromLast(folder);
    this.syncStorage();
  }

  removePathsFromLast(inputString: string): void {
    const index = this.path.indexOf(inputString);

    if (index !== -1) {
      // If the inputString is found in the array
      this.path.splice(index + 1);
    } else {
      // Handle the case when the inputString is not found in the array
      console.error(`${inputString} not found in paths array`);
    }
  }

  onPreviewImage(index: number): void {
    this.totalImageCount = this.images.length;
    this.showMask = true;
    this.previewImage = true;
    this.currentIndex = index;
    this.currentLightboxImage = this.images[index];
  }

  onAnimationEnd(event: AnimationEvent): void {
    if (event.toState === 'void') {
      this.showMask = false;
    }
  }

  onClosePreview(): void {
    this.previewImage = false;
  }

  prev(): void {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.images.length - 1;
    }
    this.currentLightboxImage = this.images[this.currentIndex];
  }

  next(): void {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex > this.images.length - 1) {
      this.currentIndex = 0;
    }
    this.currentLightboxImage = this.images[this.currentIndex];
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // Call your method here
      this.onClosePreview();
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    // Define the list of class names to exclude
    const excludedClasses = ['btn-lightbox-carousel', 'count', 'close-btn', 'gallery', 'lightbox-img']; // Add any classes you want to exclude

    // Check if the clicked element or any of its ancestors have the excluded classes
    if (!this.isElementWithinClasses(event.target, excludedClasses)) {
      // Call your method to close the dialog
      this.onClosePreview();
    }
  }

  isElementWithinClasses(element: any, classes: string[]): boolean {
    // Check if the clicked element or any of its ancestors have the excluded classes
    while (element) {
      if (element.classList && classes.some(className => element.classList.contains(className))) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  }

  delete(): void {
    const path = this.images[this.currentIndex].fullPath;
    this.galleryService.delete(path).subscribe(() => {
      this.syncStorage();
      this.matSnackBar.open('Löschen erfolgreich.', undefined, { duration: 2500 })
    });
  }

  addFolder(): void {
    const dialog = this.matDialog.open(AdminGalleryFoldersDialogComponent, {
      data: { prefix: this.prefix }
    });
    dialog.afterClosed().subscribe(newFolderName => {
      if (newFolderName) {
        let path = this.root;
        this.path.forEach(folder => {
          path = path + '/' + folder;
        });
        path = path + '/' + newFolderName;
        this.galleryService.add(path).then(() => this.syncStorage());
      } else {
        this.matSnackBar.open('Gebe einen Ordnernamen an', undefined, { duration: 2500 });
      }
    });
  }

  deleteFolder(): void {
    let path = 'gallery';
    this.path.forEach(folder => {
      path = path + '/' + folder;
    });
    path = path + '/';
    this.galleryService.deleteAll(path).then(() => {
      this.path = [];
      setTimeout(() => { this.syncStorage(); }, 1000);
    });
  }

  onFileSelected(event: any): void {
    // Reset the selected files array
    this.selectedFiles = [];

    // Get the FileList from the event
    const fileList: FileList | null = event.target.files;

    if (fileList) {
      // Convert the FileList to an array
      this.selectedFiles = Array.from(fileList);
      this.uploadFiles();
    }
  }

  uploadFiles(): void {
    if (this.selectedFiles.length > 0) {
      this.loading = true;
      this.galleryService.uploadFiles(this.selectedFiles, this.path)
        .then(downloadUrls => {
          this.loading = false;
          this.matSnackBar.open('Upload erfolgreich', undefined, { duration: 2500 })
          this.syncStorage();
          this.selectedFiles = [];
        })
        .catch(error => {
          console.error('File upload error:', error);
          this.selectedFiles = [];
        });
    }
  }

  syncPrefix(): void {
    let path = '';
    this.path.forEach(folder => {
      path = path + '/' + folder;
    });
    this.prefix = path + '/';
  }
}
