<div class="team-settings">
    <mat-card *ngIf="team">
        <mat-card-header>
            <mat-card-title>Team Bearbeiten</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="form" class="form">

                <div class="formfield-with-hint">
                    <mat-form-field appearance="outline" class="formfield">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>
                    <button mat-icon-button matTooltip="Der Name des Teams." matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <!-- User chooser for coaches-->
                <div class="formfield-with-hint">
                    <tsv-user-chooser [title]="'Trainer'" [role]="coachRole" class="formfield" [sportId]="team.sportId"
                        [selectedUsers]="team.coachIds" (selectedUsersEvent)="syncCoaches($event)"></tsv-user-chooser>
                    <button mat-icon-button matTooltip="Trainer verwalten das Team und deren Mitglieder."
                        matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

                <!-- User chooser for members -->
                <div class="formfield-with-hint">
                    <tsv-user-chooser [title]="'Mitglieder'" [role]="userRole" class="formfield"
                        [selectedUsers]="team.memberIds" (selectedUsersEvent)="syncMembers($event)"></tsv-user-chooser>
                    <button mat-icon-button
                        matTooltip="Mitglieder können auf das Team zugreifen und mit diesem interagieren."
                        matTooltipPosition="left">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>

            </form>
        </mat-card-content>
        <mat-card-actions class="actions">
            <button mat-button (click)="cancel()">Abbrechen</button>
            <button mat-raised-button color="warn" (click)="delete()">Löschen</button>
            <button mat-raised-button color="primary" (click)="update()">Speichern</button>
        </mat-card-actions>
    </mat-card>
</div>