<h1 mat-dialog-title>Neuigkeit erstellen</h1>
<div mat-dialog-content>

    <form [formGroup]="form" class="form">

        <mat-card *ngIf="dialogData" appearance="outlined" class="form-author">
            <mat-card-header class="form-author-header">
                <img [src]="dialogData.imageUrl" mat-card-avatar class="form-author-img">
                <div class="form-author-text">
                    <mat-card-title class="form-author-title">{{dialogData.name}}</mat-card-title>
                    <mat-card-subtitle class="form-author-subtitle">{{dialogData.description}}</mat-card-subtitle>
                </div>
            </mat-card-header>
        </mat-card>

        <div class="form-img-picker">
            <h3>Vorschaubild</h3>
            <tsv-image-picker (downloadURLEvent)="syncDownloadURL($event)">
            </tsv-image-picker>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Schlagzeile</mat-label>
                <input matInput formControlName="headline" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Die Schlagzeile der Neuigkeit." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Unterüberschrift</mat-label>
                <input matInput formControlName="subheadline">
            </mat-form-field>
            <button mat-icon-button matTooltip="Die Unterüberschrift der Neuigkeit." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <tsv-img-upload [newsId]="newsId" (imagesUploadedEvent)="imagesUploadedEvent($event)"></tsv-img-upload>

        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor [editor]="editor" formControlName="html" outputFormat="html"
                [placeholder]="'Hier schreiben...'"></ngx-editor>
        </div>

        <div class="formfield-toggle-hint" *ngIf="imageUrl">
            <p>Featured</p>
            <mat-checkbox formControlName="featured">
            </mat-checkbox>
            <button mat-icon-button matTooltip="Gibt an ob diese Neuigkeit auf der Homepage im Swiper angezeigt wird."
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-toggle-hint" *ngIf="imageUrl && !dialogData">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Featured Bis:</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="featuredUntil">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
            <button mat-icon-button matTooltip="Legt fest bis wann die Neuigkeit im Swiper angezeigt wird."
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <tsv-file-upload titleText="Anhang" [uploadPath]="'news/' + newsId + '/files'" (uploadCompleted)="onUploadCompleted($event)"></tsv-file-upload>
        <mat-chip-listbox class="attachments">
            <a mat-chip *ngFor="let file of files" target="_blank" [href]="file.downloadUrl">
                <mat-icon matChipAvatar>download</mat-icon>
                {{file.name}}
            </a>
        </mat-chip-listbox>

    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="primary" (click)="createNews()" [disabled]="form.invalid">Erstellen</button>
</div>