import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private collection: AngularFirestoreCollection<any>;
  private path = 'settings';

  constructor(
    private firestore: AngularFirestore,
    private matSnackBar: MatSnackBar
  ) {
    this.collection = this.firestore.collection<any>(this.path);
  }

  /**
   * Gets the setting by the according name key.
   * @param name: string
   * @returns the according setting.
   */
  get(name: string): Observable<any> {
    return this.firestore
      .collection(this.path, (ref) => ref.where('name', '==', name))
      .valueChanges();
  }

  /**
   * Updates the setting.
   * @param document
   * @returns empty promise.
   */
  update(document: any): Promise<void> {
    return this.collection.doc(document.id!).set(document, {merge: true});
  }
}
