<div class="settings">
    <h3>Einstellungen</h3>

    <form class="form" [formGroup]="form">

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Globale Mitteilungen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-divider></mat-divider>
            <div class="settings-list">
                <div class="item">
                    <p>Globalen Banner anzeigen?</p>
                    <mat-checkbox color="primary" formControlName="banner"></mat-checkbox>
                </div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Banner Nachricht</mat-label>
                    <input matInput formControlName="bannerMessage">
                </mat-form-field>
                <mat-divider></mat-divider>
                <div class="item">
                    <p>🚧 Globale Mitteilung anzeigen? (Still in progress) 🚧</p>
                    <mat-checkbox color="primary" formControlName="messageBool"></mat-checkbox>
                </div>
                <mat-form-field appearance="outline" class="form-field"> 
                    <mat-label>Mitteilung</mat-label>
                    <input matInput formControlName="message">
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Standard Einstellungen für Sportarten
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-divider></mat-divider>
            <div class="settings-list">
                <div class="item">
                    <p>Weitere Informationen standardmäßig im internen Bereich anzeigen?</p>
                    <mat-checkbox color="primary" formControlName="sportInformationDefault"></mat-checkbox>
                </div>
            </div>
        </mat-expansion-panel>

    </form>

    <div class="actions">
        <button mat-raised-button color="primary" (click)="update()">
            Speichern
        </button>
    </div>

</div>