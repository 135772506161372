<a (click)="openTeam()">
    <mat-card class="teams-card">
        <mat-card-header class="teams-card-header">
            <div class="teams-card-text">
                <mat-card-title class="teams-card-title">{{team.name}}</mat-card-title>
                <mat-card-subtitle>
                    {{team.memberIds.length > 1 ? team.memberIds.length + ' Mitglieder' : team.memberIds.length + ' Mitglied'}}
                </mat-card-subtitle>
            </div>
        </mat-card-header>
    </mat-card>
</a>