<div class="sign-up-flexbox">

    <mat-card appearance="outlined" class="sign-up-card">
        <mat-card-header>
            <mat-card-title>
                Registrieren
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="sign-up-card-content">
            <form class="sign-up-card-form" [formGroup]="signUpForm">

                <!-- Firstname -->
                <mat-form-field appearance="outline">
                    <mat-label>Vorname</mat-label>
                    <input matInput #firstName formControlName="firstName" required>
                    <mat-error *ngIf="signUpForm.get('firstName')?.hasError('required')">{{missingValue}}</mat-error>
                    <mat-error *ngIf="signUpForm.get('firstName')?.hasError('pattern')">{{stringError}}</mat-error>
                </mat-form-field>

                <!-- Lastname -->
                <mat-form-field appearance="outline">
                    <mat-label>Nachname</mat-label>
                    <input matInput #lastName formControlName="lastName" required>
                    <mat-error *ngIf="signUpForm.get('lastName')?.hasError('required')">{{missingValue}}</mat-error>
                    <mat-error *ngIf="signUpForm.get('lastName')?.hasError('pattern')">{{stringError}}</mat-error>
                </mat-form-field>

                <!-- Email -->
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput #email formControlName="email" email required>
                    <mat-error *ngIf="signUpForm.get('email')?.hasError('required')">{{missingValue}}</mat-error>
                    <mat-error *ngIf="signUpForm.get('email')?.hasError('email')">{{emailError}}</mat-error>
                </mat-form-field>

                <!-- Password -->
                <mat-form-field appearance="outline">
                    <mat-label>Passwort</mat-label>
                    <mat-icon matPrefix>key</mat-icon>
                    <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" required>
                    <mat-error *ngIf="signUpForm.get('password')?.hasError('required')">{{missingValue}}</mat-error>
                    <mat-error *ngIf="signUpForm.get('password')?.hasError('minlength')">{{passwordError}}</mat-error>
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>

                <!-- Legal acceptance -->
                <mat-checkbox color="primary" formControlName="legalAcceptance">
                    Ich habe die Datenverarbeitung <a routerLink="/datenschutz">(hier klicken)</a> gelesen und
                    stimme dieser zu.
                </mat-checkbox>

                <button mat-raised-button [disabled]="!signUpForm.valid" (click)="signUp()" color="primary"
                    class="sign-up-card-btn">
                    <span>Registrieren</span>
                </button>
            </form>
        </mat-card-content>
    </mat-card>

</div>