import { AuthService } from '../../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SportService } from '../../../services/sport.service';
import { Link, Sport } from '../../../models/sport.model';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { Roles } from 'src/app/models/roles.enum';
import { MatDialog } from '@angular/material/dialog';
import { SportLinkDialogComponent } from '../sport-link-dialog/sport-link-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  templateUrl: './sport-create.component.html',
  styleUrls: ['./sport-create.component.scss']
})
export class CreateSportComponent implements OnInit {

  links: Link[] = [];
  role = Roles.MANAGER;
  coachRole: Roles = Roles.COACH;
  user: User;
  // Create sport form
  form = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
    trainerIds: [[''], Validators.required],
    coachIds: [['']],
    information: [''],
    contactName: [''],
    contactTelephone: [''],
    contactEmail: ['']
  });
  // Default imageURL when none is synced by the image picker
  imageUrl: string = 'https://firebasestorage.googleapis.com/v0/b/dev-tsv-travemuende.appspot.com/o/default%2Fdefault-sports-image.png?alt=media&token=f1baf33f-3c3b-43f2-b491-a1109b8d5770';

  constructor(
    private formBuilder: FormBuilder,
    private sportService: SportService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => {
      this.user = user;
    });
  }

  createSport(): void {
    // Create an instance from the formBuilder
    const sport: Sport = {
      name: this.form.get('name')?.value!,
      description: this.form.get('description')?.value!,
      trainerIds: this.form.get('trainerIds')?.value!,
      coachIds: this.form.get('coachIds')?.value!,
      created: new Date().getTime(),
      modified: new Date().getTime(),
      deleted: false,
      creatorId: this.user.id!,
      imageUrl: this.imageUrl,
      information: this.form.get('information')?.value!,
      contact: {
        name: this.form.get('contactName')?.value!,
        email: this.form.get('contactEmail')?.value!,
        telephone: this.form.get('contactTelephone')?.value!,
      },
      links: this.links
    };

    this.sportService.createSport(sport)
      .then(() => {
        this.matSnackBar.open(
          'Sportart wurde erfolgreich erstellt.',
          'Ok!',
          { duration: 2000 }
        );
        this.router.navigate(['/sportarten']);
      })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  cancel(): void {
    this.router.navigate(['/sportarten']);
  }

  /**
   * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
   * @param userIds string[]
   */
  syncTrainers(userIds: string[]) {
    this.form.controls['trainerIds'].setValue(userIds);
  }

  /**
   * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
   * @param userIds string[]
   */
  syncCoaches(userIds: string[]) {
    this.form.controls['coachIds'].setValue(userIds);
  }

  /**
   * Gets called on change of the emitted downloadURL of the image-picker and syncs the url to the create object.
   * @param url string
   */
  syncDownloadURL(url: string) {
    this.imageUrl = url;
  }

  addLink(): void {
    const sportLinkDialogRef = this.matDialog.open(SportLinkDialogComponent, {});

    sportLinkDialogRef.afterClosed().subscribe((link: Link) => {
      if (link.name) {
        this.links.push(link);
      }
    });
  }

  rmLink(linkName: string): void {
    const indexToRemove = this.links.findIndex(link => link.name === linkName);

    if (indexToRemove !== -1) {
      this.links.splice(indexToRemove, 1);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.links, event.previousIndex, event.currentIndex);
  }

}
