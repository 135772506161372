<h1 mat-dialog-title>Veranstaltung erstellen</h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="form">

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Titel</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Der Titel der Veranstaltung." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Beschreibung</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
            <button mat-icon-button matTooltip="Eine Beschreibung der Veranstaltung." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Datum</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="day">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
            <button mat-icon-button matTooltip="Der Inhalt der Neuigkeit." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Start</mat-label>
                <input matInput formControlName="startHour" [ngxMatTimepicker]="timepickerStart" [format]="24" readonly />
                <mat-icon matSuffix (click)="timepickerStart.open()">
                    watch_later
                </mat-icon>
            </mat-form-field>
            <ngx-mat-timepicker #timepickerStart></ngx-mat-timepicker>
            <button mat-icon-button matTooltip="Der Start der Veranstaltung." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Ende</mat-label>
                <input matInput formControlName="endHour" [ngxMatTimepicker]="timepickerEnd" [format]="24" readonly />
                <mat-icon matSuffix (click)="timepickerEnd.open()">
                    watch_later
                </mat-icon>
            </mat-form-field>
            <ngx-mat-timepicker #timepickerEnd></ngx-mat-timepicker>
            <button mat-icon-button matTooltip="Das Ende der Veranstaltung." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Ort</mat-label>
                <input matInput formControlName="place">
            </mat-form-field>
            <button mat-icon-button matTooltip="Der Ort der Veranstaltung." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint" *ngIf="event.authorType == teamAuthorType">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Begrenzte Teilnahme</mat-label>
                <input matInput type="number" min="0" max="99" formControlName="limitedParticipants" oninput="validity.valid||(value='');">
            </mat-form-field>
            <button mat-icon-button matTooltip="Eine Begrnezung der Teilnehmer zwischen 1-99 Teilnehmer. 0 resultiert in keiner Begrenzung." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="primary" (click)="update()" [disabled]="form.invalid">Speichern</button>
</div>