import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {

    public override dayViewHour({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'HH', locale!);
    }

    public override weekViewHour({ date, locale }: DateFormatterParams): string {
        return this.dayViewHour({ date, locale });
    }

    public override weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'dd.M', locale!);
    }

    public override weekViewColumnSubHeader({ date, locale, }: DateFormatterParams): string { 
        return formatDate(date, 'EE', locale!);
    }
}