import { BaseModel } from "./basemodel.model";

export interface CustomFile extends BaseModel {
    name: string;
    downloadUrl: string;
    path: string;
    type?: FileType;
    size: number;
}

export enum FileType {
    DOWNLAOD = 'Download',
    ARCHIVE = 'Archiv'
}