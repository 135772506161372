<div class="wrapper">
    <button mat-stroked-button (click)="compressAndUpload()">Bilder auswählen</button>
    <button mat-icon-button [matTooltip]="tooltip" (click)="showTooltip()" matTooltipPosition="left">
        <mat-icon>help</mat-icon>
    </button>
</div>

<div *ngFor="let image of images" class="uploads">

    <img [src]="image.url" class="image">
    <div class="options">
        <div class="buttons" *ngIf="image.progress == 100">
            <span class="all-spacer"></span>
            <button mat-icon-button (click)="copyUrl(image)" matTooltip="Url kopieren." matTooltipPosition="left">
                <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button (click)="delete(image)" matTooltip="Bild löschen." matTooltipPosition="left">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <mat-progress-bar mode="determinate" [value]="image.progress" class="progress-bar"></mat-progress-bar>
    </div>
</div>