<div class="landing-page" *ngIf="user">

    <button mat-fab class="fab" *ngIf="user.roles.includes(reporterRole) || user.roles.includes(adminRole)" (click)="openDialog()">
        <mat-icon>article</mat-icon>
    </button>

    <!-- Tab group for mobile layout -->
    <mat-tab-group [selectedIndex]="1" class="show-md">
        <mat-tab label="Teams">
            <div class="mobile-tab">
                <tsv-team-memberships-preview class="membership-mobile" [user]="user"></tsv-team-memberships-preview>
            </div>
        </mat-tab>
        <mat-tab label="News">
            <tsv-timeline class="timeline-mobile"></tsv-timeline>
        </mat-tab>
        <mat-tab label="Events">
            <div class="mobile-tab">
                <tsv-events-preview [user]="user"></tsv-events-preview>
            </div>
        </mat-tab>
    </mat-tab-group>

    <!-- Desktop layout -->
    <div class="landing-page-desktop hide-md">
        <tsv-team-memberships-preview class="membership-desktop" [user]="user"></tsv-team-memberships-preview>
        <tsv-timeline class="timeline-desktop"></tsv-timeline>
        <tsv-events-preview class="events-desktop" [user]="user"></tsv-events-preview>
    </div>
</div>