import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { of, Observable, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;

  constructor(
    private fireAuth: AngularFireAuth,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.user$ = this.fireAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.userService.getUserById(user.uid);
        } else {
          return of(null);
        }
      })
    );
  }

  signIn(email: string, password: string): any {
    return this.fireAuth.signInWithEmailAndPassword(email, password)
      .then(() => this.router.navigate(['/startseite']))
      .catch(err => this.openSnackBar(err));
  }

  signUp(user: User, password: string): Promise<void> {
    return this.fireAuth.createUserWithEmailAndPassword(user.email, password)
      .then(() => {
        this.fireAuth.authState.subscribe(res => {
          user.id = res?.uid;
          this.userService.createUser(user).then(() => this.router.navigate(['']));
        })
      })
      .catch(err => this.openSnackBar(err));
  }

  signOut(): any {
    return this.fireAuth.signOut()
      .then(() => this.router.navigate(['anmelden']))
      .catch(err => this.openSnackBar(err));
  }

  passwordReset(email: string): Promise<void> {
    return this.fireAuth.sendPasswordResetEmail(email);
  }

  private openSnackBar(message: string, description = ""): void {
    const duration = 2500;
    this.snackBar.open(message, description, { duration });
  }

}
