import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Image } from 'src/app/models/images.model';
import { SettingsClub } from 'src/app/models/settingsClub.model';
import { GalleryService } from 'src/app/services/gallery.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'tsv-ext-club',
  templateUrl: './ext-club.component.html',
  styleUrls: ['./ext-club.component.scss'],
})
export class ExtClubComponent implements OnInit {
  settingsClub: SettingsClub;
  path: string[] = ['Clubhaus'];
  prefix = '/';
  folders: string[] = [];
  images: Image[] = [];

  constructor(
    private settingsService: SettingsService,
    private matSnackBar: MatSnackBar,
    private galleryService: GalleryService
  ) {}

  ngOnInit(): void {
    this.settingsService.get('clubhouse').subscribe((clubs) => {
      const club: SettingsClub = clubs[0];
      this.settingsClub = club;
      if (club.banner) {
        this.matSnackBar.open(club.banner, 'Ok', { duration: 5000 });
      }
    });
    this.syncStorage();
  }

  syncStorage(): void {
    this.folders = [];
    this.images = [];
    this.galleryService.get(this.path).then((res) => {
      res.prefixes.forEach((folderRef: any) => {
        this.folders.push(folderRef.name);
        this.folders.sort();
      });
      res.items.forEach((itemRef: any) => {
        itemRef.getMetadata().then((data: any) => {
          if (data.name != 'newFolder') {
            itemRef.getDownloadURL().then((url: any) => {
              const image: Image = {
                fullPath: data.fullPath,
                size: data.size,
                name: data.name,
                updated: data.updated,
                created: data.created ? data.created : '',
                url: url,
              };
              this.images.push(image);
              this.images.sort((a, b) => a.name.localeCompare(b.name));
            });
          }
        });
      });
    });
  }

  syncPrefix(): void {
    let path = '';
    this.path.forEach((folder) => {
      path = path + '/' + folder;
    });
    this.prefix = path + '/';
  }
}
