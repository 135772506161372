import { Component, OnInit } from '@angular/core';
import { SettingsContact } from 'src/app/models/settingsContact.model';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'tsv-ext-contact',
  templateUrl: './ext-contact.component.html',
  styleUrls: ['./ext-contact.component.scss']
})
export class ExtContactComponent implements OnInit {

  contact: SettingsContact;

  constructor(
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.settingsService.get('contact').subscribe((contacts: SettingsContact[]) => {
      this.contact = contacts[0];
    });
  }

}
