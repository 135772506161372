import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Board } from 'src/app/models/board.model';
import { BoardService } from 'src/app/services/board.service';

@Component({
  selector: 'tsv-board-create',
  templateUrl: './board-create.component.html',
  styleUrls: ['./board-create.component.scss']
})
export class BoardCreateComponent implements OnInit {

  form = this.formBuilder.group({
    title: [''],
    name: [''],
    order: [0]
  });
  imageUrl: string = 'https://firebasestorage.googleapis.com/v0/b/dev-tsv-travemuende.appspot.com/o/default%2Fdefault-sports-image.png?alt=media&token=f1baf33f-3c3b-43f2-b491-a1109b8d5770';

  constructor(
    private boardService: BoardService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public board: Board,
    private dialogRef: MatDialogRef<BoardCreateComponent>
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  create(): void {
    const board: Board = {
      created: new Date().getTime(),
      modified: new Date().getTime(),
      title: this.form.get('title')?.value!,
      name: this.form.get('name')?.value!,
      order: this.form.get('order')?.value!,
      imageUrl: this.imageUrl
    };
    this.boardService.create(board).then(() => this.dialogRef.close());
  }

  update(): void {
    const board: Board = this.board;
    board.title = this.form.get('title')?.value!;
    board.name = this.form.get('name')?.value!;
    board.modified = new Date().getTime();
    board.order = this.form.get('order')?.value!;
    board.imageUrl = this.imageUrl;
    this.boardService.update(board).then(() => this.dialogRef.close());
  }

  delete(): void {
    this.boardService.delete(this.board).then(() => this.dialogRef.close());
  }

  initForm(): void {
    if (this.board?.id) {
      this.form.controls.name.setValue(this.board.name);
      this.form.controls.title.setValue(this.board.title);
      this.form.controls.order.setValue(this.board.order);
      this.imageUrl = this.board.imageUrl;
    }
  }

  /**
   * Gets called on change of the emitted downloadURL of the image-picker and syncs the url to the create object.
   * @param url string
   */
  syncDownloadURL(url: string) {
    this.imageUrl = url;
  }

}
