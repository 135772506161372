import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Roles } from 'src/app/models/roles.enum';
import { Sport } from 'src/app/models/sport.model';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'tsv-team-create-dialog',
  templateUrl: './team-create-dialog.component.html',
  styleUrls: ['./team-create-dialog.component.scss']
})
export class TeamCreateDialogComponent implements OnInit {

  user: User;
  sport: Sport;
  coachRole: Roles = Roles.COACH;
  userRole: Roles = Roles.USER;
  form = this.formBuilder.group({
    name: ['', Validators.required],
    coachIds: [['']],
    memberIds: [['']]
  });

  constructor(
    private teamService: TeamService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<TeamCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { user: User, sport: Sport }
  ) { }

  ngOnInit(): void {
    this.sport = this.dialogData.sport;
    this.user = this.dialogData.user;
  }

  createTeam(): void {
    const team: Team = {
      name: this.form.get('name')?.value!,
      created: new Date().getTime(),
      modified: new Date().getTime(),
      sportId: this.sport.id!,
      creatorId: this.user.id!,
      coachIds: this.form.get('coachIds')?.value!,
      memberIds: this.form.get('memberIds')?.value!
    };
    this.teamService.create(team).then(() => {
      this.matSnackBar.open(
        'Team wurde erfolgreich erstellt.',
        'Ok!',
        { duration: 2000 }
      );
      this.dialogRef.close();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  /**
   * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
   * @param userIds string[]
   */
  syncCoaches(userIds: string[]) {
    this.form.controls['coachIds'].setValue(userIds);
  }

  /**
   * Gets called on change of the emitted event of the user-chooser and syncs the trainers.
   * @param userIds string[]
   */
  syncMembers(userIds: string[]) {
    this.form.controls['memberIds'].setValue(userIds);
  }
}
