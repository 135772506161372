import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap } from 'rxjs';
import { Roles } from 'src/app/models/roles.enum';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'tsv-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  user: User;
  roles: Roles[] = [Roles.ADMIN, Roles.COACH, Roles.REPORTER, Roles.MANAGER, Roles.USER];
  form = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    roles: [[''], Validators.required]
  });

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(concatMap(params => this.userService.getUserById(params['userId'])))
      .subscribe(user => {
        this.user = user;
        this.initForm(user);
      });
  }

  initForm(user: User): void {
    this.form.controls['firstName'].setValue(user.firstName);
    this.form.controls['lastName'].setValue(user.lastName);
    this.form.controls['email'].setValue(user.email);
    this.form.controls['roles'].setValue(user.roles);
  }

  cancel(): void {
    this.router.navigate(['admin/benutzerverwaltung']);
  }

  update(): void {
    const user = this.user;
    user.firstName = this.form.get('firstName')?.value!;
    user.lastName = this.form.get('lastName')?.value!;
    user.email = this.form.get('email')?.value!;
    user.roles = this.form.get('roles')?.value!;
    this.userService.updateUser(user).then(() => this.cancel());
  }

}
