<div class="board">

    <h1 class="title">Vorstand</h1>

    <mat-card *ngFor="let member of board$ | async" class="card">
        <img class="card-img" mat-card-lg-image [src]="member.imageUrl">
        <mat-card-header>
            <mat-card-title>{{member.name}}</mat-card-title>
            <mat-card-subtitle>{{member.title}}</mat-card-subtitle>
        </mat-card-header>
    </mat-card>
</div>