<mat-card class="membership-preview" *ngIf="teams$ | async; let teams">
    <span class="membership-preview-title">Meine Teams</span>

    <mat-divider></mat-divider>

    <div class="membership-list">
        <button mat-button class="membership-list-item" *ngFor="let team of teams"
            [routerLink]="'/mannschaft/' + team.id">
            {{team.name}}
        </button>

        <mat-card-subtitle *ngIf="teams.length == 0">Du bist noch keinem Team beigetreten.</mat-card-subtitle>
    </div>
</mat-card>