import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorType } from 'src/app/models/authorType.enum';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'tsv-event-create-dialog',
  templateUrl: './event-create-dialog.component.html',
  styleUrls: ['./event-create-dialog.component.scss']
})
export class EventCreateDialogComponent implements OnInit {

  teamAuthorType = AuthorType.TEAM;
  user: User;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    day: [new Date(), Validators.required],
    description: [''],
    place: [''],
    start: ['12:00', Validators.required],
    end: ['13:00', Validators.required],
    limitedParticipants: [0],
  },
    { validators: this.endTimeGreaterThanStartTimeValidator.bind(this) }
  );

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EventCreateDialogComponent>,
    private eventService: EventService,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { authorType: AuthorType, authorId: string }
  ) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => this.user = user);
  }

  create(): void {
    // Need to cast this to any to start and end to number while Date is expected
    const event: any = {
      created: new Date().getTime(),
      modified: new Date().getTime(),
      title: this.form.get('name')?.value!,
      description: this.form.get('description')?.value!,
      place: this.form.get('place')?.value!,
      day: this.form.get('day')?.value!.getTime(),
      start: new Date(),
      end: new Date(),
      startHour: this.form.get('start')?.value!,
      endHour: this.form.get('end')?.value!,
      creatorId: this.user.id!,
      authorId: this.dialogData.authorId ? this.dialogData.authorId : this.user.id!,
      authorType: this.dialogData ? this.dialogData.authorType : AuthorType.EVENT,
      participantIds: [],
      limitedParticipants: this.form.get('limitedParticipants')?.value!,
    };

    this.eventService.create(event).then(() => {
      this.matSnackBar.open(
        'Veranstaltung wurde erfolgreich erstellt.',
        'Ok!',
        { duration: 2000 }
      );
      this.dialogRef.close();
    })
      .catch(err => {
        this.matSnackBar.open(
          'Etwas ist schiefgelaufen.',
          'Hm...',
          { duration: 5000 }
        );
        console.log(err);
      });
  }

  /**
   * Custom validator function.
   * @param control {AbstractControl}
   * @returns error {ValidationErrors | null}
   */
  private endTimeGreaterThanStartTimeValidator(control: AbstractControl): ValidationErrors | null {
    const start = control.get('start')?.value;
    const end = control.get('end')?.value;

    if (!start || !end) {
      return null; // Don't perform validation if either start or end is not present
    }

    const startTime = this.parseTimeString(start);
    const endTime = this.parseTimeString(end);

    if (startTime && endTime && startTime >= endTime) {
      this.matSnackBar.open('Das Ende der Veranstaltung muss nach dem Anfang liegen.', 'Schließen', {
      });
      return { endTimeGreaterThanStartTime: true };
    }

    return null;
  }

  /**
   * Helper function to parse time string into Date object.
   * @param timeString {string}
   * @returns date {Date | null}
   */
  private parseTimeString(timeString: string): Date | null {
    const [hours, minutes] = timeString.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

}
