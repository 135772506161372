<div class="team-flexbox" *ngIf="team && sport">

    <button mat-fab class="fab" (click)="openEventCreation()" *ngIf="team.coachIds.includes(user.id!) || user.roles.includes(adminRole)">
        <mat-icon>add</mat-icon>
    </button>

    <div class="team">
        <mat-card class="team-card">
            <mat-card-header class="team-header">
                <mat-card-title class="team-title">{{team.name}}</mat-card-title>
                <mat-card-subtitle>
                    {{team.memberIds.length > 1 ? team.memberIds.length + ' Mitglieder' : team.memberIds.length + '
                    Mitglied'}}
                </mat-card-subtitle>
            </mat-card-header>
            <div class="all-spacer"></div>
            <div class="team-more">
                <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="team.coachIds.includes(user.id!) || user.roles.includes(adminRole)">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <button mat-stroked-button class="team-sport" [routerLink]="'/sport/' + sport.id">
                    {{sport.name}}
                </button>
            </div>
        </mat-card>
        <mat-menu #menu [xPosition]="'before'">
            <button mat-menu-item [routerLink]="'einstellungen'">
                <mat-icon>settings</mat-icon>
                Einstellungen
            </button>
        </mat-menu>
    </div>

    <div class="team-content">
        <tsv-team-calendar [currentUser]="user" [team]="team"></tsv-team-calendar>
    </div>
</div>