<mat-form-field appearance="outline" class="user-chooser-form">
    <mat-label>{{title}}</mat-label>
    <mat-select multiple (selectionChange)="selectedUsersEvent.emit(selectedUsers)" [(ngModel)]="selectedUsers">

        <mat-form-field class="search">
            <input matInput placeholder="Suche..." (keyup)="search()" [(ngModel)]="searchValue" />
        </mat-form-field>

        <div *ngIf="filteredUsers">
            <mat-option *ngFor="let user of filteredUsers" [value]="user.id">
                {{user.firstName}} {{user.lastName}}
            </mat-option>
        </div>

        <div *ngIf="!filteredUsers">
            <mat-option *ngFor="let user of users" [value]="user.id">
                {{user.firstName}} {{user.lastName}}
            </mat-option>
        </div>
    </mat-select>
</mat-form-field>