import { Component, OnInit } from '@angular/core';
import { FAQ } from 'src/app/models/faq.model';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'tsv-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  newFaq: FAQ = {
    created: new Date().getTime(),
    question: '',
    answer: ''
  }
  faqs: FAQ[];

  constructor(
    private faqService: FaqService
  ) { }

  ngOnInit(): void {
    this.faqService.getAll().subscribe(faqs => this.faqs = faqs);
  }

  createFaq(): void {
    const faq: FAQ = {
      created: this.newFaq.created,
      answer: this.newFaq.answer,
      question: this.newFaq.question
    };
    this.faqService.create(faq);
  }


  deleteFaq(faq: FAQ): void {
    this.faqService.delete(faq);
  }

  updateFaq(faq: FAQ): void {
    this.faqService.update(faq);
  }
}
