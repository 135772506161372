<div class="admin">
    <h3 class="title">Benutzerverwaltung</h3>
    <mat-form-field appearance="outline" class="search">
        <mat-label>Suche</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="z.B. Mustermann" #input>
    </mat-form-field>
    <mat-card class="card">
        <mat-card-content class="table">

            <table mat-table [dataSource]="dataSource" matSort>

                <!-- Firstname Column -->
                <ng-container matColumnDef="firstname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </th>
                    <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
                </ng-container>

                <!-- Lastname Column -->
                <ng-container matColumnDef="lastname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </th>
                    <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hide-md"> Email </th>
                    <td mat-cell *matCellDef="let row" class="hide-md"> {{row.email}} </td>
                </ng-container>

                <!-- Roles Column -->
                <ng-container matColumnDef="roles">
                    <th mat-header-cell *matHeaderCellDef class="hide-md"> Rollen </th>
                    <td mat-cell *matCellDef="let row" class="hide-md"> {{row.roles}} </td>
                </ng-container>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button mat-menu-item [routerLink]="row.id">
                                <mat-icon>edit</mat-icon>
                                Bearbeiten
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-menu-item (click)="deleteUser(row)">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>

            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>

        </mat-card-content>
    </mat-card>
</div>