import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SettingsClub } from 'src/app/models/settingsClub.model';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'tsv-admin-club',
  templateUrl: './admin-club.component.html',
  styleUrls: ['./admin-club.component.scss'],
})
export class AdminClubComponent implements OnInit {
  // Form to bind values for the clubhouse
  form = this.formBuilder.group({
    banner: [''],
    title: [''],
    description: [''],
    content: [''],
  });

  // ImageUrls to render in the swiper of the clubhouse
  imageUrls: string[] = [];

  // Variable to store the settings for updating the whole document
  clubSettings: SettingsClub;

  constructor(
    private formBuilder: FormBuilder,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Calls the settings observable and inits the form.
   */
  initForm(): void {
    this.settingsService.get('clubhouse').subscribe((clubs: SettingsClub[]) => {
      const club = clubs[0];
      this.clubSettings = club;

      this.form.controls.banner.setValue(club.banner);
      this.form.controls.title.setValue(club.title);
      this.form.controls.description.setValue(club.description);
      this.form.controls.content.setValue(club.content);

      this.imageUrls = club.imageUrls;
    });
  }

  /**
   * Updates the documents values
   */
  update(): void {
    const club = this.clubSettings;
    club.modified = new Date().getTime();
    club.banner = this.form.controls.banner.value!;
    club.title = this.form.controls.title.value!;
    club.description = this.form.controls.description.value!;
    club.content = this.form.controls.content.value!;
    club.imageUrls = this.imageUrls;

    this.settingsService.update(club);
  }
}
