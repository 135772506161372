import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsGlobal } from 'src/app/models/settings-global.model';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'tsv-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {

  /**
  * Global settings key.
  */
  readonly SETTINGS_KEY: string = 'global-settings';

  /**
   * Stores the global settings, see interface for details.
   */
  settings: SettingsGlobal;

  form = this.formBuilder.group({
    banner: [true],
    bannerMessage: [''],
    sportInformationDefault: [true],
    messageBool: {value: false, disabled: true},
    message: {value: '', disabled: true}
  });

  constructor(
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.settingsService.get(this.SETTINGS_KEY).subscribe(settings => {
      this.settings = settings[0];
      this.initForm();
    });
  }

  initForm(): void {
    this.form.controls.sportInformationDefault.setValue(this.settings.sportInformationDefault);
    this.form.controls.banner.setValue(this.settings.banner);
    this.form.controls.bannerMessage.setValue(this.settings.bannerMessage);
  }

  update(): void {
    const settings: SettingsGlobal = {
      id: this.settings.id,
      banner: this.form.controls.banner.value!,
      bannerMessage: this.form.controls.bannerMessage.value!,
      sportInformationDefault: this.form.controls.sportInformationDefault.value!,
      modified: new Date().getTime()
    };
    this.settingsService.update(settings).then(() => {
      this.matSnackBar.open(
        'Änderungen erfolgreich gespeichert.',
        undefined,
        { duration: 2500 }
      );
    });
  }

}
