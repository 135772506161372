// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDItFVgzLj71SWO3lSeF1_DNvhLHcQD79k',
    authDomain: 'dev-tsv-travemuende.firebaseapp.com',
    projectId: 'dev-tsv-travemuende',
    storageBucket: 'dev-tsv-travemuende.appspot.com',
    messagingSenderId: '148856021392',
    appId: '1:148856021392:web:6938d8f67c94abfe0f5315',
    measurementId: 'G-HR99NGK24L'
  }
};
